
import { Message } from "@/proto/message";
import{EventEmitter} from "./EventEmitter";
//与远程服务器进行通信的类
export class MessageTransport extends EventEmitter{
    _address:string;
    _logined:boolean;
    _messages:Uint8Array[];
    constructor(address:string){
        super();
        this._address = address;
        this._logined = false;
        this._messages = [];
        this.on("loginRespond",(message:Message)=>{
            const respond = message.loginRespond;
            const result= respond?.result
            if(result){
                this._logined = true;
                while(this._messages.length){
                    const data = this._messages.splice(0,1)
                    this.socket?.send(data[0]);
                }
            }
            this.emit("login-result",result)
        })
    }
    isStarted = false;

    socket?:WebSocket;
    login(userName:string,password:string){
        const auth = this._packMessage("loginRequest",{
            userName:userName,
            password:password,
            deviceName:"manager",
            sessionType:2,
        })
        this._logined = false;
        this._messages.length = 0;

        this.socket = new WebSocket(this._address);
        this.socket.onopen = (ev)=>{
            this.socket?.send(auth)
            this.emit("socket-open",ev);
        }
        this.socket.onmessage = (ev)=>{
            new Response(ev.data).arrayBuffer().then(buffer=>{
                const data = new Uint8Array(buffer);
                const message = Message.decode(data);
                const message_type = message.Data;
                if(message_type){
                    // console.log(message_type,message.toJSON())
                    this.emit(message_type,message);
                }else{
                    this.emit("unknown-message");
                }
            })
        }
        this.socket.onerror = (ev)=>{
            this.emit("socket-error",ev);
        }
        this.socket.onclose = (ev)=>{
            this.isStarted = false;
            console.log(ev.code)
            this.emit("socket-close",ev)
        }
    }

    _packMessage(name:string,message?:any):Uint8Array{
        if(!message){
            message = {}
        }
        const obj = Message.fromObject({[name]:message})
        return Message.encode(obj).finish()
    }

    sendMessage(name:string,message?:any){
        const data = this._packMessage(name,message);
        if(this._logined){
            this.socket?.send(data)
        }else
        {
            this._messages.push(data);
        }
    }

}