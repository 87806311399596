/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.LoginRequest = (function() {

    /**
     * Properties of a LoginRequest.
     * @exports ILoginRequest
     * @interface ILoginRequest
     * @property {string|null} [userName] LoginRequest userName
     * @property {string|null} [password] LoginRequest password
     * @property {number|null} [sessionType] LoginRequest sessionType
     * @property {string|null} [deviceName] LoginRequest deviceName
     */

    /**
     * Constructs a new LoginRequest.
     * @exports LoginRequest
     * @classdesc Represents a LoginRequest.
     * @implements ILoginRequest
     * @constructor
     * @param {ILoginRequest=} [properties] Properties to set
     */
    function LoginRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginRequest userName.
     * @member {string} userName
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.userName = "";

    /**
     * LoginRequest password.
     * @member {string} password
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.password = "";

    /**
     * LoginRequest sessionType.
     * @member {number} sessionType
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.sessionType = 0;

    /**
     * LoginRequest deviceName.
     * @member {string} deviceName
     * @memberof LoginRequest
     * @instance
     */
    LoginRequest.prototype.deviceName = "";

    /**
     * Creates a new LoginRequest instance using the specified properties.
     * @function create
     * @memberof LoginRequest
     * @static
     * @param {ILoginRequest=} [properties] Properties to set
     * @returns {LoginRequest} LoginRequest instance
     */
    LoginRequest.create = function create(properties) {
        return new LoginRequest(properties);
    };

    /**
     * Encodes the specified LoginRequest message. Does not implicitly {@link LoginRequest.verify|verify} messages.
     * @function encode
     * @memberof LoginRequest
     * @static
     * @param {ILoginRequest} message LoginRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userName);
        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
        if (message.sessionType != null && Object.hasOwnProperty.call(message, "sessionType"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sessionType);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.deviceName);
        return writer;
    };

    /**
     * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link LoginRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LoginRequest
     * @static
     * @param {ILoginRequest} message LoginRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginRequest message from the specified reader or buffer.
     * @function decode
     * @memberof LoginRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LoginRequest} LoginRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LoginRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.userName = reader.string();
                    break;
                }
            case 2: {
                    message.password = reader.string();
                    break;
                }
            case 3: {
                    message.sessionType = reader.int32();
                    break;
                }
            case 4: {
                    message.deviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LoginRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LoginRequest} LoginRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginRequest message.
     * @function verify
     * @memberof LoginRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.userName != null && message.hasOwnProperty("userName"))
            if (!$util.isString(message.userName))
                return "userName: string expected";
        if (message.password != null && message.hasOwnProperty("password"))
            if (!$util.isString(message.password))
                return "password: string expected";
        if (message.sessionType != null && message.hasOwnProperty("sessionType"))
            if (!$util.isInteger(message.sessionType))
                return "sessionType: integer expected";
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            if (!$util.isString(message.deviceName))
                return "deviceName: string expected";
        return null;
    };

    /**
     * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LoginRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LoginRequest} LoginRequest
     */
    LoginRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.LoginRequest)
            return object;
        var message = new $root.LoginRequest();
        if (object.userName != null)
            message.userName = String(object.userName);
        if (object.password != null)
            message.password = String(object.password);
        if (object.sessionType != null)
            message.sessionType = object.sessionType | 0;
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        return message;
    };

    /**
     * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LoginRequest
     * @static
     * @param {LoginRequest} message LoginRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.userName = "";
            object.password = "";
            object.sessionType = 0;
            object.deviceName = "";
        }
        if (message.userName != null && message.hasOwnProperty("userName"))
            object.userName = message.userName;
        if (message.password != null && message.hasOwnProperty("password"))
            object.password = message.password;
        if (message.sessionType != null && message.hasOwnProperty("sessionType"))
            object.sessionType = message.sessionType;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        return object;
    };

    /**
     * Converts this LoginRequest to JSON.
     * @function toJSON
     * @memberof LoginRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginRequest
     * @function getTypeUrl
     * @memberof LoginRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LoginRequest";
    };

    return LoginRequest;
})();

$root.Ping = (function() {

    /**
     * Properties of a Ping.
     * @exports IPing
     * @interface IPing
     */

    /**
     * Constructs a new Ping.
     * @exports Ping
     * @classdesc Represents a Ping.
     * @implements IPing
     * @constructor
     * @param {IPing=} [properties] Properties to set
     */
    function Ping(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new Ping instance using the specified properties.
     * @function create
     * @memberof Ping
     * @static
     * @param {IPing=} [properties] Properties to set
     * @returns {Ping} Ping instance
     */
    Ping.create = function create(properties) {
        return new Ping(properties);
    };

    /**
     * Encodes the specified Ping message. Does not implicitly {@link Ping.verify|verify} messages.
     * @function encode
     * @memberof Ping
     * @static
     * @param {IPing} message Ping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Ping.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified Ping message, length delimited. Does not implicitly {@link Ping.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Ping
     * @static
     * @param {IPing} message Ping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Ping.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Ping message from the specified reader or buffer.
     * @function decode
     * @memberof Ping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Ping} Ping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Ping.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Ping();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Ping message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Ping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Ping} Ping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Ping.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Ping message.
     * @function verify
     * @memberof Ping
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Ping.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a Ping message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Ping
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Ping} Ping
     */
    Ping.fromObject = function fromObject(object) {
        if (object instanceof $root.Ping)
            return object;
        return new $root.Ping();
    };

    /**
     * Creates a plain object from a Ping message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Ping
     * @static
     * @param {Ping} message Ping
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Ping.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Ping to JSON.
     * @function toJSON
     * @memberof Ping
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Ping.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Ping
     * @function getTypeUrl
     * @memberof Ping
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Ping.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Ping";
    };

    return Ping;
})();

$root.LoginRespond = (function() {

    /**
     * Properties of a LoginRespond.
     * @exports ILoginRespond
     * @interface ILoginRespond
     * @property {boolean|null} [result] LoginRespond result
     * @property {string|null} [message] LoginRespond message
     */

    /**
     * Constructs a new LoginRespond.
     * @exports LoginRespond
     * @classdesc Represents a LoginRespond.
     * @implements ILoginRespond
     * @constructor
     * @param {ILoginRespond=} [properties] Properties to set
     */
    function LoginRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginRespond result.
     * @member {boolean} result
     * @memberof LoginRespond
     * @instance
     */
    LoginRespond.prototype.result = false;

    /**
     * LoginRespond message.
     * @member {string} message
     * @memberof LoginRespond
     * @instance
     */
    LoginRespond.prototype.message = "";

    /**
     * Creates a new LoginRespond instance using the specified properties.
     * @function create
     * @memberof LoginRespond
     * @static
     * @param {ILoginRespond=} [properties] Properties to set
     * @returns {LoginRespond} LoginRespond instance
     */
    LoginRespond.create = function create(properties) {
        return new LoginRespond(properties);
    };

    /**
     * Encodes the specified LoginRespond message. Does not implicitly {@link LoginRespond.verify|verify} messages.
     * @function encode
     * @memberof LoginRespond
     * @static
     * @param {ILoginRespond} message LoginRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.result);
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified LoginRespond message, length delimited. Does not implicitly {@link LoginRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LoginRespond
     * @static
     * @param {ILoginRespond} message LoginRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginRespond message from the specified reader or buffer.
     * @function decode
     * @memberof LoginRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LoginRespond} LoginRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LoginRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.bool();
                    break;
                }
            case 2: {
                    message.message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LoginRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LoginRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LoginRespond} LoginRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginRespond message.
     * @function verify
     * @memberof LoginRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (typeof message.result !== "boolean")
                return "result: boolean expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        return null;
    };

    /**
     * Creates a LoginRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LoginRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LoginRespond} LoginRespond
     */
    LoginRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.LoginRespond)
            return object;
        var message = new $root.LoginRespond();
        if (object.result != null)
            message.result = Boolean(object.result);
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from a LoginRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LoginRespond
     * @static
     * @param {LoginRespond} message LoginRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.result = false;
            object.message = "";
        }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this LoginRespond to JSON.
     * @function toJSON
     * @memberof LoginRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginRespond
     * @function getTypeUrl
     * @memberof LoginRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LoginRespond";
    };

    return LoginRespond;
})();

$root.GetCodeRequest = (function() {

    /**
     * Properties of a GetCodeRequest.
     * @exports IGetCodeRequest
     * @interface IGetCodeRequest
     * @property {string|null} [url] GetCodeRequest url
     * @property {number|Long|null} [workerId] GetCodeRequest workerId
     */

    /**
     * Constructs a new GetCodeRequest.
     * @exports GetCodeRequest
     * @classdesc Represents a GetCodeRequest.
     * @implements IGetCodeRequest
     * @constructor
     * @param {IGetCodeRequest=} [properties] Properties to set
     */
    function GetCodeRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetCodeRequest url.
     * @member {string} url
     * @memberof GetCodeRequest
     * @instance
     */
    GetCodeRequest.prototype.url = "";

    /**
     * GetCodeRequest workerId.
     * @member {number|Long} workerId
     * @memberof GetCodeRequest
     * @instance
     */
    GetCodeRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new GetCodeRequest instance using the specified properties.
     * @function create
     * @memberof GetCodeRequest
     * @static
     * @param {IGetCodeRequest=} [properties] Properties to set
     * @returns {GetCodeRequest} GetCodeRequest instance
     */
    GetCodeRequest.create = function create(properties) {
        return new GetCodeRequest(properties);
    };

    /**
     * Encodes the specified GetCodeRequest message. Does not implicitly {@link GetCodeRequest.verify|verify} messages.
     * @function encode
     * @memberof GetCodeRequest
     * @static
     * @param {IGetCodeRequest} message GetCodeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCodeRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified GetCodeRequest message, length delimited. Does not implicitly {@link GetCodeRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetCodeRequest
     * @static
     * @param {IGetCodeRequest} message GetCodeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetCodeRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetCodeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetCodeRequest} GetCodeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCodeRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetCodeRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.url = reader.string();
                    break;
                }
            case 2: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetCodeRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetCodeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetCodeRequest} GetCodeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCodeRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetCodeRequest message.
     * @function verify
     * @memberof GetCodeRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCodeRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.url != null && message.hasOwnProperty("url"))
            if (!$util.isString(message.url))
                return "url: string expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a GetCodeRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetCodeRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetCodeRequest} GetCodeRequest
     */
    GetCodeRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetCodeRequest)
            return object;
        var message = new $root.GetCodeRequest();
        if (object.url != null)
            message.url = String(object.url);
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a GetCodeRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetCodeRequest
     * @static
     * @param {GetCodeRequest} message GetCodeRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCodeRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.url = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        }
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this GetCodeRequest to JSON.
     * @function toJSON
     * @memberof GetCodeRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCodeRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetCodeRequest
     * @function getTypeUrl
     * @memberof GetCodeRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetCodeRequest";
    };

    return GetCodeRequest;
})();

$root.GetCodeRespond = (function() {

    /**
     * Properties of a GetCodeRespond.
     * @exports IGetCodeRespond
     * @interface IGetCodeRespond
     * @property {Uint8Array|null} [code] GetCodeRespond code
     * @property {number|Long|null} [workerId] GetCodeRespond workerId
     */

    /**
     * Constructs a new GetCodeRespond.
     * @exports GetCodeRespond
     * @classdesc Represents a GetCodeRespond.
     * @implements IGetCodeRespond
     * @constructor
     * @param {IGetCodeRespond=} [properties] Properties to set
     */
    function GetCodeRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetCodeRespond code.
     * @member {Uint8Array} code
     * @memberof GetCodeRespond
     * @instance
     */
    GetCodeRespond.prototype.code = $util.newBuffer([]);

    /**
     * GetCodeRespond workerId.
     * @member {number|Long} workerId
     * @memberof GetCodeRespond
     * @instance
     */
    GetCodeRespond.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new GetCodeRespond instance using the specified properties.
     * @function create
     * @memberof GetCodeRespond
     * @static
     * @param {IGetCodeRespond=} [properties] Properties to set
     * @returns {GetCodeRespond} GetCodeRespond instance
     */
    GetCodeRespond.create = function create(properties) {
        return new GetCodeRespond(properties);
    };

    /**
     * Encodes the specified GetCodeRespond message. Does not implicitly {@link GetCodeRespond.verify|verify} messages.
     * @function encode
     * @memberof GetCodeRespond
     * @static
     * @param {IGetCodeRespond} message GetCodeRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCodeRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.code);
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified GetCodeRespond message, length delimited. Does not implicitly {@link GetCodeRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetCodeRespond
     * @static
     * @param {IGetCodeRespond} message GetCodeRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCodeRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetCodeRespond message from the specified reader or buffer.
     * @function decode
     * @memberof GetCodeRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetCodeRespond} GetCodeRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCodeRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetCodeRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.bytes();
                    break;
                }
            case 2: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetCodeRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetCodeRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetCodeRespond} GetCodeRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCodeRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetCodeRespond message.
     * @function verify
     * @memberof GetCodeRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCodeRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!(message.code && typeof message.code.length === "number" || $util.isString(message.code)))
                return "code: buffer expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a GetCodeRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetCodeRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetCodeRespond} GetCodeRespond
     */
    GetCodeRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.GetCodeRespond)
            return object;
        var message = new $root.GetCodeRespond();
        if (object.code != null)
            if (typeof object.code === "string")
                $util.base64.decode(object.code, message.code = $util.newBuffer($util.base64.length(object.code)), 0);
            else if (object.code.length >= 0)
                message.code = object.code;
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a GetCodeRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetCodeRespond
     * @static
     * @param {GetCodeRespond} message GetCodeRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCodeRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if (options.bytes === String)
                object.code = "";
            else {
                object.code = [];
                if (options.bytes !== Array)
                    object.code = $util.newBuffer(object.code);
            }
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.bytes === String ? $util.base64.encode(message.code, 0, message.code.length) : options.bytes === Array ? Array.prototype.slice.call(message.code) : message.code;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this GetCodeRespond to JSON.
     * @function toJSON
     * @memberof GetCodeRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCodeRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetCodeRespond
     * @function getTypeUrl
     * @memberof GetCodeRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetCodeRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetCodeRespond";
    };

    return GetCodeRespond;
})();

$root.UpdateWorkerStateRequest = (function() {

    /**
     * Properties of an UpdateWorkerStateRequest.
     * @exports IUpdateWorkerStateRequest
     * @interface IUpdateWorkerStateRequest
     * @property {number|Long|null} [workerId] UpdateWorkerStateRequest workerId
     * @property {number|null} [state] UpdateWorkerStateRequest state
     * @property {string|null} [deviceName] UpdateWorkerStateRequest deviceName
     */

    /**
     * Constructs a new UpdateWorkerStateRequest.
     * @exports UpdateWorkerStateRequest
     * @classdesc Represents an UpdateWorkerStateRequest.
     * @implements IUpdateWorkerStateRequest
     * @constructor
     * @param {IUpdateWorkerStateRequest=} [properties] Properties to set
     */
    function UpdateWorkerStateRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateWorkerStateRequest workerId.
     * @member {number|Long} workerId
     * @memberof UpdateWorkerStateRequest
     * @instance
     */
    UpdateWorkerStateRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * UpdateWorkerStateRequest state.
     * @member {number} state
     * @memberof UpdateWorkerStateRequest
     * @instance
     */
    UpdateWorkerStateRequest.prototype.state = 0;

    /**
     * UpdateWorkerStateRequest deviceName.
     * @member {string} deviceName
     * @memberof UpdateWorkerStateRequest
     * @instance
     */
    UpdateWorkerStateRequest.prototype.deviceName = "";

    /**
     * Creates a new UpdateWorkerStateRequest instance using the specified properties.
     * @function create
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {IUpdateWorkerStateRequest=} [properties] Properties to set
     * @returns {UpdateWorkerStateRequest} UpdateWorkerStateRequest instance
     */
    UpdateWorkerStateRequest.create = function create(properties) {
        return new UpdateWorkerStateRequest(properties);
    };

    /**
     * Encodes the specified UpdateWorkerStateRequest message. Does not implicitly {@link UpdateWorkerStateRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {IUpdateWorkerStateRequest} message UpdateWorkerStateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateWorkerStateRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.deviceName);
        return writer;
    };

    /**
     * Encodes the specified UpdateWorkerStateRequest message, length delimited. Does not implicitly {@link UpdateWorkerStateRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {IUpdateWorkerStateRequest} message UpdateWorkerStateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateWorkerStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateWorkerStateRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateWorkerStateRequest} UpdateWorkerStateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateWorkerStateRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateWorkerStateRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.state = reader.int32();
                    break;
                }
            case 3: {
                    message.deviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateWorkerStateRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateWorkerStateRequest} UpdateWorkerStateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateWorkerStateRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateWorkerStateRequest message.
     * @function verify
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateWorkerStateRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.state != null && message.hasOwnProperty("state"))
            if (!$util.isInteger(message.state))
                return "state: integer expected";
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            if (!$util.isString(message.deviceName))
                return "deviceName: string expected";
        return null;
    };

    /**
     * Creates an UpdateWorkerStateRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateWorkerStateRequest} UpdateWorkerStateRequest
     */
    UpdateWorkerStateRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateWorkerStateRequest)
            return object;
        var message = new $root.UpdateWorkerStateRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.state != null)
            message.state = object.state | 0;
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        return message;
    };

    /**
     * Creates a plain object from an UpdateWorkerStateRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {UpdateWorkerStateRequest} message UpdateWorkerStateRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateWorkerStateRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            object.state = 0;
            object.deviceName = "";
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = message.state;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        return object;
    };

    /**
     * Converts this UpdateWorkerStateRequest to JSON.
     * @function toJSON
     * @memberof UpdateWorkerStateRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateWorkerStateRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateWorkerStateRequest
     * @function getTypeUrl
     * @memberof UpdateWorkerStateRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateWorkerStateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateWorkerStateRequest";
    };

    return UpdateWorkerStateRequest;
})();

$root.LogRequest = (function() {

    /**
     * Properties of a LogRequest.
     * @exports ILogRequest
     * @interface ILogRequest
     * @property {string|null} [path] LogRequest path
     * @property {number|null} [line] LogRequest line
     * @property {string|null} [message] LogRequest message
     * @property {number|Long|null} [workerId] LogRequest workerId
     */

    /**
     * Constructs a new LogRequest.
     * @exports LogRequest
     * @classdesc Represents a LogRequest.
     * @implements ILogRequest
     * @constructor
     * @param {ILogRequest=} [properties] Properties to set
     */
    function LogRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LogRequest path.
     * @member {string} path
     * @memberof LogRequest
     * @instance
     */
    LogRequest.prototype.path = "";

    /**
     * LogRequest line.
     * @member {number} line
     * @memberof LogRequest
     * @instance
     */
    LogRequest.prototype.line = 0;

    /**
     * LogRequest message.
     * @member {string} message
     * @memberof LogRequest
     * @instance
     */
    LogRequest.prototype.message = "";

    /**
     * LogRequest workerId.
     * @member {number|Long} workerId
     * @memberof LogRequest
     * @instance
     */
    LogRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new LogRequest instance using the specified properties.
     * @function create
     * @memberof LogRequest
     * @static
     * @param {ILogRequest=} [properties] Properties to set
     * @returns {LogRequest} LogRequest instance
     */
    LogRequest.create = function create(properties) {
        return new LogRequest(properties);
    };

    /**
     * Encodes the specified LogRequest message. Does not implicitly {@link LogRequest.verify|verify} messages.
     * @function encode
     * @memberof LogRequest
     * @static
     * @param {ILogRequest} message LogRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.path != null && Object.hasOwnProperty.call(message, "path"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
        if (message.line != null && Object.hasOwnProperty.call(message, "line"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.line);
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified LogRequest message, length delimited. Does not implicitly {@link LogRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LogRequest
     * @static
     * @param {ILogRequest} message LogRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LogRequest message from the specified reader or buffer.
     * @function decode
     * @memberof LogRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LogRequest} LogRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LogRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.path = reader.string();
                    break;
                }
            case 2: {
                    message.line = reader.int32();
                    break;
                }
            case 3: {
                    message.message = reader.string();
                    break;
                }
            case 4: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LogRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LogRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LogRequest} LogRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LogRequest message.
     * @function verify
     * @memberof LogRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LogRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.path != null && message.hasOwnProperty("path"))
            if (!$util.isString(message.path))
                return "path: string expected";
        if (message.line != null && message.hasOwnProperty("line"))
            if (!$util.isInteger(message.line))
                return "line: integer expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a LogRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LogRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LogRequest} LogRequest
     */
    LogRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.LogRequest)
            return object;
        var message = new $root.LogRequest();
        if (object.path != null)
            message.path = String(object.path);
        if (object.line != null)
            message.line = object.line | 0;
        if (object.message != null)
            message.message = String(object.message);
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a LogRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LogRequest
     * @static
     * @param {LogRequest} message LogRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LogRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.path = "";
            object.line = 0;
            object.message = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        }
        if (message.path != null && message.hasOwnProperty("path"))
            object.path = message.path;
        if (message.line != null && message.hasOwnProperty("line"))
            object.line = message.line;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this LogRequest to JSON.
     * @function toJSON
     * @memberof LogRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LogRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LogRequest
     * @function getTypeUrl
     * @memberof LogRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LogRequest";
    };

    return LogRequest;
})();

$root.InterruptRequest = (function() {

    /**
     * Properties of an InterruptRequest.
     * @exports IInterruptRequest
     * @interface IInterruptRequest
     * @property {number|Long|null} [workerId] InterruptRequest workerId
     */

    /**
     * Constructs a new InterruptRequest.
     * @exports InterruptRequest
     * @classdesc Represents an InterruptRequest.
     * @implements IInterruptRequest
     * @constructor
     * @param {IInterruptRequest=} [properties] Properties to set
     */
    function InterruptRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * InterruptRequest workerId.
     * @member {number|Long} workerId
     * @memberof InterruptRequest
     * @instance
     */
    InterruptRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new InterruptRequest instance using the specified properties.
     * @function create
     * @memberof InterruptRequest
     * @static
     * @param {IInterruptRequest=} [properties] Properties to set
     * @returns {InterruptRequest} InterruptRequest instance
     */
    InterruptRequest.create = function create(properties) {
        return new InterruptRequest(properties);
    };

    /**
     * Encodes the specified InterruptRequest message. Does not implicitly {@link InterruptRequest.verify|verify} messages.
     * @function encode
     * @memberof InterruptRequest
     * @static
     * @param {IInterruptRequest} message InterruptRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InterruptRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified InterruptRequest message, length delimited. Does not implicitly {@link InterruptRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof InterruptRequest
     * @static
     * @param {IInterruptRequest} message InterruptRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InterruptRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InterruptRequest message from the specified reader or buffer.
     * @function decode
     * @memberof InterruptRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InterruptRequest} InterruptRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InterruptRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.InterruptRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an InterruptRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof InterruptRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {InterruptRequest} InterruptRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InterruptRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InterruptRequest message.
     * @function verify
     * @memberof InterruptRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InterruptRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates an InterruptRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof InterruptRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {InterruptRequest} InterruptRequest
     */
    InterruptRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.InterruptRequest)
            return object;
        var message = new $root.InterruptRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an InterruptRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof InterruptRequest
     * @static
     * @param {InterruptRequest} message InterruptRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InterruptRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this InterruptRequest to JSON.
     * @function toJSON
     * @memberof InterruptRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InterruptRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InterruptRequest
     * @function getTypeUrl
     * @memberof InterruptRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InterruptRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/InterruptRequest";
    };

    return InterruptRequest;
})();

$root.ExecuteRequest = (function() {

    /**
     * Properties of an ExecuteRequest.
     * @exports IExecuteRequest
     * @interface IExecuteRequest
     * @property {string|null} [code] ExecuteRequest code
     * @property {number|null} [codeType] ExecuteRequest codeType
     * @property {number|null} [executeMode] ExecuteRequest executeMode
     * @property {number|Long|null} [workerId] ExecuteRequest workerId
     */

    /**
     * Constructs a new ExecuteRequest.
     * @exports ExecuteRequest
     * @classdesc Represents an ExecuteRequest.
     * @implements IExecuteRequest
     * @constructor
     * @param {IExecuteRequest=} [properties] Properties to set
     */
    function ExecuteRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExecuteRequest code.
     * @member {string} code
     * @memberof ExecuteRequest
     * @instance
     */
    ExecuteRequest.prototype.code = "";

    /**
     * ExecuteRequest codeType.
     * @member {number} codeType
     * @memberof ExecuteRequest
     * @instance
     */
    ExecuteRequest.prototype.codeType = 0;

    /**
     * ExecuteRequest executeMode.
     * @member {number} executeMode
     * @memberof ExecuteRequest
     * @instance
     */
    ExecuteRequest.prototype.executeMode = 0;

    /**
     * ExecuteRequest workerId.
     * @member {number|Long} workerId
     * @memberof ExecuteRequest
     * @instance
     */
    ExecuteRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ExecuteRequest instance using the specified properties.
     * @function create
     * @memberof ExecuteRequest
     * @static
     * @param {IExecuteRequest=} [properties] Properties to set
     * @returns {ExecuteRequest} ExecuteRequest instance
     */
    ExecuteRequest.create = function create(properties) {
        return new ExecuteRequest(properties);
    };

    /**
     * Encodes the specified ExecuteRequest message. Does not implicitly {@link ExecuteRequest.verify|verify} messages.
     * @function encode
     * @memberof ExecuteRequest
     * @static
     * @param {IExecuteRequest} message ExecuteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExecuteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
        if (message.codeType != null && Object.hasOwnProperty.call(message, "codeType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.codeType);
        if (message.executeMode != null && Object.hasOwnProperty.call(message, "executeMode"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.executeMode);
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified ExecuteRequest message, length delimited. Does not implicitly {@link ExecuteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ExecuteRequest
     * @static
     * @param {IExecuteRequest} message ExecuteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExecuteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ExecuteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ExecuteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExecuteRequest} ExecuteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExecuteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExecuteRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.string();
                    break;
                }
            case 2: {
                    message.codeType = reader.int32();
                    break;
                }
            case 3: {
                    message.executeMode = reader.int32();
                    break;
                }
            case 4: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ExecuteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ExecuteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ExecuteRequest} ExecuteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExecuteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ExecuteRequest message.
     * @function verify
     * @memberof ExecuteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ExecuteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isString(message.code))
                return "code: string expected";
        if (message.codeType != null && message.hasOwnProperty("codeType"))
            if (!$util.isInteger(message.codeType))
                return "codeType: integer expected";
        if (message.executeMode != null && message.hasOwnProperty("executeMode"))
            if (!$util.isInteger(message.executeMode))
                return "executeMode: integer expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates an ExecuteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ExecuteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ExecuteRequest} ExecuteRequest
     */
    ExecuteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ExecuteRequest)
            return object;
        var message = new $root.ExecuteRequest();
        if (object.code != null)
            message.code = String(object.code);
        if (object.codeType != null)
            message.codeType = object.codeType | 0;
        if (object.executeMode != null)
            message.executeMode = object.executeMode | 0;
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an ExecuteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ExecuteRequest
     * @static
     * @param {ExecuteRequest} message ExecuteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ExecuteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.code = "";
            object.codeType = 0;
            object.executeMode = 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.codeType != null && message.hasOwnProperty("codeType"))
            object.codeType = message.codeType;
        if (message.executeMode != null && message.hasOwnProperty("executeMode"))
            object.executeMode = message.executeMode;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this ExecuteRequest to JSON.
     * @function toJSON
     * @memberof ExecuteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ExecuteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ExecuteRequest
     * @function getTypeUrl
     * @memberof ExecuteRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ExecuteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExecuteRequest";
    };

    return ExecuteRequest;
})();

$root.IsUseRemoteCodeProviderRequest = (function() {

    /**
     * Properties of an IsUseRemoteCodeProviderRequest.
     * @exports IIsUseRemoteCodeProviderRequest
     * @interface IIsUseRemoteCodeProviderRequest
     * @property {boolean|null} [is] IsUseRemoteCodeProviderRequest is
     */

    /**
     * Constructs a new IsUseRemoteCodeProviderRequest.
     * @exports IsUseRemoteCodeProviderRequest
     * @classdesc Represents an IsUseRemoteCodeProviderRequest.
     * @implements IIsUseRemoteCodeProviderRequest
     * @constructor
     * @param {IIsUseRemoteCodeProviderRequest=} [properties] Properties to set
     */
    function IsUseRemoteCodeProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IsUseRemoteCodeProviderRequest is.
     * @member {boolean} is
     * @memberof IsUseRemoteCodeProviderRequest
     * @instance
     */
    IsUseRemoteCodeProviderRequest.prototype.is = false;

    /**
     * Creates a new IsUseRemoteCodeProviderRequest instance using the specified properties.
     * @function create
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {IIsUseRemoteCodeProviderRequest=} [properties] Properties to set
     * @returns {IsUseRemoteCodeProviderRequest} IsUseRemoteCodeProviderRequest instance
     */
    IsUseRemoteCodeProviderRequest.create = function create(properties) {
        return new IsUseRemoteCodeProviderRequest(properties);
    };

    /**
     * Encodes the specified IsUseRemoteCodeProviderRequest message. Does not implicitly {@link IsUseRemoteCodeProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {IIsUseRemoteCodeProviderRequest} message IsUseRemoteCodeProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IsUseRemoteCodeProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.is != null && Object.hasOwnProperty.call(message, "is"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.is);
        return writer;
    };

    /**
     * Encodes the specified IsUseRemoteCodeProviderRequest message, length delimited. Does not implicitly {@link IsUseRemoteCodeProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {IIsUseRemoteCodeProviderRequest} message IsUseRemoteCodeProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IsUseRemoteCodeProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IsUseRemoteCodeProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IsUseRemoteCodeProviderRequest} IsUseRemoteCodeProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IsUseRemoteCodeProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.IsUseRemoteCodeProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.is = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IsUseRemoteCodeProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IsUseRemoteCodeProviderRequest} IsUseRemoteCodeProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IsUseRemoteCodeProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IsUseRemoteCodeProviderRequest message.
     * @function verify
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IsUseRemoteCodeProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.is != null && message.hasOwnProperty("is"))
            if (typeof message.is !== "boolean")
                return "is: boolean expected";
        return null;
    };

    /**
     * Creates an IsUseRemoteCodeProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IsUseRemoteCodeProviderRequest} IsUseRemoteCodeProviderRequest
     */
    IsUseRemoteCodeProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IsUseRemoteCodeProviderRequest)
            return object;
        var message = new $root.IsUseRemoteCodeProviderRequest();
        if (object.is != null)
            message.is = Boolean(object.is);
        return message;
    };

    /**
     * Creates a plain object from an IsUseRemoteCodeProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {IsUseRemoteCodeProviderRequest} message IsUseRemoteCodeProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IsUseRemoteCodeProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.is = false;
        if (message.is != null && message.hasOwnProperty("is"))
            object.is = message.is;
        return object;
    };

    /**
     * Converts this IsUseRemoteCodeProviderRequest to JSON.
     * @function toJSON
     * @memberof IsUseRemoteCodeProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IsUseRemoteCodeProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for IsUseRemoteCodeProviderRequest
     * @function getTypeUrl
     * @memberof IsUseRemoteCodeProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    IsUseRemoteCodeProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IsUseRemoteCodeProviderRequest";
    };

    return IsUseRemoteCodeProviderRequest;
})();

$root.IsUseRemoteLogRequest = (function() {

    /**
     * Properties of an IsUseRemoteLogRequest.
     * @exports IIsUseRemoteLogRequest
     * @interface IIsUseRemoteLogRequest
     * @property {boolean|null} [is] IsUseRemoteLogRequest is
     */

    /**
     * Constructs a new IsUseRemoteLogRequest.
     * @exports IsUseRemoteLogRequest
     * @classdesc Represents an IsUseRemoteLogRequest.
     * @implements IIsUseRemoteLogRequest
     * @constructor
     * @param {IIsUseRemoteLogRequest=} [properties] Properties to set
     */
    function IsUseRemoteLogRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IsUseRemoteLogRequest is.
     * @member {boolean} is
     * @memberof IsUseRemoteLogRequest
     * @instance
     */
    IsUseRemoteLogRequest.prototype.is = false;

    /**
     * Creates a new IsUseRemoteLogRequest instance using the specified properties.
     * @function create
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {IIsUseRemoteLogRequest=} [properties] Properties to set
     * @returns {IsUseRemoteLogRequest} IsUseRemoteLogRequest instance
     */
    IsUseRemoteLogRequest.create = function create(properties) {
        return new IsUseRemoteLogRequest(properties);
    };

    /**
     * Encodes the specified IsUseRemoteLogRequest message. Does not implicitly {@link IsUseRemoteLogRequest.verify|verify} messages.
     * @function encode
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {IIsUseRemoteLogRequest} message IsUseRemoteLogRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IsUseRemoteLogRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.is != null && Object.hasOwnProperty.call(message, "is"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.is);
        return writer;
    };

    /**
     * Encodes the specified IsUseRemoteLogRequest message, length delimited. Does not implicitly {@link IsUseRemoteLogRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {IIsUseRemoteLogRequest} message IsUseRemoteLogRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IsUseRemoteLogRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IsUseRemoteLogRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IsUseRemoteLogRequest} IsUseRemoteLogRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IsUseRemoteLogRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.IsUseRemoteLogRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.is = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IsUseRemoteLogRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IsUseRemoteLogRequest} IsUseRemoteLogRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IsUseRemoteLogRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IsUseRemoteLogRequest message.
     * @function verify
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IsUseRemoteLogRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.is != null && message.hasOwnProperty("is"))
            if (typeof message.is !== "boolean")
                return "is: boolean expected";
        return null;
    };

    /**
     * Creates an IsUseRemoteLogRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IsUseRemoteLogRequest} IsUseRemoteLogRequest
     */
    IsUseRemoteLogRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IsUseRemoteLogRequest)
            return object;
        var message = new $root.IsUseRemoteLogRequest();
        if (object.is != null)
            message.is = Boolean(object.is);
        return message;
    };

    /**
     * Creates a plain object from an IsUseRemoteLogRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {IsUseRemoteLogRequest} message IsUseRemoteLogRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IsUseRemoteLogRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.is = false;
        if (message.is != null && message.hasOwnProperty("is"))
            object.is = message.is;
        return object;
    };

    /**
     * Converts this IsUseRemoteLogRequest to JSON.
     * @function toJSON
     * @memberof IsUseRemoteLogRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IsUseRemoteLogRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for IsUseRemoteLogRequest
     * @function getTypeUrl
     * @memberof IsUseRemoteLogRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    IsUseRemoteLogRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/IsUseRemoteLogRequest";
    };

    return IsUseRemoteLogRequest;
})();

$root.AddLogObserverRequest = (function() {

    /**
     * Properties of an AddLogObserverRequest.
     * @exports IAddLogObserverRequest
     * @interface IAddLogObserverRequest
     * @property {number|Long|null} [workerId] AddLogObserverRequest workerId
     */

    /**
     * Constructs a new AddLogObserverRequest.
     * @exports AddLogObserverRequest
     * @classdesc Represents an AddLogObserverRequest.
     * @implements IAddLogObserverRequest
     * @constructor
     * @param {IAddLogObserverRequest=} [properties] Properties to set
     */
    function AddLogObserverRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AddLogObserverRequest workerId.
     * @member {number|Long} workerId
     * @memberof AddLogObserverRequest
     * @instance
     */
    AddLogObserverRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new AddLogObserverRequest instance using the specified properties.
     * @function create
     * @memberof AddLogObserverRequest
     * @static
     * @param {IAddLogObserverRequest=} [properties] Properties to set
     * @returns {AddLogObserverRequest} AddLogObserverRequest instance
     */
    AddLogObserverRequest.create = function create(properties) {
        return new AddLogObserverRequest(properties);
    };

    /**
     * Encodes the specified AddLogObserverRequest message. Does not implicitly {@link AddLogObserverRequest.verify|verify} messages.
     * @function encode
     * @memberof AddLogObserverRequest
     * @static
     * @param {IAddLogObserverRequest} message AddLogObserverRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddLogObserverRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified AddLogObserverRequest message, length delimited. Does not implicitly {@link AddLogObserverRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AddLogObserverRequest
     * @static
     * @param {IAddLogObserverRequest} message AddLogObserverRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddLogObserverRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AddLogObserverRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AddLogObserverRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AddLogObserverRequest} AddLogObserverRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddLogObserverRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddLogObserverRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AddLogObserverRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AddLogObserverRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AddLogObserverRequest} AddLogObserverRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddLogObserverRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AddLogObserverRequest message.
     * @function verify
     * @memberof AddLogObserverRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AddLogObserverRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates an AddLogObserverRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AddLogObserverRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AddLogObserverRequest} AddLogObserverRequest
     */
    AddLogObserverRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AddLogObserverRequest)
            return object;
        var message = new $root.AddLogObserverRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an AddLogObserverRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AddLogObserverRequest
     * @static
     * @param {AddLogObserverRequest} message AddLogObserverRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AddLogObserverRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this AddLogObserverRequest to JSON.
     * @function toJSON
     * @memberof AddLogObserverRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AddLogObserverRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AddLogObserverRequest
     * @function getTypeUrl
     * @memberof AddLogObserverRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AddLogObserverRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddLogObserverRequest";
    };

    return AddLogObserverRequest;
})();

$root.RemoveLogObserverRequest = (function() {

    /**
     * Properties of a RemoveLogObserverRequest.
     * @exports IRemoveLogObserverRequest
     * @interface IRemoveLogObserverRequest
     * @property {number|Long|null} [workerId] RemoveLogObserverRequest workerId
     */

    /**
     * Constructs a new RemoveLogObserverRequest.
     * @exports RemoveLogObserverRequest
     * @classdesc Represents a RemoveLogObserverRequest.
     * @implements IRemoveLogObserverRequest
     * @constructor
     * @param {IRemoveLogObserverRequest=} [properties] Properties to set
     */
    function RemoveLogObserverRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoveLogObserverRequest workerId.
     * @member {number|Long} workerId
     * @memberof RemoveLogObserverRequest
     * @instance
     */
    RemoveLogObserverRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new RemoveLogObserverRequest instance using the specified properties.
     * @function create
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {IRemoveLogObserverRequest=} [properties] Properties to set
     * @returns {RemoveLogObserverRequest} RemoveLogObserverRequest instance
     */
    RemoveLogObserverRequest.create = function create(properties) {
        return new RemoveLogObserverRequest(properties);
    };

    /**
     * Encodes the specified RemoveLogObserverRequest message. Does not implicitly {@link RemoveLogObserverRequest.verify|verify} messages.
     * @function encode
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {IRemoveLogObserverRequest} message RemoveLogObserverRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveLogObserverRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified RemoveLogObserverRequest message, length delimited. Does not implicitly {@link RemoveLogObserverRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {IRemoveLogObserverRequest} message RemoveLogObserverRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveLogObserverRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoveLogObserverRequest message from the specified reader or buffer.
     * @function decode
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoveLogObserverRequest} RemoveLogObserverRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveLogObserverRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoveLogObserverRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoveLogObserverRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoveLogObserverRequest} RemoveLogObserverRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveLogObserverRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoveLogObserverRequest message.
     * @function verify
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoveLogObserverRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a RemoveLogObserverRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoveLogObserverRequest} RemoveLogObserverRequest
     */
    RemoveLogObserverRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoveLogObserverRequest)
            return object;
        var message = new $root.RemoveLogObserverRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a RemoveLogObserverRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {RemoveLogObserverRequest} message RemoveLogObserverRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoveLogObserverRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this RemoveLogObserverRequest to JSON.
     * @function toJSON
     * @memberof RemoveLogObserverRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoveLogObserverRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoveLogObserverRequest
     * @function getTypeUrl
     * @memberof RemoveLogObserverRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoveLogObserverRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoveLogObserverRequest";
    };

    return RemoveLogObserverRequest;
})();

$root.SetDebuggerRequest = (function() {

    /**
     * Properties of a SetDebuggerRequest.
     * @exports ISetDebuggerRequest
     * @interface ISetDebuggerRequest
     * @property {number|Long|null} [workerId] SetDebuggerRequest workerId
     */

    /**
     * Constructs a new SetDebuggerRequest.
     * @exports SetDebuggerRequest
     * @classdesc Represents a SetDebuggerRequest.
     * @implements ISetDebuggerRequest
     * @constructor
     * @param {ISetDebuggerRequest=} [properties] Properties to set
     */
    function SetDebuggerRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetDebuggerRequest workerId.
     * @member {number|Long} workerId
     * @memberof SetDebuggerRequest
     * @instance
     */
    SetDebuggerRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new SetDebuggerRequest instance using the specified properties.
     * @function create
     * @memberof SetDebuggerRequest
     * @static
     * @param {ISetDebuggerRequest=} [properties] Properties to set
     * @returns {SetDebuggerRequest} SetDebuggerRequest instance
     */
    SetDebuggerRequest.create = function create(properties) {
        return new SetDebuggerRequest(properties);
    };

    /**
     * Encodes the specified SetDebuggerRequest message. Does not implicitly {@link SetDebuggerRequest.verify|verify} messages.
     * @function encode
     * @memberof SetDebuggerRequest
     * @static
     * @param {ISetDebuggerRequest} message SetDebuggerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetDebuggerRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified SetDebuggerRequest message, length delimited. Does not implicitly {@link SetDebuggerRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SetDebuggerRequest
     * @static
     * @param {ISetDebuggerRequest} message SetDebuggerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetDebuggerRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetDebuggerRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SetDebuggerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SetDebuggerRequest} SetDebuggerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetDebuggerRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SetDebuggerRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SetDebuggerRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SetDebuggerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SetDebuggerRequest} SetDebuggerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetDebuggerRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetDebuggerRequest message.
     * @function verify
     * @memberof SetDebuggerRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetDebuggerRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a SetDebuggerRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SetDebuggerRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SetDebuggerRequest} SetDebuggerRequest
     */
    SetDebuggerRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SetDebuggerRequest)
            return object;
        var message = new $root.SetDebuggerRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a SetDebuggerRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SetDebuggerRequest
     * @static
     * @param {SetDebuggerRequest} message SetDebuggerRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetDebuggerRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this SetDebuggerRequest to JSON.
     * @function toJSON
     * @memberof SetDebuggerRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetDebuggerRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetDebuggerRequest
     * @function getTypeUrl
     * @memberof SetDebuggerRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetDebuggerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SetDebuggerRequest";
    };

    return SetDebuggerRequest;
})();

$root.ResetDebuggerRequest = (function() {

    /**
     * Properties of a ResetDebuggerRequest.
     * @exports IResetDebuggerRequest
     * @interface IResetDebuggerRequest
     * @property {number|Long|null} [workerId] ResetDebuggerRequest workerId
     */

    /**
     * Constructs a new ResetDebuggerRequest.
     * @exports ResetDebuggerRequest
     * @classdesc Represents a ResetDebuggerRequest.
     * @implements IResetDebuggerRequest
     * @constructor
     * @param {IResetDebuggerRequest=} [properties] Properties to set
     */
    function ResetDebuggerRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResetDebuggerRequest workerId.
     * @member {number|Long} workerId
     * @memberof ResetDebuggerRequest
     * @instance
     */
    ResetDebuggerRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ResetDebuggerRequest instance using the specified properties.
     * @function create
     * @memberof ResetDebuggerRequest
     * @static
     * @param {IResetDebuggerRequest=} [properties] Properties to set
     * @returns {ResetDebuggerRequest} ResetDebuggerRequest instance
     */
    ResetDebuggerRequest.create = function create(properties) {
        return new ResetDebuggerRequest(properties);
    };

    /**
     * Encodes the specified ResetDebuggerRequest message. Does not implicitly {@link ResetDebuggerRequest.verify|verify} messages.
     * @function encode
     * @memberof ResetDebuggerRequest
     * @static
     * @param {IResetDebuggerRequest} message ResetDebuggerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResetDebuggerRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified ResetDebuggerRequest message, length delimited. Does not implicitly {@link ResetDebuggerRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ResetDebuggerRequest
     * @static
     * @param {IResetDebuggerRequest} message ResetDebuggerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResetDebuggerRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResetDebuggerRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ResetDebuggerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResetDebuggerRequest} ResetDebuggerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResetDebuggerRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResetDebuggerRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ResetDebuggerRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ResetDebuggerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ResetDebuggerRequest} ResetDebuggerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResetDebuggerRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResetDebuggerRequest message.
     * @function verify
     * @memberof ResetDebuggerRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResetDebuggerRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a ResetDebuggerRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ResetDebuggerRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ResetDebuggerRequest} ResetDebuggerRequest
     */
    ResetDebuggerRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ResetDebuggerRequest)
            return object;
        var message = new $root.ResetDebuggerRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a ResetDebuggerRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ResetDebuggerRequest
     * @static
     * @param {ResetDebuggerRequest} message ResetDebuggerRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResetDebuggerRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this ResetDebuggerRequest to JSON.
     * @function toJSON
     * @memberof ResetDebuggerRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResetDebuggerRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResetDebuggerRequest
     * @function getTypeUrl
     * @memberof ResetDebuggerRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResetDebuggerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ResetDebuggerRequest";
    };

    return ResetDebuggerRequest;
})();

$root.WorkerInfo = (function() {

    /**
     * Properties of a WorkerInfo.
     * @exports IWorkerInfo
     * @interface IWorkerInfo
     * @property {number|Long|null} [id] WorkerInfo id
     * @property {string|null} [deviceName] WorkerInfo deviceName
     * @property {number|null} [state] WorkerInfo state
     */

    /**
     * Constructs a new WorkerInfo.
     * @exports WorkerInfo
     * @classdesc Represents a WorkerInfo.
     * @implements IWorkerInfo
     * @constructor
     * @param {IWorkerInfo=} [properties] Properties to set
     */
    function WorkerInfo(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WorkerInfo id.
     * @member {number|Long} id
     * @memberof WorkerInfo
     * @instance
     */
    WorkerInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * WorkerInfo deviceName.
     * @member {string} deviceName
     * @memberof WorkerInfo
     * @instance
     */
    WorkerInfo.prototype.deviceName = "";

    /**
     * WorkerInfo state.
     * @member {number} state
     * @memberof WorkerInfo
     * @instance
     */
    WorkerInfo.prototype.state = 0;

    /**
     * Creates a new WorkerInfo instance using the specified properties.
     * @function create
     * @memberof WorkerInfo
     * @static
     * @param {IWorkerInfo=} [properties] Properties to set
     * @returns {WorkerInfo} WorkerInfo instance
     */
    WorkerInfo.create = function create(properties) {
        return new WorkerInfo(properties);
    };

    /**
     * Encodes the specified WorkerInfo message. Does not implicitly {@link WorkerInfo.verify|verify} messages.
     * @function encode
     * @memberof WorkerInfo
     * @static
     * @param {IWorkerInfo} message WorkerInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WorkerInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.deviceName);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified WorkerInfo message, length delimited. Does not implicitly {@link WorkerInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WorkerInfo
     * @static
     * @param {IWorkerInfo} message WorkerInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WorkerInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WorkerInfo message from the specified reader or buffer.
     * @function decode
     * @memberof WorkerInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WorkerInfo} WorkerInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WorkerInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WorkerInfo();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int64();
                    break;
                }
            case 2: {
                    message.deviceName = reader.string();
                    break;
                }
            case 3: {
                    message.state = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WorkerInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WorkerInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WorkerInfo} WorkerInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WorkerInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WorkerInfo message.
     * @function verify
     * @memberof WorkerInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WorkerInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            if (!$util.isString(message.deviceName))
                return "deviceName: string expected";
        if (message.state != null && message.hasOwnProperty("state"))
            if (!$util.isInteger(message.state))
                return "state: integer expected";
        return null;
    };

    /**
     * Creates a WorkerInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WorkerInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WorkerInfo} WorkerInfo
     */
    WorkerInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.WorkerInfo)
            return object;
        var message = new $root.WorkerInfo();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        if (object.state != null)
            message.state = object.state | 0;
        return message;
    };

    /**
     * Creates a plain object from a WorkerInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WorkerInfo
     * @static
     * @param {WorkerInfo} message WorkerInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WorkerInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            object.deviceName = "";
            object.state = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = message.state;
        return object;
    };

    /**
     * Converts this WorkerInfo to JSON.
     * @function toJSON
     * @memberof WorkerInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WorkerInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WorkerInfo
     * @function getTypeUrl
     * @memberof WorkerInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WorkerInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WorkerInfo";
    };

    return WorkerInfo;
})();

$root.GetWorkerInfoRequest = (function() {

    /**
     * Properties of a GetWorkerInfoRequest.
     * @exports IGetWorkerInfoRequest
     * @interface IGetWorkerInfoRequest
     * @property {string|null} [userName] GetWorkerInfoRequest userName
     */

    /**
     * Constructs a new GetWorkerInfoRequest.
     * @exports GetWorkerInfoRequest
     * @classdesc Represents a GetWorkerInfoRequest.
     * @implements IGetWorkerInfoRequest
     * @constructor
     * @param {IGetWorkerInfoRequest=} [properties] Properties to set
     */
    function GetWorkerInfoRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWorkerInfoRequest userName.
     * @member {string} userName
     * @memberof GetWorkerInfoRequest
     * @instance
     */
    GetWorkerInfoRequest.prototype.userName = "";

    /**
     * Creates a new GetWorkerInfoRequest instance using the specified properties.
     * @function create
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {IGetWorkerInfoRequest=} [properties] Properties to set
     * @returns {GetWorkerInfoRequest} GetWorkerInfoRequest instance
     */
    GetWorkerInfoRequest.create = function create(properties) {
        return new GetWorkerInfoRequest(properties);
    };

    /**
     * Encodes the specified GetWorkerInfoRequest message. Does not implicitly {@link GetWorkerInfoRequest.verify|verify} messages.
     * @function encode
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {IGetWorkerInfoRequest} message GetWorkerInfoRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWorkerInfoRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userName);
        return writer;
    };

    /**
     * Encodes the specified GetWorkerInfoRequest message, length delimited. Does not implicitly {@link GetWorkerInfoRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {IGetWorkerInfoRequest} message GetWorkerInfoRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWorkerInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWorkerInfoRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWorkerInfoRequest} GetWorkerInfoRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWorkerInfoRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWorkerInfoRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.userName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWorkerInfoRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWorkerInfoRequest} GetWorkerInfoRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWorkerInfoRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWorkerInfoRequest message.
     * @function verify
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWorkerInfoRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.userName != null && message.hasOwnProperty("userName"))
            if (!$util.isString(message.userName))
                return "userName: string expected";
        return null;
    };

    /**
     * Creates a GetWorkerInfoRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWorkerInfoRequest} GetWorkerInfoRequest
     */
    GetWorkerInfoRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWorkerInfoRequest)
            return object;
        var message = new $root.GetWorkerInfoRequest();
        if (object.userName != null)
            message.userName = String(object.userName);
        return message;
    };

    /**
     * Creates a plain object from a GetWorkerInfoRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {GetWorkerInfoRequest} message GetWorkerInfoRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWorkerInfoRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.userName = "";
        if (message.userName != null && message.hasOwnProperty("userName"))
            object.userName = message.userName;
        return object;
    };

    /**
     * Converts this GetWorkerInfoRequest to JSON.
     * @function toJSON
     * @memberof GetWorkerInfoRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWorkerInfoRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWorkerInfoRequest
     * @function getTypeUrl
     * @memberof GetWorkerInfoRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWorkerInfoRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWorkerInfoRequest";
    };

    return GetWorkerInfoRequest;
})();

$root.GetWorkerInfoRespond = (function() {

    /**
     * Properties of a GetWorkerInfoRespond.
     * @exports IGetWorkerInfoRespond
     * @interface IGetWorkerInfoRespond
     * @property {Array.<IWorkerInfo>|null} [workerInfos] GetWorkerInfoRespond workerInfos
     */

    /**
     * Constructs a new GetWorkerInfoRespond.
     * @exports GetWorkerInfoRespond
     * @classdesc Represents a GetWorkerInfoRespond.
     * @implements IGetWorkerInfoRespond
     * @constructor
     * @param {IGetWorkerInfoRespond=} [properties] Properties to set
     */
    function GetWorkerInfoRespond(properties) {
        this.workerInfos = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWorkerInfoRespond workerInfos.
     * @member {Array.<IWorkerInfo>} workerInfos
     * @memberof GetWorkerInfoRespond
     * @instance
     */
    GetWorkerInfoRespond.prototype.workerInfos = $util.emptyArray;

    /**
     * Creates a new GetWorkerInfoRespond instance using the specified properties.
     * @function create
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {IGetWorkerInfoRespond=} [properties] Properties to set
     * @returns {GetWorkerInfoRespond} GetWorkerInfoRespond instance
     */
    GetWorkerInfoRespond.create = function create(properties) {
        return new GetWorkerInfoRespond(properties);
    };

    /**
     * Encodes the specified GetWorkerInfoRespond message. Does not implicitly {@link GetWorkerInfoRespond.verify|verify} messages.
     * @function encode
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {IGetWorkerInfoRespond} message GetWorkerInfoRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWorkerInfoRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerInfos != null && message.workerInfos.length)
            for (var i = 0; i < message.workerInfos.length; ++i)
                $root.WorkerInfo.encode(message.workerInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetWorkerInfoRespond message, length delimited. Does not implicitly {@link GetWorkerInfoRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {IGetWorkerInfoRespond} message GetWorkerInfoRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWorkerInfoRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWorkerInfoRespond message from the specified reader or buffer.
     * @function decode
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetWorkerInfoRespond} GetWorkerInfoRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWorkerInfoRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetWorkerInfoRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.workerInfos && message.workerInfos.length))
                        message.workerInfos = [];
                    message.workerInfos.push($root.WorkerInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetWorkerInfoRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetWorkerInfoRespond} GetWorkerInfoRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWorkerInfoRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWorkerInfoRespond message.
     * @function verify
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWorkerInfoRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerInfos != null && message.hasOwnProperty("workerInfos")) {
            if (!Array.isArray(message.workerInfos))
                return "workerInfos: array expected";
            for (var i = 0; i < message.workerInfos.length; ++i) {
                var error = $root.WorkerInfo.verify(message.workerInfos[i]);
                if (error)
                    return "workerInfos." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetWorkerInfoRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetWorkerInfoRespond} GetWorkerInfoRespond
     */
    GetWorkerInfoRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.GetWorkerInfoRespond)
            return object;
        var message = new $root.GetWorkerInfoRespond();
        if (object.workerInfos) {
            if (!Array.isArray(object.workerInfos))
                throw TypeError(".GetWorkerInfoRespond.workerInfos: array expected");
            message.workerInfos = [];
            for (var i = 0; i < object.workerInfos.length; ++i) {
                if (typeof object.workerInfos[i] !== "object")
                    throw TypeError(".GetWorkerInfoRespond.workerInfos: object expected");
                message.workerInfos[i] = $root.WorkerInfo.fromObject(object.workerInfos[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetWorkerInfoRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {GetWorkerInfoRespond} message GetWorkerInfoRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWorkerInfoRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.workerInfos = [];
        if (message.workerInfos && message.workerInfos.length) {
            object.workerInfos = [];
            for (var j = 0; j < message.workerInfos.length; ++j)
                object.workerInfos[j] = $root.WorkerInfo.toObject(message.workerInfos[j], options);
        }
        return object;
    };

    /**
     * Converts this GetWorkerInfoRespond to JSON.
     * @function toJSON
     * @memberof GetWorkerInfoRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWorkerInfoRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWorkerInfoRespond
     * @function getTypeUrl
     * @memberof GetWorkerInfoRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWorkerInfoRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetWorkerInfoRespond";
    };

    return GetWorkerInfoRespond;
})();

$root.ScreenShotRequest = (function() {

    /**
     * Properties of a ScreenShotRequest.
     * @exports IScreenShotRequest
     * @interface IScreenShotRequest
     * @property {number|Long|null} [workerId] ScreenShotRequest workerId
     * @property {number|Long|null} [receiverId] ScreenShotRequest receiverId
     * @property {number|null} [type] ScreenShotRequest type
     */

    /**
     * Constructs a new ScreenShotRequest.
     * @exports ScreenShotRequest
     * @classdesc Represents a ScreenShotRequest.
     * @implements IScreenShotRequest
     * @constructor
     * @param {IScreenShotRequest=} [properties] Properties to set
     */
    function ScreenShotRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ScreenShotRequest workerId.
     * @member {number|Long} workerId
     * @memberof ScreenShotRequest
     * @instance
     */
    ScreenShotRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ScreenShotRequest receiverId.
     * @member {number|Long} receiverId
     * @memberof ScreenShotRequest
     * @instance
     */
    ScreenShotRequest.prototype.receiverId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ScreenShotRequest type.
     * @member {number} type
     * @memberof ScreenShotRequest
     * @instance
     */
    ScreenShotRequest.prototype.type = 0;

    /**
     * Creates a new ScreenShotRequest instance using the specified properties.
     * @function create
     * @memberof ScreenShotRequest
     * @static
     * @param {IScreenShotRequest=} [properties] Properties to set
     * @returns {ScreenShotRequest} ScreenShotRequest instance
     */
    ScreenShotRequest.create = function create(properties) {
        return new ScreenShotRequest(properties);
    };

    /**
     * Encodes the specified ScreenShotRequest message. Does not implicitly {@link ScreenShotRequest.verify|verify} messages.
     * @function encode
     * @memberof ScreenShotRequest
     * @static
     * @param {IScreenShotRequest} message ScreenShotRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ScreenShotRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.receiverId != null && Object.hasOwnProperty.call(message, "receiverId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.receiverId);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
        return writer;
    };

    /**
     * Encodes the specified ScreenShotRequest message, length delimited. Does not implicitly {@link ScreenShotRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ScreenShotRequest
     * @static
     * @param {IScreenShotRequest} message ScreenShotRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ScreenShotRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ScreenShotRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ScreenShotRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ScreenShotRequest} ScreenShotRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ScreenShotRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ScreenShotRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.receiverId = reader.int64();
                    break;
                }
            case 3: {
                    message.type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ScreenShotRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ScreenShotRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ScreenShotRequest} ScreenShotRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ScreenShotRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ScreenShotRequest message.
     * @function verify
     * @memberof ScreenShotRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ScreenShotRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.receiverId != null && message.hasOwnProperty("receiverId"))
            if (!$util.isInteger(message.receiverId) && !(message.receiverId && $util.isInteger(message.receiverId.low) && $util.isInteger(message.receiverId.high)))
                return "receiverId: integer|Long expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isInteger(message.type))
                return "type: integer expected";
        return null;
    };

    /**
     * Creates a ScreenShotRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ScreenShotRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ScreenShotRequest} ScreenShotRequest
     */
    ScreenShotRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ScreenShotRequest)
            return object;
        var message = new $root.ScreenShotRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.receiverId != null)
            if ($util.Long)
                (message.receiverId = $util.Long.fromValue(object.receiverId)).unsigned = false;
            else if (typeof object.receiverId === "string")
                message.receiverId = parseInt(object.receiverId, 10);
            else if (typeof object.receiverId === "number")
                message.receiverId = object.receiverId;
            else if (typeof object.receiverId === "object")
                message.receiverId = new $util.LongBits(object.receiverId.low >>> 0, object.receiverId.high >>> 0).toNumber();
        if (object.type != null)
            message.type = object.type | 0;
        return message;
    };

    /**
     * Creates a plain object from a ScreenShotRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ScreenShotRequest
     * @static
     * @param {ScreenShotRequest} message ScreenShotRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ScreenShotRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.receiverId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.receiverId = options.longs === String ? "0" : 0;
            object.type = 0;
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.receiverId != null && message.hasOwnProperty("receiverId"))
            if (typeof message.receiverId === "number")
                object.receiverId = options.longs === String ? String(message.receiverId) : message.receiverId;
            else
                object.receiverId = options.longs === String ? $util.Long.prototype.toString.call(message.receiverId) : options.longs === Number ? new $util.LongBits(message.receiverId.low >>> 0, message.receiverId.high >>> 0).toNumber() : message.receiverId;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        return object;
    };

    /**
     * Converts this ScreenShotRequest to JSON.
     * @function toJSON
     * @memberof ScreenShotRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ScreenShotRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ScreenShotRequest
     * @function getTypeUrl
     * @memberof ScreenShotRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ScreenShotRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ScreenShotRequest";
    };

    return ScreenShotRequest;
})();

$root.ScreenShotRespond = (function() {

    /**
     * Properties of a ScreenShotRespond.
     * @exports IScreenShotRespond
     * @interface IScreenShotRespond
     * @property {number|Long|null} [workerId] ScreenShotRespond workerId
     * @property {Uint8Array|null} [data] ScreenShotRespond data
     * @property {number|Long|null} [receiverId] ScreenShotRespond receiverId
     */

    /**
     * Constructs a new ScreenShotRespond.
     * @exports ScreenShotRespond
     * @classdesc Represents a ScreenShotRespond.
     * @implements IScreenShotRespond
     * @constructor
     * @param {IScreenShotRespond=} [properties] Properties to set
     */
    function ScreenShotRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ScreenShotRespond workerId.
     * @member {number|Long} workerId
     * @memberof ScreenShotRespond
     * @instance
     */
    ScreenShotRespond.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ScreenShotRespond data.
     * @member {Uint8Array} data
     * @memberof ScreenShotRespond
     * @instance
     */
    ScreenShotRespond.prototype.data = $util.newBuffer([]);

    /**
     * ScreenShotRespond receiverId.
     * @member {number|Long} receiverId
     * @memberof ScreenShotRespond
     * @instance
     */
    ScreenShotRespond.prototype.receiverId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ScreenShotRespond instance using the specified properties.
     * @function create
     * @memberof ScreenShotRespond
     * @static
     * @param {IScreenShotRespond=} [properties] Properties to set
     * @returns {ScreenShotRespond} ScreenShotRespond instance
     */
    ScreenShotRespond.create = function create(properties) {
        return new ScreenShotRespond(properties);
    };

    /**
     * Encodes the specified ScreenShotRespond message. Does not implicitly {@link ScreenShotRespond.verify|verify} messages.
     * @function encode
     * @memberof ScreenShotRespond
     * @static
     * @param {IScreenShotRespond} message ScreenShotRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ScreenShotRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
        if (message.receiverId != null && Object.hasOwnProperty.call(message, "receiverId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.receiverId);
        return writer;
    };

    /**
     * Encodes the specified ScreenShotRespond message, length delimited. Does not implicitly {@link ScreenShotRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ScreenShotRespond
     * @static
     * @param {IScreenShotRespond} message ScreenShotRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ScreenShotRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ScreenShotRespond message from the specified reader or buffer.
     * @function decode
     * @memberof ScreenShotRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ScreenShotRespond} ScreenShotRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ScreenShotRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ScreenShotRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.data = reader.bytes();
                    break;
                }
            case 3: {
                    message.receiverId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ScreenShotRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ScreenShotRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ScreenShotRespond} ScreenShotRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ScreenShotRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ScreenShotRespond message.
     * @function verify
     * @memberof ScreenShotRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ScreenShotRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        if (message.receiverId != null && message.hasOwnProperty("receiverId"))
            if (!$util.isInteger(message.receiverId) && !(message.receiverId && $util.isInteger(message.receiverId.low) && $util.isInteger(message.receiverId.high)))
                return "receiverId: integer|Long expected";
        return null;
    };

    /**
     * Creates a ScreenShotRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ScreenShotRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ScreenShotRespond} ScreenShotRespond
     */
    ScreenShotRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.ScreenShotRespond)
            return object;
        var message = new $root.ScreenShotRespond();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        if (object.receiverId != null)
            if ($util.Long)
                (message.receiverId = $util.Long.fromValue(object.receiverId)).unsigned = false;
            else if (typeof object.receiverId === "string")
                message.receiverId = parseInt(object.receiverId, 10);
            else if (typeof object.receiverId === "number")
                message.receiverId = object.receiverId;
            else if (typeof object.receiverId === "object")
                message.receiverId = new $util.LongBits(object.receiverId.low >>> 0, object.receiverId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a ScreenShotRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ScreenShotRespond
     * @static
     * @param {ScreenShotRespond} message ScreenShotRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ScreenShotRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.receiverId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.receiverId = options.longs === String ? "0" : 0;
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        if (message.receiverId != null && message.hasOwnProperty("receiverId"))
            if (typeof message.receiverId === "number")
                object.receiverId = options.longs === String ? String(message.receiverId) : message.receiverId;
            else
                object.receiverId = options.longs === String ? $util.Long.prototype.toString.call(message.receiverId) : options.longs === Number ? new $util.LongBits(message.receiverId.low >>> 0, message.receiverId.high >>> 0).toNumber() : message.receiverId;
        return object;
    };

    /**
     * Converts this ScreenShotRespond to JSON.
     * @function toJSON
     * @memberof ScreenShotRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ScreenShotRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ScreenShotRespond
     * @function getTypeUrl
     * @memberof ScreenShotRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ScreenShotRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ScreenShotRespond";
    };

    return ScreenShotRespond;
})();

$root.GetResourceRequest = (function() {

    /**
     * Properties of a GetResourceRequest.
     * @exports IGetResourceRequest
     * @interface IGetResourceRequest
     * @property {number|Long|null} [workerId] GetResourceRequest workerId
     * @property {string|null} [path] GetResourceRequest path
     */

    /**
     * Constructs a new GetResourceRequest.
     * @exports GetResourceRequest
     * @classdesc Represents a GetResourceRequest.
     * @implements IGetResourceRequest
     * @constructor
     * @param {IGetResourceRequest=} [properties] Properties to set
     */
    function GetResourceRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetResourceRequest workerId.
     * @member {number|Long} workerId
     * @memberof GetResourceRequest
     * @instance
     */
    GetResourceRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * GetResourceRequest path.
     * @member {string} path
     * @memberof GetResourceRequest
     * @instance
     */
    GetResourceRequest.prototype.path = "";

    /**
     * Creates a new GetResourceRequest instance using the specified properties.
     * @function create
     * @memberof GetResourceRequest
     * @static
     * @param {IGetResourceRequest=} [properties] Properties to set
     * @returns {GetResourceRequest} GetResourceRequest instance
     */
    GetResourceRequest.create = function create(properties) {
        return new GetResourceRequest(properties);
    };

    /**
     * Encodes the specified GetResourceRequest message. Does not implicitly {@link GetResourceRequest.verify|verify} messages.
     * @function encode
     * @memberof GetResourceRequest
     * @static
     * @param {IGetResourceRequest} message GetResourceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetResourceRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.path != null && Object.hasOwnProperty.call(message, "path"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
        return writer;
    };

    /**
     * Encodes the specified GetResourceRequest message, length delimited. Does not implicitly {@link GetResourceRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetResourceRequest
     * @static
     * @param {IGetResourceRequest} message GetResourceRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetResourceRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetResourceRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetResourceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetResourceRequest} GetResourceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetResourceRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetResourceRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.path = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetResourceRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetResourceRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetResourceRequest} GetResourceRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetResourceRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetResourceRequest message.
     * @function verify
     * @memberof GetResourceRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetResourceRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.path != null && message.hasOwnProperty("path"))
            if (!$util.isString(message.path))
                return "path: string expected";
        return null;
    };

    /**
     * Creates a GetResourceRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetResourceRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetResourceRequest} GetResourceRequest
     */
    GetResourceRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetResourceRequest)
            return object;
        var message = new $root.GetResourceRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.path != null)
            message.path = String(object.path);
        return message;
    };

    /**
     * Creates a plain object from a GetResourceRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetResourceRequest
     * @static
     * @param {GetResourceRequest} message GetResourceRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetResourceRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            object.path = "";
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.path != null && message.hasOwnProperty("path"))
            object.path = message.path;
        return object;
    };

    /**
     * Converts this GetResourceRequest to JSON.
     * @function toJSON
     * @memberof GetResourceRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetResourceRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetResourceRequest
     * @function getTypeUrl
     * @memberof GetResourceRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetResourceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetResourceRequest";
    };

    return GetResourceRequest;
})();

$root.GetResourceRespond = (function() {

    /**
     * Properties of a GetResourceRespond.
     * @exports IGetResourceRespond
     * @interface IGetResourceRespond
     * @property {number|Long|null} [workerId] GetResourceRespond workerId
     * @property {Uint8Array|null} [data] GetResourceRespond data
     */

    /**
     * Constructs a new GetResourceRespond.
     * @exports GetResourceRespond
     * @classdesc Represents a GetResourceRespond.
     * @implements IGetResourceRespond
     * @constructor
     * @param {IGetResourceRespond=} [properties] Properties to set
     */
    function GetResourceRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetResourceRespond workerId.
     * @member {number|Long} workerId
     * @memberof GetResourceRespond
     * @instance
     */
    GetResourceRespond.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * GetResourceRespond data.
     * @member {Uint8Array} data
     * @memberof GetResourceRespond
     * @instance
     */
    GetResourceRespond.prototype.data = $util.newBuffer([]);

    /**
     * Creates a new GetResourceRespond instance using the specified properties.
     * @function create
     * @memberof GetResourceRespond
     * @static
     * @param {IGetResourceRespond=} [properties] Properties to set
     * @returns {GetResourceRespond} GetResourceRespond instance
     */
    GetResourceRespond.create = function create(properties) {
        return new GetResourceRespond(properties);
    };

    /**
     * Encodes the specified GetResourceRespond message. Does not implicitly {@link GetResourceRespond.verify|verify} messages.
     * @function encode
     * @memberof GetResourceRespond
     * @static
     * @param {IGetResourceRespond} message GetResourceRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetResourceRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
        return writer;
    };

    /**
     * Encodes the specified GetResourceRespond message, length delimited. Does not implicitly {@link GetResourceRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetResourceRespond
     * @static
     * @param {IGetResourceRespond} message GetResourceRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetResourceRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetResourceRespond message from the specified reader or buffer.
     * @function decode
     * @memberof GetResourceRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetResourceRespond} GetResourceRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetResourceRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetResourceRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.data = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetResourceRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetResourceRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetResourceRespond} GetResourceRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetResourceRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetResourceRespond message.
     * @function verify
     * @memberof GetResourceRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetResourceRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        return null;
    };

    /**
     * Creates a GetResourceRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetResourceRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetResourceRespond} GetResourceRespond
     */
    GetResourceRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.GetResourceRespond)
            return object;
        var message = new $root.GetResourceRespond();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        return message;
    };

    /**
     * Creates a plain object from a GetResourceRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetResourceRespond
     * @static
     * @param {GetResourceRespond} message GetResourceRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetResourceRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        return object;
    };

    /**
     * Converts this GetResourceRespond to JSON.
     * @function toJSON
     * @memberof GetResourceRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetResourceRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetResourceRespond
     * @function getTypeUrl
     * @memberof GetResourceRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetResourceRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetResourceRespond";
    };

    return GetResourceRespond;
})();

$root.ReadSettingRequest = (function() {

    /**
     * Properties of a ReadSettingRequest.
     * @exports IReadSettingRequest
     * @interface IReadSettingRequest
     * @property {number|Long|null} [id] ReadSettingRequest id
     * @property {string|null} [key] ReadSettingRequest key
     * @property {string|null} [deviceName] ReadSettingRequest deviceName
     */

    /**
     * Constructs a new ReadSettingRequest.
     * @exports ReadSettingRequest
     * @classdesc Represents a ReadSettingRequest.
     * @implements IReadSettingRequest
     * @constructor
     * @param {IReadSettingRequest=} [properties] Properties to set
     */
    function ReadSettingRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReadSettingRequest id.
     * @member {number|Long} id
     * @memberof ReadSettingRequest
     * @instance
     */
    ReadSettingRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ReadSettingRequest key.
     * @member {string} key
     * @memberof ReadSettingRequest
     * @instance
     */
    ReadSettingRequest.prototype.key = "";

    /**
     * ReadSettingRequest deviceName.
     * @member {string} deviceName
     * @memberof ReadSettingRequest
     * @instance
     */
    ReadSettingRequest.prototype.deviceName = "";

    /**
     * Creates a new ReadSettingRequest instance using the specified properties.
     * @function create
     * @memberof ReadSettingRequest
     * @static
     * @param {IReadSettingRequest=} [properties] Properties to set
     * @returns {ReadSettingRequest} ReadSettingRequest instance
     */
    ReadSettingRequest.create = function create(properties) {
        return new ReadSettingRequest(properties);
    };

    /**
     * Encodes the specified ReadSettingRequest message. Does not implicitly {@link ReadSettingRequest.verify|verify} messages.
     * @function encode
     * @memberof ReadSettingRequest
     * @static
     * @param {IReadSettingRequest} message ReadSettingRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReadSettingRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.deviceName);
        return writer;
    };

    /**
     * Encodes the specified ReadSettingRequest message, length delimited. Does not implicitly {@link ReadSettingRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ReadSettingRequest
     * @static
     * @param {IReadSettingRequest} message ReadSettingRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReadSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReadSettingRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ReadSettingRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ReadSettingRequest} ReadSettingRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReadSettingRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ReadSettingRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int64();
                    break;
                }
            case 2: {
                    message.key = reader.string();
                    break;
                }
            case 3: {
                    message.deviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ReadSettingRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ReadSettingRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ReadSettingRequest} ReadSettingRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReadSettingRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReadSettingRequest message.
     * @function verify
     * @memberof ReadSettingRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReadSettingRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isString(message.key))
                return "key: string expected";
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            if (!$util.isString(message.deviceName))
                return "deviceName: string expected";
        return null;
    };

    /**
     * Creates a ReadSettingRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ReadSettingRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ReadSettingRequest} ReadSettingRequest
     */
    ReadSettingRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ReadSettingRequest)
            return object;
        var message = new $root.ReadSettingRequest();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        if (object.key != null)
            message.key = String(object.key);
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        return message;
    };

    /**
     * Creates a plain object from a ReadSettingRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ReadSettingRequest
     * @static
     * @param {ReadSettingRequest} message ReadSettingRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReadSettingRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            object.key = "";
            object.deviceName = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        return object;
    };

    /**
     * Converts this ReadSettingRequest to JSON.
     * @function toJSON
     * @memberof ReadSettingRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReadSettingRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReadSettingRequest
     * @function getTypeUrl
     * @memberof ReadSettingRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReadSettingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ReadSettingRequest";
    };

    return ReadSettingRequest;
})();

$root.ReadSettingRespond = (function() {

    /**
     * Properties of a ReadSettingRespond.
     * @exports IReadSettingRespond
     * @interface IReadSettingRespond
     * @property {number|Long|null} [id] ReadSettingRespond id
     * @property {string|null} [value] ReadSettingRespond value
     */

    /**
     * Constructs a new ReadSettingRespond.
     * @exports ReadSettingRespond
     * @classdesc Represents a ReadSettingRespond.
     * @implements IReadSettingRespond
     * @constructor
     * @param {IReadSettingRespond=} [properties] Properties to set
     */
    function ReadSettingRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReadSettingRespond id.
     * @member {number|Long} id
     * @memberof ReadSettingRespond
     * @instance
     */
    ReadSettingRespond.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ReadSettingRespond value.
     * @member {string} value
     * @memberof ReadSettingRespond
     * @instance
     */
    ReadSettingRespond.prototype.value = "";

    /**
     * Creates a new ReadSettingRespond instance using the specified properties.
     * @function create
     * @memberof ReadSettingRespond
     * @static
     * @param {IReadSettingRespond=} [properties] Properties to set
     * @returns {ReadSettingRespond} ReadSettingRespond instance
     */
    ReadSettingRespond.create = function create(properties) {
        return new ReadSettingRespond(properties);
    };

    /**
     * Encodes the specified ReadSettingRespond message. Does not implicitly {@link ReadSettingRespond.verify|verify} messages.
     * @function encode
     * @memberof ReadSettingRespond
     * @static
     * @param {IReadSettingRespond} message ReadSettingRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReadSettingRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        return writer;
    };

    /**
     * Encodes the specified ReadSettingRespond message, length delimited. Does not implicitly {@link ReadSettingRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ReadSettingRespond
     * @static
     * @param {IReadSettingRespond} message ReadSettingRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReadSettingRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReadSettingRespond message from the specified reader or buffer.
     * @function decode
     * @memberof ReadSettingRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ReadSettingRespond} ReadSettingRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReadSettingRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ReadSettingRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int64();
                    break;
                }
            case 2: {
                    message.value = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ReadSettingRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ReadSettingRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ReadSettingRespond} ReadSettingRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReadSettingRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReadSettingRespond message.
     * @function verify
     * @memberof ReadSettingRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReadSettingRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        return null;
    };

    /**
     * Creates a ReadSettingRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ReadSettingRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ReadSettingRespond} ReadSettingRespond
     */
    ReadSettingRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.ReadSettingRespond)
            return object;
        var message = new $root.ReadSettingRespond();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        if (object.value != null)
            message.value = String(object.value);
        return message;
    };

    /**
     * Creates a plain object from a ReadSettingRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ReadSettingRespond
     * @static
     * @param {ReadSettingRespond} message ReadSettingRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReadSettingRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
            object.value = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        return object;
    };

    /**
     * Converts this ReadSettingRespond to JSON.
     * @function toJSON
     * @memberof ReadSettingRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReadSettingRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReadSettingRespond
     * @function getTypeUrl
     * @memberof ReadSettingRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReadSettingRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ReadSettingRespond";
    };

    return ReadSettingRespond;
})();

$root.WriteSettingRequest = (function() {

    /**
     * Properties of a WriteSettingRequest.
     * @exports IWriteSettingRequest
     * @interface IWriteSettingRequest
     * @property {string|null} [key] WriteSettingRequest key
     * @property {string|null} [value] WriteSettingRequest value
     * @property {string|null} [deviceName] WriteSettingRequest deviceName
     */

    /**
     * Constructs a new WriteSettingRequest.
     * @exports WriteSettingRequest
     * @classdesc Represents a WriteSettingRequest.
     * @implements IWriteSettingRequest
     * @constructor
     * @param {IWriteSettingRequest=} [properties] Properties to set
     */
    function WriteSettingRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WriteSettingRequest key.
     * @member {string} key
     * @memberof WriteSettingRequest
     * @instance
     */
    WriteSettingRequest.prototype.key = "";

    /**
     * WriteSettingRequest value.
     * @member {string} value
     * @memberof WriteSettingRequest
     * @instance
     */
    WriteSettingRequest.prototype.value = "";

    /**
     * WriteSettingRequest deviceName.
     * @member {string} deviceName
     * @memberof WriteSettingRequest
     * @instance
     */
    WriteSettingRequest.prototype.deviceName = "";

    /**
     * Creates a new WriteSettingRequest instance using the specified properties.
     * @function create
     * @memberof WriteSettingRequest
     * @static
     * @param {IWriteSettingRequest=} [properties] Properties to set
     * @returns {WriteSettingRequest} WriteSettingRequest instance
     */
    WriteSettingRequest.create = function create(properties) {
        return new WriteSettingRequest(properties);
    };

    /**
     * Encodes the specified WriteSettingRequest message. Does not implicitly {@link WriteSettingRequest.verify|verify} messages.
     * @function encode
     * @memberof WriteSettingRequest
     * @static
     * @param {IWriteSettingRequest} message WriteSettingRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WriteSettingRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.key != null && Object.hasOwnProperty.call(message, "key"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        if (message.deviceName != null && Object.hasOwnProperty.call(message, "deviceName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.deviceName);
        return writer;
    };

    /**
     * Encodes the specified WriteSettingRequest message, length delimited. Does not implicitly {@link WriteSettingRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof WriteSettingRequest
     * @static
     * @param {IWriteSettingRequest} message WriteSettingRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WriteSettingRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WriteSettingRequest message from the specified reader or buffer.
     * @function decode
     * @memberof WriteSettingRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WriteSettingRequest} WriteSettingRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WriteSettingRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WriteSettingRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.key = reader.string();
                    break;
                }
            case 2: {
                    message.value = reader.string();
                    break;
                }
            case 3: {
                    message.deviceName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a WriteSettingRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof WriteSettingRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {WriteSettingRequest} WriteSettingRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WriteSettingRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WriteSettingRequest message.
     * @function verify
     * @memberof WriteSettingRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WriteSettingRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.key != null && message.hasOwnProperty("key"))
            if (!$util.isString(message.key))
                return "key: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            if (!$util.isString(message.deviceName))
                return "deviceName: string expected";
        return null;
    };

    /**
     * Creates a WriteSettingRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof WriteSettingRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {WriteSettingRequest} WriteSettingRequest
     */
    WriteSettingRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.WriteSettingRequest)
            return object;
        var message = new $root.WriteSettingRequest();
        if (object.key != null)
            message.key = String(object.key);
        if (object.value != null)
            message.value = String(object.value);
        if (object.deviceName != null)
            message.deviceName = String(object.deviceName);
        return message;
    };

    /**
     * Creates a plain object from a WriteSettingRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof WriteSettingRequest
     * @static
     * @param {WriteSettingRequest} message WriteSettingRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WriteSettingRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.key = "";
            object.value = "";
            object.deviceName = "";
        }
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.deviceName != null && message.hasOwnProperty("deviceName"))
            object.deviceName = message.deviceName;
        return object;
    };

    /**
     * Converts this WriteSettingRequest to JSON.
     * @function toJSON
     * @memberof WriteSettingRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WriteSettingRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WriteSettingRequest
     * @function getTypeUrl
     * @memberof WriteSettingRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WriteSettingRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WriteSettingRequest";
    };

    return WriteSettingRequest;
})();

$root.RecognizeRequest = (function() {

    /**
     * Properties of a RecognizeRequest.
     * @exports IRecognizeRequest
     * @interface IRecognizeRequest
     * @property {string|null} [type] RecognizeRequest type
     * @property {Uint8Array|null} [data] RecognizeRequest data
     * @property {RecognizeRequest.DataFormat|null} [format] RecognizeRequest format
     */

    /**
     * Constructs a new RecognizeRequest.
     * @exports RecognizeRequest
     * @classdesc Represents a RecognizeRequest.
     * @implements IRecognizeRequest
     * @constructor
     * @param {IRecognizeRequest=} [properties] Properties to set
     */
    function RecognizeRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RecognizeRequest type.
     * @member {string} type
     * @memberof RecognizeRequest
     * @instance
     */
    RecognizeRequest.prototype.type = "";

    /**
     * RecognizeRequest data.
     * @member {Uint8Array} data
     * @memberof RecognizeRequest
     * @instance
     */
    RecognizeRequest.prototype.data = $util.newBuffer([]);

    /**
     * RecognizeRequest format.
     * @member {RecognizeRequest.DataFormat} format
     * @memberof RecognizeRequest
     * @instance
     */
    RecognizeRequest.prototype.format = 0;

    /**
     * Creates a new RecognizeRequest instance using the specified properties.
     * @function create
     * @memberof RecognizeRequest
     * @static
     * @param {IRecognizeRequest=} [properties] Properties to set
     * @returns {RecognizeRequest} RecognizeRequest instance
     */
    RecognizeRequest.create = function create(properties) {
        return new RecognizeRequest(properties);
    };

    /**
     * Encodes the specified RecognizeRequest message. Does not implicitly {@link RecognizeRequest.verify|verify} messages.
     * @function encode
     * @memberof RecognizeRequest
     * @static
     * @param {IRecognizeRequest} message RecognizeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecognizeRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
        if (message.format != null && Object.hasOwnProperty.call(message, "format"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.format);
        return writer;
    };

    /**
     * Encodes the specified RecognizeRequest message, length delimited. Does not implicitly {@link RecognizeRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RecognizeRequest
     * @static
     * @param {IRecognizeRequest} message RecognizeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecognizeRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RecognizeRequest message from the specified reader or buffer.
     * @function decode
     * @memberof RecognizeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RecognizeRequest} RecognizeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecognizeRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RecognizeRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.type = reader.string();
                    break;
                }
            case 2: {
                    message.data = reader.bytes();
                    break;
                }
            case 3: {
                    message.format = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RecognizeRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RecognizeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RecognizeRequest} RecognizeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecognizeRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RecognizeRequest message.
     * @function verify
     * @memberof RecognizeRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RecognizeRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isString(message.type))
                return "type: string expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        if (message.format != null && message.hasOwnProperty("format"))
            switch (message.format) {
            default:
                return "format: enum value expected";
            case 0:
            case 1:
                break;
            }
        return null;
    };

    /**
     * Creates a RecognizeRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RecognizeRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RecognizeRequest} RecognizeRequest
     */
    RecognizeRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.RecognizeRequest)
            return object;
        var message = new $root.RecognizeRequest();
        if (object.type != null)
            message.type = String(object.type);
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        switch (object.format) {
        default:
            if (typeof object.format === "number") {
                message.format = object.format;
                break;
            }
            break;
        case "Png":
        case 0:
            message.format = 0;
            break;
        case "Jpeg":
        case 1:
            message.format = 1;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a RecognizeRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RecognizeRequest
     * @static
     * @param {RecognizeRequest} message RecognizeRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RecognizeRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.type = "";
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
            object.format = options.enums === String ? "Png" : 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        if (message.format != null && message.hasOwnProperty("format"))
            object.format = options.enums === String ? $root.RecognizeRequest.DataFormat[message.format] === undefined ? message.format : $root.RecognizeRequest.DataFormat[message.format] : message.format;
        return object;
    };

    /**
     * Converts this RecognizeRequest to JSON.
     * @function toJSON
     * @memberof RecognizeRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RecognizeRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RecognizeRequest
     * @function getTypeUrl
     * @memberof RecognizeRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RecognizeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RecognizeRequest";
    };

    /**
     * DataFormat enum.
     * @name RecognizeRequest.DataFormat
     * @enum {number}
     * @property {number} Png=0 Png value
     * @property {number} Jpeg=1 Jpeg value
     */
    RecognizeRequest.DataFormat = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Png"] = 0;
        values[valuesById[1] = "Jpeg"] = 1;
        return values;
    })();

    return RecognizeRequest;
})();

$root.RecognizeRespond = (function() {

    /**
     * Properties of a RecognizeRespond.
     * @exports IRecognizeRespond
     * @interface IRecognizeRespond
     * @property {Uint8Array|null} [result] RecognizeRespond result
     */

    /**
     * Constructs a new RecognizeRespond.
     * @exports RecognizeRespond
     * @classdesc Represents a RecognizeRespond.
     * @implements IRecognizeRespond
     * @constructor
     * @param {IRecognizeRespond=} [properties] Properties to set
     */
    function RecognizeRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RecognizeRespond result.
     * @member {Uint8Array} result
     * @memberof RecognizeRespond
     * @instance
     */
    RecognizeRespond.prototype.result = $util.newBuffer([]);

    /**
     * Creates a new RecognizeRespond instance using the specified properties.
     * @function create
     * @memberof RecognizeRespond
     * @static
     * @param {IRecognizeRespond=} [properties] Properties to set
     * @returns {RecognizeRespond} RecognizeRespond instance
     */
    RecognizeRespond.create = function create(properties) {
        return new RecognizeRespond(properties);
    };

    /**
     * Encodes the specified RecognizeRespond message. Does not implicitly {@link RecognizeRespond.verify|verify} messages.
     * @function encode
     * @memberof RecognizeRespond
     * @static
     * @param {IRecognizeRespond} message RecognizeRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecognizeRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.result);
        return writer;
    };

    /**
     * Encodes the specified RecognizeRespond message, length delimited. Does not implicitly {@link RecognizeRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RecognizeRespond
     * @static
     * @param {IRecognizeRespond} message RecognizeRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecognizeRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RecognizeRespond message from the specified reader or buffer.
     * @function decode
     * @memberof RecognizeRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RecognizeRespond} RecognizeRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecognizeRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RecognizeRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RecognizeRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RecognizeRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RecognizeRespond} RecognizeRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecognizeRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RecognizeRespond message.
     * @function verify
     * @memberof RecognizeRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RecognizeRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!(message.result && typeof message.result.length === "number" || $util.isString(message.result)))
                return "result: buffer expected";
        return null;
    };

    /**
     * Creates a RecognizeRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RecognizeRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RecognizeRespond} RecognizeRespond
     */
    RecognizeRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.RecognizeRespond)
            return object;
        var message = new $root.RecognizeRespond();
        if (object.result != null)
            if (typeof object.result === "string")
                $util.base64.decode(object.result, message.result = $util.newBuffer($util.base64.length(object.result)), 0);
            else if (object.result.length >= 0)
                message.result = object.result;
        return message;
    };

    /**
     * Creates a plain object from a RecognizeRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RecognizeRespond
     * @static
     * @param {RecognizeRespond} message RecognizeRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RecognizeRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if (options.bytes === String)
                object.result = "";
            else {
                object.result = [];
                if (options.bytes !== Array)
                    object.result = $util.newBuffer(object.result);
            }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.bytes === String ? $util.base64.encode(message.result, 0, message.result.length) : options.bytes === Array ? Array.prototype.slice.call(message.result) : message.result;
        return object;
    };

    /**
     * Converts this RecognizeRespond to JSON.
     * @function toJSON
     * @memberof RecognizeRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RecognizeRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RecognizeRespond
     * @function getTypeUrl
     * @memberof RecognizeRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RecognizeRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RecognizeRespond";
    };

    return RecognizeRespond;
})();

$root.TaskInfo = (function() {

    /**
     * Properties of a TaskInfo.
     * @exports ITaskInfo
     * @interface ITaskInfo
     * @property {string|null} [name] TaskInfo name
     * @property {string|null} [configureLayout] TaskInfo configureLayout
     */

    /**
     * Constructs a new TaskInfo.
     * @exports TaskInfo
     * @classdesc Represents a TaskInfo.
     * @implements ITaskInfo
     * @constructor
     * @param {ITaskInfo=} [properties] Properties to set
     */
    function TaskInfo(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TaskInfo name.
     * @member {string} name
     * @memberof TaskInfo
     * @instance
     */
    TaskInfo.prototype.name = "";

    /**
     * TaskInfo configureLayout.
     * @member {string} configureLayout
     * @memberof TaskInfo
     * @instance
     */
    TaskInfo.prototype.configureLayout = "";

    /**
     * Creates a new TaskInfo instance using the specified properties.
     * @function create
     * @memberof TaskInfo
     * @static
     * @param {ITaskInfo=} [properties] Properties to set
     * @returns {TaskInfo} TaskInfo instance
     */
    TaskInfo.create = function create(properties) {
        return new TaskInfo(properties);
    };

    /**
     * Encodes the specified TaskInfo message. Does not implicitly {@link TaskInfo.verify|verify} messages.
     * @function encode
     * @memberof TaskInfo
     * @static
     * @param {ITaskInfo} message TaskInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TaskInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        if (message.configureLayout != null && Object.hasOwnProperty.call(message, "configureLayout"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.configureLayout);
        return writer;
    };

    /**
     * Encodes the specified TaskInfo message, length delimited. Does not implicitly {@link TaskInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof TaskInfo
     * @static
     * @param {ITaskInfo} message TaskInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TaskInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TaskInfo message from the specified reader or buffer.
     * @function decode
     * @memberof TaskInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TaskInfo} TaskInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TaskInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.TaskInfo();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.name = reader.string();
                    break;
                }
            case 2: {
                    message.configureLayout = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a TaskInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof TaskInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {TaskInfo} TaskInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TaskInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TaskInfo message.
     * @function verify
     * @memberof TaskInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TaskInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.configureLayout != null && message.hasOwnProperty("configureLayout"))
            if (!$util.isString(message.configureLayout))
                return "configureLayout: string expected";
        return null;
    };

    /**
     * Creates a TaskInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof TaskInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {TaskInfo} TaskInfo
     */
    TaskInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.TaskInfo)
            return object;
        var message = new $root.TaskInfo();
        if (object.name != null)
            message.name = String(object.name);
        if (object.configureLayout != null)
            message.configureLayout = String(object.configureLayout);
        return message;
    };

    /**
     * Creates a plain object from a TaskInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof TaskInfo
     * @static
     * @param {TaskInfo} message TaskInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TaskInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.name = "";
            object.configureLayout = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.configureLayout != null && message.hasOwnProperty("configureLayout"))
            object.configureLayout = message.configureLayout;
        return object;
    };

    /**
     * Converts this TaskInfo to JSON.
     * @function toJSON
     * @memberof TaskInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TaskInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TaskInfo
     * @function getTypeUrl
     * @memberof TaskInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TaskInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/TaskInfo";
    };

    return TaskInfo;
})();

$root.GetTaskRequest = (function() {

    /**
     * Properties of a GetTaskRequest.
     * @exports IGetTaskRequest
     * @interface IGetTaskRequest
     */

    /**
     * Constructs a new GetTaskRequest.
     * @exports GetTaskRequest
     * @classdesc Represents a GetTaskRequest.
     * @implements IGetTaskRequest
     * @constructor
     * @param {IGetTaskRequest=} [properties] Properties to set
     */
    function GetTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetTaskRequest instance using the specified properties.
     * @function create
     * @memberof GetTaskRequest
     * @static
     * @param {IGetTaskRequest=} [properties] Properties to set
     * @returns {GetTaskRequest} GetTaskRequest instance
     */
    GetTaskRequest.create = function create(properties) {
        return new GetTaskRequest(properties);
    };

    /**
     * Encodes the specified GetTaskRequest message. Does not implicitly {@link GetTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof GetTaskRequest
     * @static
     * @param {IGetTaskRequest} message GetTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetTaskRequest message, length delimited. Does not implicitly {@link GetTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetTaskRequest
     * @static
     * @param {IGetTaskRequest} message GetTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetTaskRequest} GetTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetTaskRequest} GetTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetTaskRequest message.
     * @function verify
     * @memberof GetTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetTaskRequest} GetTaskRequest
     */
    GetTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetTaskRequest)
            return object;
        return new $root.GetTaskRequest();
    };

    /**
     * Creates a plain object from a GetTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetTaskRequest
     * @static
     * @param {GetTaskRequest} message GetTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetTaskRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetTaskRequest to JSON.
     * @function toJSON
     * @memberof GetTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetTaskRequest
     * @function getTypeUrl
     * @memberof GetTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetTaskRequest";
    };

    return GetTaskRequest;
})();

$root.GetTaskRespond = (function() {

    /**
     * Properties of a GetTaskRespond.
     * @exports IGetTaskRespond
     * @interface IGetTaskRespond
     * @property {Array.<ITaskInfo>|null} [info] GetTaskRespond info
     */

    /**
     * Constructs a new GetTaskRespond.
     * @exports GetTaskRespond
     * @classdesc Represents a GetTaskRespond.
     * @implements IGetTaskRespond
     * @constructor
     * @param {IGetTaskRespond=} [properties] Properties to set
     */
    function GetTaskRespond(properties) {
        this.info = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetTaskRespond info.
     * @member {Array.<ITaskInfo>} info
     * @memberof GetTaskRespond
     * @instance
     */
    GetTaskRespond.prototype.info = $util.emptyArray;

    /**
     * Creates a new GetTaskRespond instance using the specified properties.
     * @function create
     * @memberof GetTaskRespond
     * @static
     * @param {IGetTaskRespond=} [properties] Properties to set
     * @returns {GetTaskRespond} GetTaskRespond instance
     */
    GetTaskRespond.create = function create(properties) {
        return new GetTaskRespond(properties);
    };

    /**
     * Encodes the specified GetTaskRespond message. Does not implicitly {@link GetTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof GetTaskRespond
     * @static
     * @param {IGetTaskRespond} message GetTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.info != null && message.info.length)
            for (var i = 0; i < message.info.length; ++i)
                $root.TaskInfo.encode(message.info[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetTaskRespond message, length delimited. Does not implicitly {@link GetTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetTaskRespond
     * @static
     * @param {IGetTaskRespond} message GetTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof GetTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetTaskRespond} GetTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.info && message.info.length))
                        message.info = [];
                    message.info.push($root.TaskInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetTaskRespond} GetTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetTaskRespond message.
     * @function verify
     * @memberof GetTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.info != null && message.hasOwnProperty("info")) {
            if (!Array.isArray(message.info))
                return "info: array expected";
            for (var i = 0; i < message.info.length; ++i) {
                var error = $root.TaskInfo.verify(message.info[i]);
                if (error)
                    return "info." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetTaskRespond} GetTaskRespond
     */
    GetTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.GetTaskRespond)
            return object;
        var message = new $root.GetTaskRespond();
        if (object.info) {
            if (!Array.isArray(object.info))
                throw TypeError(".GetTaskRespond.info: array expected");
            message.info = [];
            for (var i = 0; i < object.info.length; ++i) {
                if (typeof object.info[i] !== "object")
                    throw TypeError(".GetTaskRespond.info: object expected");
                message.info[i] = $root.TaskInfo.fromObject(object.info[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetTaskRespond
     * @static
     * @param {GetTaskRespond} message GetTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.info = [];
        if (message.info && message.info.length) {
            object.info = [];
            for (var j = 0; j < message.info.length; ++j)
                object.info[j] = $root.TaskInfo.toObject(message.info[j], options);
        }
        return object;
    };

    /**
     * Converts this GetTaskRespond to JSON.
     * @function toJSON
     * @memberof GetTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetTaskRespond
     * @function getTypeUrl
     * @memberof GetTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetTaskRespond";
    };

    return GetTaskRespond;
})();

$root.ExecuteTaskRequest = (function() {

    /**
     * Properties of an ExecuteTaskRequest.
     * @exports IExecuteTaskRequest
     * @interface IExecuteTaskRequest
     * @property {number|Long|null} [workerId] ExecuteTaskRequest workerId
     * @property {string|null} [taskName] ExecuteTaskRequest taskName
     * @property {string|null} [args] ExecuteTaskRequest args
     */

    /**
     * Constructs a new ExecuteTaskRequest.
     * @exports ExecuteTaskRequest
     * @classdesc Represents an ExecuteTaskRequest.
     * @implements IExecuteTaskRequest
     * @constructor
     * @param {IExecuteTaskRequest=} [properties] Properties to set
     */
    function ExecuteTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExecuteTaskRequest workerId.
     * @member {number|Long} workerId
     * @memberof ExecuteTaskRequest
     * @instance
     */
    ExecuteTaskRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ExecuteTaskRequest taskName.
     * @member {string} taskName
     * @memberof ExecuteTaskRequest
     * @instance
     */
    ExecuteTaskRequest.prototype.taskName = "";

    /**
     * ExecuteTaskRequest args.
     * @member {string} args
     * @memberof ExecuteTaskRequest
     * @instance
     */
    ExecuteTaskRequest.prototype.args = "";

    /**
     * Creates a new ExecuteTaskRequest instance using the specified properties.
     * @function create
     * @memberof ExecuteTaskRequest
     * @static
     * @param {IExecuteTaskRequest=} [properties] Properties to set
     * @returns {ExecuteTaskRequest} ExecuteTaskRequest instance
     */
    ExecuteTaskRequest.create = function create(properties) {
        return new ExecuteTaskRequest(properties);
    };

    /**
     * Encodes the specified ExecuteTaskRequest message. Does not implicitly {@link ExecuteTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof ExecuteTaskRequest
     * @static
     * @param {IExecuteTaskRequest} message ExecuteTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExecuteTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        if (message.taskName != null && Object.hasOwnProperty.call(message, "taskName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskName);
        if (message.args != null && Object.hasOwnProperty.call(message, "args"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.args);
        return writer;
    };

    /**
     * Encodes the specified ExecuteTaskRequest message, length delimited. Does not implicitly {@link ExecuteTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ExecuteTaskRequest
     * @static
     * @param {IExecuteTaskRequest} message ExecuteTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExecuteTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ExecuteTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ExecuteTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExecuteTaskRequest} ExecuteTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExecuteTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExecuteTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            case 2: {
                    message.taskName = reader.string();
                    break;
                }
            case 3: {
                    message.args = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ExecuteTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ExecuteTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ExecuteTaskRequest} ExecuteTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExecuteTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ExecuteTaskRequest message.
     * @function verify
     * @memberof ExecuteTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ExecuteTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        if (message.taskName != null && message.hasOwnProperty("taskName"))
            if (!$util.isString(message.taskName))
                return "taskName: string expected";
        if (message.args != null && message.hasOwnProperty("args"))
            if (!$util.isString(message.args))
                return "args: string expected";
        return null;
    };

    /**
     * Creates an ExecuteTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ExecuteTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ExecuteTaskRequest} ExecuteTaskRequest
     */
    ExecuteTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ExecuteTaskRequest)
            return object;
        var message = new $root.ExecuteTaskRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        if (object.taskName != null)
            message.taskName = String(object.taskName);
        if (object.args != null)
            message.args = String(object.args);
        return message;
    };

    /**
     * Creates a plain object from an ExecuteTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ExecuteTaskRequest
     * @static
     * @param {ExecuteTaskRequest} message ExecuteTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ExecuteTaskRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
            object.taskName = "";
            object.args = "";
        }
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        if (message.taskName != null && message.hasOwnProperty("taskName"))
            object.taskName = message.taskName;
        if (message.args != null && message.hasOwnProperty("args"))
            object.args = message.args;
        return object;
    };

    /**
     * Converts this ExecuteTaskRequest to JSON.
     * @function toJSON
     * @memberof ExecuteTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ExecuteTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ExecuteTaskRequest
     * @function getTypeUrl
     * @memberof ExecuteTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ExecuteTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ExecuteTaskRequest";
    };

    return ExecuteTaskRequest;
})();

$root.PublishMessageRequest = (function() {

    /**
     * Properties of a PublishMessageRequest.
     * @exports IPublishMessageRequest
     * @interface IPublishMessageRequest
     * @property {string|null} [message] PublishMessageRequest message
     */

    /**
     * Constructs a new PublishMessageRequest.
     * @exports PublishMessageRequest
     * @classdesc Represents a PublishMessageRequest.
     * @implements IPublishMessageRequest
     * @constructor
     * @param {IPublishMessageRequest=} [properties] Properties to set
     */
    function PublishMessageRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PublishMessageRequest message.
     * @member {string} message
     * @memberof PublishMessageRequest
     * @instance
     */
    PublishMessageRequest.prototype.message = "";

    /**
     * Creates a new PublishMessageRequest instance using the specified properties.
     * @function create
     * @memberof PublishMessageRequest
     * @static
     * @param {IPublishMessageRequest=} [properties] Properties to set
     * @returns {PublishMessageRequest} PublishMessageRequest instance
     */
    PublishMessageRequest.create = function create(properties) {
        return new PublishMessageRequest(properties);
    };

    /**
     * Encodes the specified PublishMessageRequest message. Does not implicitly {@link PublishMessageRequest.verify|verify} messages.
     * @function encode
     * @memberof PublishMessageRequest
     * @static
     * @param {IPublishMessageRequest} message PublishMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PublishMessageRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified PublishMessageRequest message, length delimited. Does not implicitly {@link PublishMessageRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof PublishMessageRequest
     * @static
     * @param {IPublishMessageRequest} message PublishMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PublishMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PublishMessageRequest message from the specified reader or buffer.
     * @function decode
     * @memberof PublishMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PublishMessageRequest} PublishMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PublishMessageRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PublishMessageRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 3: {
                    message.message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a PublishMessageRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof PublishMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {PublishMessageRequest} PublishMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PublishMessageRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PublishMessageRequest message.
     * @function verify
     * @memberof PublishMessageRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PublishMessageRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        return null;
    };

    /**
     * Creates a PublishMessageRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof PublishMessageRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {PublishMessageRequest} PublishMessageRequest
     */
    PublishMessageRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.PublishMessageRequest)
            return object;
        var message = new $root.PublishMessageRequest();
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from a PublishMessageRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof PublishMessageRequest
     * @static
     * @param {PublishMessageRequest} message PublishMessageRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PublishMessageRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.message = "";
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this PublishMessageRequest to JSON.
     * @function toJSON
     * @memberof PublishMessageRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PublishMessageRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PublishMessageRequest
     * @function getTypeUrl
     * @memberof PublishMessageRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PublishMessageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PublishMessageRequest";
    };

    return PublishMessageRequest;
})();

$root.SubscribeMessageRequest = (function() {

    /**
     * Properties of a SubscribeMessageRequest.
     * @exports ISubscribeMessageRequest
     * @interface ISubscribeMessageRequest
     */

    /**
     * Constructs a new SubscribeMessageRequest.
     * @exports SubscribeMessageRequest
     * @classdesc Represents a SubscribeMessageRequest.
     * @implements ISubscribeMessageRequest
     * @constructor
     * @param {ISubscribeMessageRequest=} [properties] Properties to set
     */
    function SubscribeMessageRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SubscribeMessageRequest instance using the specified properties.
     * @function create
     * @memberof SubscribeMessageRequest
     * @static
     * @param {ISubscribeMessageRequest=} [properties] Properties to set
     * @returns {SubscribeMessageRequest} SubscribeMessageRequest instance
     */
    SubscribeMessageRequest.create = function create(properties) {
        return new SubscribeMessageRequest(properties);
    };

    /**
     * Encodes the specified SubscribeMessageRequest message. Does not implicitly {@link SubscribeMessageRequest.verify|verify} messages.
     * @function encode
     * @memberof SubscribeMessageRequest
     * @static
     * @param {ISubscribeMessageRequest} message SubscribeMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeMessageRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified SubscribeMessageRequest message, length delimited. Does not implicitly {@link SubscribeMessageRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubscribeMessageRequest
     * @static
     * @param {ISubscribeMessageRequest} message SubscribeMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubscribeMessageRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SubscribeMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubscribeMessageRequest} SubscribeMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeMessageRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscribeMessageRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubscribeMessageRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubscribeMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubscribeMessageRequest} SubscribeMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeMessageRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubscribeMessageRequest message.
     * @function verify
     * @memberof SubscribeMessageRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubscribeMessageRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a SubscribeMessageRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubscribeMessageRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubscribeMessageRequest} SubscribeMessageRequest
     */
    SubscribeMessageRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscribeMessageRequest)
            return object;
        return new $root.SubscribeMessageRequest();
    };

    /**
     * Creates a plain object from a SubscribeMessageRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubscribeMessageRequest
     * @static
     * @param {SubscribeMessageRequest} message SubscribeMessageRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubscribeMessageRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this SubscribeMessageRequest to JSON.
     * @function toJSON
     * @memberof SubscribeMessageRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubscribeMessageRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubscribeMessageRequest
     * @function getTypeUrl
     * @memberof SubscribeMessageRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubscribeMessageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscribeMessageRequest";
    };

    return SubscribeMessageRequest;
})();

$root.UnsubscribeMessageRequest = (function() {

    /**
     * Properties of an UnsubscribeMessageRequest.
     * @exports IUnsubscribeMessageRequest
     * @interface IUnsubscribeMessageRequest
     */

    /**
     * Constructs a new UnsubscribeMessageRequest.
     * @exports UnsubscribeMessageRequest
     * @classdesc Represents an UnsubscribeMessageRequest.
     * @implements IUnsubscribeMessageRequest
     * @constructor
     * @param {IUnsubscribeMessageRequest=} [properties] Properties to set
     */
    function UnsubscribeMessageRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new UnsubscribeMessageRequest instance using the specified properties.
     * @function create
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {IUnsubscribeMessageRequest=} [properties] Properties to set
     * @returns {UnsubscribeMessageRequest} UnsubscribeMessageRequest instance
     */
    UnsubscribeMessageRequest.create = function create(properties) {
        return new UnsubscribeMessageRequest(properties);
    };

    /**
     * Encodes the specified UnsubscribeMessageRequest message. Does not implicitly {@link UnsubscribeMessageRequest.verify|verify} messages.
     * @function encode
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {IUnsubscribeMessageRequest} message UnsubscribeMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnsubscribeMessageRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified UnsubscribeMessageRequest message, length delimited. Does not implicitly {@link UnsubscribeMessageRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {IUnsubscribeMessageRequest} message UnsubscribeMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnsubscribeMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UnsubscribeMessageRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UnsubscribeMessageRequest} UnsubscribeMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnsubscribeMessageRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UnsubscribeMessageRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UnsubscribeMessageRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UnsubscribeMessageRequest} UnsubscribeMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnsubscribeMessageRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UnsubscribeMessageRequest message.
     * @function verify
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UnsubscribeMessageRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates an UnsubscribeMessageRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UnsubscribeMessageRequest} UnsubscribeMessageRequest
     */
    UnsubscribeMessageRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UnsubscribeMessageRequest)
            return object;
        return new $root.UnsubscribeMessageRequest();
    };

    /**
     * Creates a plain object from an UnsubscribeMessageRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {UnsubscribeMessageRequest} message UnsubscribeMessageRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UnsubscribeMessageRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this UnsubscribeMessageRequest to JSON.
     * @function toJSON
     * @memberof UnsubscribeMessageRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UnsubscribeMessageRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UnsubscribeMessageRequest
     * @function getTypeUrl
     * @memberof UnsubscribeMessageRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UnsubscribeMessageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UnsubscribeMessageRequest";
    };

    return UnsubscribeMessageRequest;
})();

$root.SubscribeMessageRespond = (function() {

    /**
     * Properties of a SubscribeMessageRespond.
     * @exports ISubscribeMessageRespond
     * @interface ISubscribeMessageRespond
     * @property {number|Long|null} [messageId] SubscribeMessageRespond messageId
     * @property {string|null} [who] SubscribeMessageRespond who
     * @property {string|null} [message] SubscribeMessageRespond message
     */

    /**
     * Constructs a new SubscribeMessageRespond.
     * @exports SubscribeMessageRespond
     * @classdesc Represents a SubscribeMessageRespond.
     * @implements ISubscribeMessageRespond
     * @constructor
     * @param {ISubscribeMessageRespond=} [properties] Properties to set
     */
    function SubscribeMessageRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubscribeMessageRespond messageId.
     * @member {number|Long} messageId
     * @memberof SubscribeMessageRespond
     * @instance
     */
    SubscribeMessageRespond.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * SubscribeMessageRespond who.
     * @member {string} who
     * @memberof SubscribeMessageRespond
     * @instance
     */
    SubscribeMessageRespond.prototype.who = "";

    /**
     * SubscribeMessageRespond message.
     * @member {string} message
     * @memberof SubscribeMessageRespond
     * @instance
     */
    SubscribeMessageRespond.prototype.message = "";

    /**
     * Creates a new SubscribeMessageRespond instance using the specified properties.
     * @function create
     * @memberof SubscribeMessageRespond
     * @static
     * @param {ISubscribeMessageRespond=} [properties] Properties to set
     * @returns {SubscribeMessageRespond} SubscribeMessageRespond instance
     */
    SubscribeMessageRespond.create = function create(properties) {
        return new SubscribeMessageRespond(properties);
    };

    /**
     * Encodes the specified SubscribeMessageRespond message. Does not implicitly {@link SubscribeMessageRespond.verify|verify} messages.
     * @function encode
     * @memberof SubscribeMessageRespond
     * @static
     * @param {ISubscribeMessageRespond} message SubscribeMessageRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeMessageRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.messageId);
        if (message.who != null && Object.hasOwnProperty.call(message, "who"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.who);
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified SubscribeMessageRespond message, length delimited. Does not implicitly {@link SubscribeMessageRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubscribeMessageRespond
     * @static
     * @param {ISubscribeMessageRespond} message SubscribeMessageRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeMessageRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubscribeMessageRespond message from the specified reader or buffer.
     * @function decode
     * @memberof SubscribeMessageRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubscribeMessageRespond} SubscribeMessageRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeMessageRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscribeMessageRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.messageId = reader.int64();
                    break;
                }
            case 2: {
                    message.who = reader.string();
                    break;
                }
            case 3: {
                    message.message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubscribeMessageRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubscribeMessageRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubscribeMessageRespond} SubscribeMessageRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeMessageRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubscribeMessageRespond message.
     * @function verify
     * @memberof SubscribeMessageRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubscribeMessageRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                return "messageId: integer|Long expected";
        if (message.who != null && message.hasOwnProperty("who"))
            if (!$util.isString(message.who))
                return "who: string expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        return null;
    };

    /**
     * Creates a SubscribeMessageRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubscribeMessageRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubscribeMessageRespond} SubscribeMessageRespond
     */
    SubscribeMessageRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscribeMessageRespond)
            return object;
        var message = new $root.SubscribeMessageRespond();
        if (object.messageId != null)
            if ($util.Long)
                (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
            else if (typeof object.messageId === "string")
                message.messageId = parseInt(object.messageId, 10);
            else if (typeof object.messageId === "number")
                message.messageId = object.messageId;
            else if (typeof object.messageId === "object")
                message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
        if (object.who != null)
            message.who = String(object.who);
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from a SubscribeMessageRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubscribeMessageRespond
     * @static
     * @param {SubscribeMessageRespond} message SubscribeMessageRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubscribeMessageRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.messageId = options.longs === String ? "0" : 0;
            object.who = "";
            object.message = "";
        }
        if (message.messageId != null && message.hasOwnProperty("messageId"))
            if (typeof message.messageId === "number")
                object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
            else
                object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
        if (message.who != null && message.hasOwnProperty("who"))
            object.who = message.who;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this SubscribeMessageRespond to JSON.
     * @function toJSON
     * @memberof SubscribeMessageRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubscribeMessageRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubscribeMessageRespond
     * @function getTypeUrl
     * @memberof SubscribeMessageRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubscribeMessageRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscribeMessageRespond";
    };

    return SubscribeMessageRespond;
})();

$root.RemoveMessageRequest = (function() {

    /**
     * Properties of a RemoveMessageRequest.
     * @exports IRemoveMessageRequest
     * @interface IRemoveMessageRequest
     * @property {number|Long|null} [id] RemoveMessageRequest id
     */

    /**
     * Constructs a new RemoveMessageRequest.
     * @exports RemoveMessageRequest
     * @classdesc Represents a RemoveMessageRequest.
     * @implements IRemoveMessageRequest
     * @constructor
     * @param {IRemoveMessageRequest=} [properties] Properties to set
     */
    function RemoveMessageRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoveMessageRequest id.
     * @member {number|Long} id
     * @memberof RemoveMessageRequest
     * @instance
     */
    RemoveMessageRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new RemoveMessageRequest instance using the specified properties.
     * @function create
     * @memberof RemoveMessageRequest
     * @static
     * @param {IRemoveMessageRequest=} [properties] Properties to set
     * @returns {RemoveMessageRequest} RemoveMessageRequest instance
     */
    RemoveMessageRequest.create = function create(properties) {
        return new RemoveMessageRequest(properties);
    };

    /**
     * Encodes the specified RemoveMessageRequest message. Does not implicitly {@link RemoveMessageRequest.verify|verify} messages.
     * @function encode
     * @memberof RemoveMessageRequest
     * @static
     * @param {IRemoveMessageRequest} message RemoveMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveMessageRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
        return writer;
    };

    /**
     * Encodes the specified RemoveMessageRequest message, length delimited. Does not implicitly {@link RemoveMessageRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoveMessageRequest
     * @static
     * @param {IRemoveMessageRequest} message RemoveMessageRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoveMessageRequest message from the specified reader or buffer.
     * @function decode
     * @memberof RemoveMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoveMessageRequest} RemoveMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveMessageRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoveMessageRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoveMessageRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoveMessageRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoveMessageRequest} RemoveMessageRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveMessageRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoveMessageRequest message.
     * @function verify
     * @memberof RemoveMessageRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoveMessageRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                return "id: integer|Long expected";
        return null;
    };

    /**
     * Creates a RemoveMessageRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoveMessageRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoveMessageRequest} RemoveMessageRequest
     */
    RemoveMessageRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoveMessageRequest)
            return object;
        var message = new $root.RemoveMessageRequest();
        if (object.id != null)
            if ($util.Long)
                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
            else if (typeof object.id === "string")
                message.id = parseInt(object.id, 10);
            else if (typeof object.id === "number")
                message.id = object.id;
            else if (typeof object.id === "object")
                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a RemoveMessageRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoveMessageRequest
     * @static
     * @param {RemoveMessageRequest} message RemoveMessageRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoveMessageRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.id = options.longs === String ? "0" : 0;
        if (message.id != null && message.hasOwnProperty("id"))
            if (typeof message.id === "number")
                object.id = options.longs === String ? String(message.id) : message.id;
            else
                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
        return object;
    };

    /**
     * Converts this RemoveMessageRequest to JSON.
     * @function toJSON
     * @memberof RemoveMessageRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoveMessageRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoveMessageRequest
     * @function getTypeUrl
     * @memberof RemoveMessageRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoveMessageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoveMessageRequest";
    };

    return RemoveMessageRequest;
})();

$root.UseLatestVerifyDataRequest = (function() {

    /**
     * Properties of a UseLatestVerifyDataRequest.
     * @exports IUseLatestVerifyDataRequest
     * @interface IUseLatestVerifyDataRequest
     */

    /**
     * Constructs a new UseLatestVerifyDataRequest.
     * @exports UseLatestVerifyDataRequest
     * @classdesc Represents a UseLatestVerifyDataRequest.
     * @implements IUseLatestVerifyDataRequest
     * @constructor
     * @param {IUseLatestVerifyDataRequest=} [properties] Properties to set
     */
    function UseLatestVerifyDataRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new UseLatestVerifyDataRequest instance using the specified properties.
     * @function create
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {IUseLatestVerifyDataRequest=} [properties] Properties to set
     * @returns {UseLatestVerifyDataRequest} UseLatestVerifyDataRequest instance
     */
    UseLatestVerifyDataRequest.create = function create(properties) {
        return new UseLatestVerifyDataRequest(properties);
    };

    /**
     * Encodes the specified UseLatestVerifyDataRequest message. Does not implicitly {@link UseLatestVerifyDataRequest.verify|verify} messages.
     * @function encode
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {IUseLatestVerifyDataRequest} message UseLatestVerifyDataRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UseLatestVerifyDataRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified UseLatestVerifyDataRequest message, length delimited. Does not implicitly {@link UseLatestVerifyDataRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {IUseLatestVerifyDataRequest} message UseLatestVerifyDataRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UseLatestVerifyDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UseLatestVerifyDataRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UseLatestVerifyDataRequest} UseLatestVerifyDataRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UseLatestVerifyDataRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UseLatestVerifyDataRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a UseLatestVerifyDataRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UseLatestVerifyDataRequest} UseLatestVerifyDataRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UseLatestVerifyDataRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UseLatestVerifyDataRequest message.
     * @function verify
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UseLatestVerifyDataRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a UseLatestVerifyDataRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UseLatestVerifyDataRequest} UseLatestVerifyDataRequest
     */
    UseLatestVerifyDataRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UseLatestVerifyDataRequest)
            return object;
        return new $root.UseLatestVerifyDataRequest();
    };

    /**
     * Creates a plain object from a UseLatestVerifyDataRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {UseLatestVerifyDataRequest} message UseLatestVerifyDataRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UseLatestVerifyDataRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this UseLatestVerifyDataRequest to JSON.
     * @function toJSON
     * @memberof UseLatestVerifyDataRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UseLatestVerifyDataRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UseLatestVerifyDataRequest
     * @function getTypeUrl
     * @memberof UseLatestVerifyDataRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UseLatestVerifyDataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UseLatestVerifyDataRequest";
    };

    return UseLatestVerifyDataRequest;
})();

$root.LogoutRequest = (function() {

    /**
     * Properties of a LogoutRequest.
     * @exports ILogoutRequest
     * @interface ILogoutRequest
     * @property {number|Long|null} [workerId] LogoutRequest workerId
     */

    /**
     * Constructs a new LogoutRequest.
     * @exports LogoutRequest
     * @classdesc Represents a LogoutRequest.
     * @implements ILogoutRequest
     * @constructor
     * @param {ILogoutRequest=} [properties] Properties to set
     */
    function LogoutRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LogoutRequest workerId.
     * @member {number|Long} workerId
     * @memberof LogoutRequest
     * @instance
     */
    LogoutRequest.prototype.workerId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new LogoutRequest instance using the specified properties.
     * @function create
     * @memberof LogoutRequest
     * @static
     * @param {ILogoutRequest=} [properties] Properties to set
     * @returns {LogoutRequest} LogoutRequest instance
     */
    LogoutRequest.create = function create(properties) {
        return new LogoutRequest(properties);
    };

    /**
     * Encodes the specified LogoutRequest message. Does not implicitly {@link LogoutRequest.verify|verify} messages.
     * @function encode
     * @memberof LogoutRequest
     * @static
     * @param {ILogoutRequest} message LogoutRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.workerId);
        return writer;
    };

    /**
     * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link LogoutRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof LogoutRequest
     * @static
     * @param {ILogoutRequest} message LogoutRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LogoutRequest message from the specified reader or buffer.
     * @function decode
     * @memberof LogoutRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LogoutRequest} LogoutRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LogoutRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.workerId = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof LogoutRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {LogoutRequest} LogoutRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LogoutRequest message.
     * @function verify
     * @memberof LogoutRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LogoutRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (!$util.isInteger(message.workerId) && !(message.workerId && $util.isInteger(message.workerId.low) && $util.isInteger(message.workerId.high)))
                return "workerId: integer|Long expected";
        return null;
    };

    /**
     * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof LogoutRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {LogoutRequest} LogoutRequest
     */
    LogoutRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.LogoutRequest)
            return object;
        var message = new $root.LogoutRequest();
        if (object.workerId != null)
            if ($util.Long)
                (message.workerId = $util.Long.fromValue(object.workerId)).unsigned = false;
            else if (typeof object.workerId === "string")
                message.workerId = parseInt(object.workerId, 10);
            else if (typeof object.workerId === "number")
                message.workerId = object.workerId;
            else if (typeof object.workerId === "object")
                message.workerId = new $util.LongBits(object.workerId.low >>> 0, object.workerId.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof LogoutRequest
     * @static
     * @param {LogoutRequest} message LogoutRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LogoutRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.workerId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.workerId = options.longs === String ? "0" : 0;
        if (message.workerId != null && message.hasOwnProperty("workerId"))
            if (typeof message.workerId === "number")
                object.workerId = options.longs === String ? String(message.workerId) : message.workerId;
            else
                object.workerId = options.longs === String ? $util.Long.prototype.toString.call(message.workerId) : options.longs === Number ? new $util.LongBits(message.workerId.low >>> 0, message.workerId.high >>> 0).toNumber() : message.workerId;
        return object;
    };

    /**
     * Converts this LogoutRequest to JSON.
     * @function toJSON
     * @memberof LogoutRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LogoutRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LogoutRequest
     * @function getTypeUrl
     * @memberof LogoutRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/LogoutRequest";
    };

    return LogoutRequest;
})();

$root.RemoteCallRequest = (function() {

    /**
     * Properties of a RemoteCallRequest.
     * @exports IRemoteCallRequest
     * @interface IRemoteCallRequest
     * @property {number|Long|null} [methodId] RemoteCallRequest methodId
     * @property {string|null} [methodName] RemoteCallRequest methodName
     * @property {Uint8Array|null} [data] RemoteCallRequest data
     */

    /**
     * Constructs a new RemoteCallRequest.
     * @exports RemoteCallRequest
     * @classdesc Represents a RemoteCallRequest.
     * @implements IRemoteCallRequest
     * @constructor
     * @param {IRemoteCallRequest=} [properties] Properties to set
     */
    function RemoteCallRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoteCallRequest methodId.
     * @member {number|Long} methodId
     * @memberof RemoteCallRequest
     * @instance
     */
    RemoteCallRequest.prototype.methodId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * RemoteCallRequest methodName.
     * @member {string} methodName
     * @memberof RemoteCallRequest
     * @instance
     */
    RemoteCallRequest.prototype.methodName = "";

    /**
     * RemoteCallRequest data.
     * @member {Uint8Array} data
     * @memberof RemoteCallRequest
     * @instance
     */
    RemoteCallRequest.prototype.data = $util.newBuffer([]);

    /**
     * Creates a new RemoteCallRequest instance using the specified properties.
     * @function create
     * @memberof RemoteCallRequest
     * @static
     * @param {IRemoteCallRequest=} [properties] Properties to set
     * @returns {RemoteCallRequest} RemoteCallRequest instance
     */
    RemoteCallRequest.create = function create(properties) {
        return new RemoteCallRequest(properties);
    };

    /**
     * Encodes the specified RemoteCallRequest message. Does not implicitly {@link RemoteCallRequest.verify|verify} messages.
     * @function encode
     * @memberof RemoteCallRequest
     * @static
     * @param {IRemoteCallRequest} message RemoteCallRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoteCallRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.methodId != null && Object.hasOwnProperty.call(message, "methodId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.methodId);
        if (message.methodName != null && Object.hasOwnProperty.call(message, "methodName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.methodName);
        if (message.data != null && Object.hasOwnProperty.call(message, "data"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
        return writer;
    };

    /**
     * Encodes the specified RemoteCallRequest message, length delimited. Does not implicitly {@link RemoteCallRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoteCallRequest
     * @static
     * @param {IRemoteCallRequest} message RemoteCallRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoteCallRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoteCallRequest message from the specified reader or buffer.
     * @function decode
     * @memberof RemoteCallRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoteCallRequest} RemoteCallRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoteCallRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoteCallRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.methodId = reader.int64();
                    break;
                }
            case 2: {
                    message.methodName = reader.string();
                    break;
                }
            case 3: {
                    message.data = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoteCallRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoteCallRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoteCallRequest} RemoteCallRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoteCallRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoteCallRequest message.
     * @function verify
     * @memberof RemoteCallRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoteCallRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.methodId != null && message.hasOwnProperty("methodId"))
            if (!$util.isInteger(message.methodId) && !(message.methodId && $util.isInteger(message.methodId.low) && $util.isInteger(message.methodId.high)))
                return "methodId: integer|Long expected";
        if (message.methodName != null && message.hasOwnProperty("methodName"))
            if (!$util.isString(message.methodName))
                return "methodName: string expected";
        if (message.data != null && message.hasOwnProperty("data"))
            if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                return "data: buffer expected";
        return null;
    };

    /**
     * Creates a RemoteCallRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoteCallRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoteCallRequest} RemoteCallRequest
     */
    RemoteCallRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoteCallRequest)
            return object;
        var message = new $root.RemoteCallRequest();
        if (object.methodId != null)
            if ($util.Long)
                (message.methodId = $util.Long.fromValue(object.methodId)).unsigned = false;
            else if (typeof object.methodId === "string")
                message.methodId = parseInt(object.methodId, 10);
            else if (typeof object.methodId === "number")
                message.methodId = object.methodId;
            else if (typeof object.methodId === "object")
                message.methodId = new $util.LongBits(object.methodId.low >>> 0, object.methodId.high >>> 0).toNumber();
        if (object.methodName != null)
            message.methodName = String(object.methodName);
        if (object.data != null)
            if (typeof object.data === "string")
                $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
            else if (object.data.length >= 0)
                message.data = object.data;
        return message;
    };

    /**
     * Creates a plain object from a RemoteCallRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoteCallRequest
     * @static
     * @param {RemoteCallRequest} message RemoteCallRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoteCallRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.methodId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.methodId = options.longs === String ? "0" : 0;
            object.methodName = "";
            if (options.bytes === String)
                object.data = "";
            else {
                object.data = [];
                if (options.bytes !== Array)
                    object.data = $util.newBuffer(object.data);
            }
        }
        if (message.methodId != null && message.hasOwnProperty("methodId"))
            if (typeof message.methodId === "number")
                object.methodId = options.longs === String ? String(message.methodId) : message.methodId;
            else
                object.methodId = options.longs === String ? $util.Long.prototype.toString.call(message.methodId) : options.longs === Number ? new $util.LongBits(message.methodId.low >>> 0, message.methodId.high >>> 0).toNumber() : message.methodId;
        if (message.methodName != null && message.hasOwnProperty("methodName"))
            object.methodName = message.methodName;
        if (message.data != null && message.hasOwnProperty("data"))
            object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
        return object;
    };

    /**
     * Converts this RemoteCallRequest to JSON.
     * @function toJSON
     * @memberof RemoteCallRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoteCallRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoteCallRequest
     * @function getTypeUrl
     * @memberof RemoteCallRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoteCallRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoteCallRequest";
    };

    return RemoteCallRequest;
})();

$root.RemoteCallRespond = (function() {

    /**
     * Properties of a RemoteCallRespond.
     * @exports IRemoteCallRespond
     * @interface IRemoteCallRespond
     * @property {Uint8Array|null} [result] RemoteCallRespond result
     */

    /**
     * Constructs a new RemoteCallRespond.
     * @exports RemoteCallRespond
     * @classdesc Represents a RemoteCallRespond.
     * @implements IRemoteCallRespond
     * @constructor
     * @param {IRemoteCallRespond=} [properties] Properties to set
     */
    function RemoteCallRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoteCallRespond result.
     * @member {Uint8Array} result
     * @memberof RemoteCallRespond
     * @instance
     */
    RemoteCallRespond.prototype.result = $util.newBuffer([]);

    /**
     * Creates a new RemoteCallRespond instance using the specified properties.
     * @function create
     * @memberof RemoteCallRespond
     * @static
     * @param {IRemoteCallRespond=} [properties] Properties to set
     * @returns {RemoteCallRespond} RemoteCallRespond instance
     */
    RemoteCallRespond.create = function create(properties) {
        return new RemoteCallRespond(properties);
    };

    /**
     * Encodes the specified RemoteCallRespond message. Does not implicitly {@link RemoteCallRespond.verify|verify} messages.
     * @function encode
     * @memberof RemoteCallRespond
     * @static
     * @param {IRemoteCallRespond} message RemoteCallRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoteCallRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.result);
        return writer;
    };

    /**
     * Encodes the specified RemoteCallRespond message, length delimited. Does not implicitly {@link RemoteCallRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoteCallRespond
     * @static
     * @param {IRemoteCallRespond} message RemoteCallRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoteCallRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoteCallRespond message from the specified reader or buffer.
     * @function decode
     * @memberof RemoteCallRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoteCallRespond} RemoteCallRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoteCallRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoteCallRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoteCallRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoteCallRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoteCallRespond} RemoteCallRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoteCallRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoteCallRespond message.
     * @function verify
     * @memberof RemoteCallRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoteCallRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (!(message.result && typeof message.result.length === "number" || $util.isString(message.result)))
                return "result: buffer expected";
        return null;
    };

    /**
     * Creates a RemoteCallRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoteCallRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoteCallRespond} RemoteCallRespond
     */
    RemoteCallRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoteCallRespond)
            return object;
        var message = new $root.RemoteCallRespond();
        if (object.result != null)
            if (typeof object.result === "string")
                $util.base64.decode(object.result, message.result = $util.newBuffer($util.base64.length(object.result)), 0);
            else if (object.result.length >= 0)
                message.result = object.result;
        return message;
    };

    /**
     * Creates a plain object from a RemoteCallRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoteCallRespond
     * @static
     * @param {RemoteCallRespond} message RemoteCallRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoteCallRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            if (options.bytes === String)
                object.result = "";
            else {
                object.result = [];
                if (options.bytes !== Array)
                    object.result = $util.newBuffer(object.result);
            }
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = options.bytes === String ? $util.base64.encode(message.result, 0, message.result.length) : options.bytes === Array ? Array.prototype.slice.call(message.result) : message.result;
        return object;
    };

    /**
     * Converts this RemoteCallRespond to JSON.
     * @function toJSON
     * @memberof RemoteCallRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoteCallRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoteCallRespond
     * @function getTypeUrl
     * @memberof RemoteCallRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoteCallRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoteCallRespond";
    };

    return RemoteCallRespond;
})();

$root.ObserveWorkerRequest = (function() {

    /**
     * Properties of an ObserveWorkerRequest.
     * @exports IObserveWorkerRequest
     * @interface IObserveWorkerRequest
     * @property {string|null} [userId] ObserveWorkerRequest userId
     */

    /**
     * Constructs a new ObserveWorkerRequest.
     * @exports ObserveWorkerRequest
     * @classdesc Represents an ObserveWorkerRequest.
     * @implements IObserveWorkerRequest
     * @constructor
     * @param {IObserveWorkerRequest=} [properties] Properties to set
     */
    function ObserveWorkerRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ObserveWorkerRequest userId.
     * @member {string} userId
     * @memberof ObserveWorkerRequest
     * @instance
     */
    ObserveWorkerRequest.prototype.userId = "";

    /**
     * Creates a new ObserveWorkerRequest instance using the specified properties.
     * @function create
     * @memberof ObserveWorkerRequest
     * @static
     * @param {IObserveWorkerRequest=} [properties] Properties to set
     * @returns {ObserveWorkerRequest} ObserveWorkerRequest instance
     */
    ObserveWorkerRequest.create = function create(properties) {
        return new ObserveWorkerRequest(properties);
    };

    /**
     * Encodes the specified ObserveWorkerRequest message. Does not implicitly {@link ObserveWorkerRequest.verify|verify} messages.
     * @function encode
     * @memberof ObserveWorkerRequest
     * @static
     * @param {IObserveWorkerRequest} message ObserveWorkerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ObserveWorkerRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
        return writer;
    };

    /**
     * Encodes the specified ObserveWorkerRequest message, length delimited. Does not implicitly {@link ObserveWorkerRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ObserveWorkerRequest
     * @static
     * @param {IObserveWorkerRequest} message ObserveWorkerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ObserveWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ObserveWorkerRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ObserveWorkerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ObserveWorkerRequest} ObserveWorkerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ObserveWorkerRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObserveWorkerRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.userId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ObserveWorkerRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ObserveWorkerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ObserveWorkerRequest} ObserveWorkerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ObserveWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ObserveWorkerRequest message.
     * @function verify
     * @memberof ObserveWorkerRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ObserveWorkerRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.userId != null && message.hasOwnProperty("userId"))
            if (!$util.isString(message.userId))
                return "userId: string expected";
        return null;
    };

    /**
     * Creates an ObserveWorkerRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ObserveWorkerRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ObserveWorkerRequest} ObserveWorkerRequest
     */
    ObserveWorkerRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ObserveWorkerRequest)
            return object;
        var message = new $root.ObserveWorkerRequest();
        if (object.userId != null)
            message.userId = String(object.userId);
        return message;
    };

    /**
     * Creates a plain object from an ObserveWorkerRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ObserveWorkerRequest
     * @static
     * @param {ObserveWorkerRequest} message ObserveWorkerRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ObserveWorkerRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.userId = "";
        if (message.userId != null && message.hasOwnProperty("userId"))
            object.userId = message.userId;
        return object;
    };

    /**
     * Converts this ObserveWorkerRequest to JSON.
     * @function toJSON
     * @memberof ObserveWorkerRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ObserveWorkerRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ObserveWorkerRequest
     * @function getTypeUrl
     * @memberof ObserveWorkerRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ObserveWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObserveWorkerRequest";
    };

    return ObserveWorkerRequest;
})();

$root.ObserveWorkerRespond = (function() {

    /**
     * Properties of an ObserveWorkerRespond.
     * @exports IObserveWorkerRespond
     * @interface IObserveWorkerRespond
     * @property {Array.<IWorkerInfo>|null} [workerInfos] ObserveWorkerRespond workerInfos
     */

    /**
     * Constructs a new ObserveWorkerRespond.
     * @exports ObserveWorkerRespond
     * @classdesc Represents an ObserveWorkerRespond.
     * @implements IObserveWorkerRespond
     * @constructor
     * @param {IObserveWorkerRespond=} [properties] Properties to set
     */
    function ObserveWorkerRespond(properties) {
        this.workerInfos = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ObserveWorkerRespond workerInfos.
     * @member {Array.<IWorkerInfo>} workerInfos
     * @memberof ObserveWorkerRespond
     * @instance
     */
    ObserveWorkerRespond.prototype.workerInfos = $util.emptyArray;

    /**
     * Creates a new ObserveWorkerRespond instance using the specified properties.
     * @function create
     * @memberof ObserveWorkerRespond
     * @static
     * @param {IObserveWorkerRespond=} [properties] Properties to set
     * @returns {ObserveWorkerRespond} ObserveWorkerRespond instance
     */
    ObserveWorkerRespond.create = function create(properties) {
        return new ObserveWorkerRespond(properties);
    };

    /**
     * Encodes the specified ObserveWorkerRespond message. Does not implicitly {@link ObserveWorkerRespond.verify|verify} messages.
     * @function encode
     * @memberof ObserveWorkerRespond
     * @static
     * @param {IObserveWorkerRespond} message ObserveWorkerRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ObserveWorkerRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerInfos != null && message.workerInfos.length)
            for (var i = 0; i < message.workerInfos.length; ++i)
                $root.WorkerInfo.encode(message.workerInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ObserveWorkerRespond message, length delimited. Does not implicitly {@link ObserveWorkerRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ObserveWorkerRespond
     * @static
     * @param {IObserveWorkerRespond} message ObserveWorkerRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ObserveWorkerRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ObserveWorkerRespond message from the specified reader or buffer.
     * @function decode
     * @memberof ObserveWorkerRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ObserveWorkerRespond} ObserveWorkerRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ObserveWorkerRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ObserveWorkerRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.workerInfos && message.workerInfos.length))
                        message.workerInfos = [];
                    message.workerInfos.push($root.WorkerInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ObserveWorkerRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ObserveWorkerRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ObserveWorkerRespond} ObserveWorkerRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ObserveWorkerRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ObserveWorkerRespond message.
     * @function verify
     * @memberof ObserveWorkerRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ObserveWorkerRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerInfos != null && message.hasOwnProperty("workerInfos")) {
            if (!Array.isArray(message.workerInfos))
                return "workerInfos: array expected";
            for (var i = 0; i < message.workerInfos.length; ++i) {
                var error = $root.WorkerInfo.verify(message.workerInfos[i]);
                if (error)
                    return "workerInfos." + error;
            }
        }
        return null;
    };

    /**
     * Creates an ObserveWorkerRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ObserveWorkerRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ObserveWorkerRespond} ObserveWorkerRespond
     */
    ObserveWorkerRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.ObserveWorkerRespond)
            return object;
        var message = new $root.ObserveWorkerRespond();
        if (object.workerInfos) {
            if (!Array.isArray(object.workerInfos))
                throw TypeError(".ObserveWorkerRespond.workerInfos: array expected");
            message.workerInfos = [];
            for (var i = 0; i < object.workerInfos.length; ++i) {
                if (typeof object.workerInfos[i] !== "object")
                    throw TypeError(".ObserveWorkerRespond.workerInfos: object expected");
                message.workerInfos[i] = $root.WorkerInfo.fromObject(object.workerInfos[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an ObserveWorkerRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ObserveWorkerRespond
     * @static
     * @param {ObserveWorkerRespond} message ObserveWorkerRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ObserveWorkerRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.workerInfos = [];
        if (message.workerInfos && message.workerInfos.length) {
            object.workerInfos = [];
            for (var j = 0; j < message.workerInfos.length; ++j)
                object.workerInfos[j] = $root.WorkerInfo.toObject(message.workerInfos[j], options);
        }
        return object;
    };

    /**
     * Converts this ObserveWorkerRespond to JSON.
     * @function toJSON
     * @memberof ObserveWorkerRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ObserveWorkerRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ObserveWorkerRespond
     * @function getTypeUrl
     * @memberof ObserveWorkerRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ObserveWorkerRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ObserveWorkerRespond";
    };

    return ObserveWorkerRespond;
})();

$root.StartTaskRequest = (function() {

    /**
     * Properties of a StartTaskRequest.
     * @exports IStartTaskRequest
     * @interface IStartTaskRequest
     * @property {Array.<number|Long>|null} [workers] StartTaskRequest workers
     * @property {string|null} [taskName] StartTaskRequest taskName
     */

    /**
     * Constructs a new StartTaskRequest.
     * @exports StartTaskRequest
     * @classdesc Represents a StartTaskRequest.
     * @implements IStartTaskRequest
     * @constructor
     * @param {IStartTaskRequest=} [properties] Properties to set
     */
    function StartTaskRequest(properties) {
        this.workers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StartTaskRequest workers.
     * @member {Array.<number|Long>} workers
     * @memberof StartTaskRequest
     * @instance
     */
    StartTaskRequest.prototype.workers = $util.emptyArray;

    /**
     * StartTaskRequest taskName.
     * @member {string} taskName
     * @memberof StartTaskRequest
     * @instance
     */
    StartTaskRequest.prototype.taskName = "";

    /**
     * Creates a new StartTaskRequest instance using the specified properties.
     * @function create
     * @memberof StartTaskRequest
     * @static
     * @param {IStartTaskRequest=} [properties] Properties to set
     * @returns {StartTaskRequest} StartTaskRequest instance
     */
    StartTaskRequest.create = function create(properties) {
        return new StartTaskRequest(properties);
    };

    /**
     * Encodes the specified StartTaskRequest message. Does not implicitly {@link StartTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof StartTaskRequest
     * @static
     * @param {IStartTaskRequest} message StartTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workers != null && message.workers.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.workers.length; ++i)
                writer.int64(message.workers[i]);
            writer.ldelim();
        }
        if (message.taskName != null && Object.hasOwnProperty.call(message, "taskName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskName);
        return writer;
    };

    /**
     * Encodes the specified StartTaskRequest message, length delimited. Does not implicitly {@link StartTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof StartTaskRequest
     * @static
     * @param {IStartTaskRequest} message StartTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StartTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof StartTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StartTaskRequest} StartTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.StartTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.workers && message.workers.length))
                        message.workers = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.workers.push(reader.int64());
                    } else
                        message.workers.push(reader.int64());
                    break;
                }
            case 2: {
                    message.taskName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a StartTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof StartTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {StartTaskRequest} StartTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StartTaskRequest message.
     * @function verify
     * @memberof StartTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StartTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workers != null && message.hasOwnProperty("workers")) {
            if (!Array.isArray(message.workers))
                return "workers: array expected";
            for (var i = 0; i < message.workers.length; ++i)
                if (!$util.isInteger(message.workers[i]) && !(message.workers[i] && $util.isInteger(message.workers[i].low) && $util.isInteger(message.workers[i].high)))
                    return "workers: integer|Long[] expected";
        }
        if (message.taskName != null && message.hasOwnProperty("taskName"))
            if (!$util.isString(message.taskName))
                return "taskName: string expected";
        return null;
    };

    /**
     * Creates a StartTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StartTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {StartTaskRequest} StartTaskRequest
     */
    StartTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.StartTaskRequest)
            return object;
        var message = new $root.StartTaskRequest();
        if (object.workers) {
            if (!Array.isArray(object.workers))
                throw TypeError(".StartTaskRequest.workers: array expected");
            message.workers = [];
            for (var i = 0; i < object.workers.length; ++i)
                if ($util.Long)
                    (message.workers[i] = $util.Long.fromValue(object.workers[i])).unsigned = false;
                else if (typeof object.workers[i] === "string")
                    message.workers[i] = parseInt(object.workers[i], 10);
                else if (typeof object.workers[i] === "number")
                    message.workers[i] = object.workers[i];
                else if (typeof object.workers[i] === "object")
                    message.workers[i] = new $util.LongBits(object.workers[i].low >>> 0, object.workers[i].high >>> 0).toNumber();
        }
        if (object.taskName != null)
            message.taskName = String(object.taskName);
        return message;
    };

    /**
     * Creates a plain object from a StartTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StartTaskRequest
     * @static
     * @param {StartTaskRequest} message StartTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StartTaskRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.workers = [];
        if (options.defaults)
            object.taskName = "";
        if (message.workers && message.workers.length) {
            object.workers = [];
            for (var j = 0; j < message.workers.length; ++j)
                if (typeof message.workers[j] === "number")
                    object.workers[j] = options.longs === String ? String(message.workers[j]) : message.workers[j];
                else
                    object.workers[j] = options.longs === String ? $util.Long.prototype.toString.call(message.workers[j]) : options.longs === Number ? new $util.LongBits(message.workers[j].low >>> 0, message.workers[j].high >>> 0).toNumber() : message.workers[j];
        }
        if (message.taskName != null && message.hasOwnProperty("taskName"))
            object.taskName = message.taskName;
        return object;
    };

    /**
     * Converts this StartTaskRequest to JSON.
     * @function toJSON
     * @memberof StartTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StartTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StartTaskRequest
     * @function getTypeUrl
     * @memberof StartTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StartTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StartTaskRequest";
    };

    return StartTaskRequest;
})();

$root.StartTaskRespond = (function() {

    /**
     * Properties of a StartTaskRespond.
     * @exports IStartTaskRespond
     * @interface IStartTaskRespond
     * @property {Array.<number|Long>|null} [workers] StartTaskRespond workers
     */

    /**
     * Constructs a new StartTaskRespond.
     * @exports StartTaskRespond
     * @classdesc Represents a StartTaskRespond.
     * @implements IStartTaskRespond
     * @constructor
     * @param {IStartTaskRespond=} [properties] Properties to set
     */
    function StartTaskRespond(properties) {
        this.workers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StartTaskRespond workers.
     * @member {Array.<number|Long>} workers
     * @memberof StartTaskRespond
     * @instance
     */
    StartTaskRespond.prototype.workers = $util.emptyArray;

    /**
     * Creates a new StartTaskRespond instance using the specified properties.
     * @function create
     * @memberof StartTaskRespond
     * @static
     * @param {IStartTaskRespond=} [properties] Properties to set
     * @returns {StartTaskRespond} StartTaskRespond instance
     */
    StartTaskRespond.create = function create(properties) {
        return new StartTaskRespond(properties);
    };

    /**
     * Encodes the specified StartTaskRespond message. Does not implicitly {@link StartTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof StartTaskRespond
     * @static
     * @param {IStartTaskRespond} message StartTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workers != null && message.workers.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.workers.length; ++i)
                writer.int64(message.workers[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified StartTaskRespond message, length delimited. Does not implicitly {@link StartTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof StartTaskRespond
     * @static
     * @param {IStartTaskRespond} message StartTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StartTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof StartTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StartTaskRespond} StartTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.StartTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.workers && message.workers.length))
                        message.workers = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.workers.push(reader.int64());
                    } else
                        message.workers.push(reader.int64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a StartTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof StartTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {StartTaskRespond} StartTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StartTaskRespond message.
     * @function verify
     * @memberof StartTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StartTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workers != null && message.hasOwnProperty("workers")) {
            if (!Array.isArray(message.workers))
                return "workers: array expected";
            for (var i = 0; i < message.workers.length; ++i)
                if (!$util.isInteger(message.workers[i]) && !(message.workers[i] && $util.isInteger(message.workers[i].low) && $util.isInteger(message.workers[i].high)))
                    return "workers: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a StartTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StartTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {StartTaskRespond} StartTaskRespond
     */
    StartTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.StartTaskRespond)
            return object;
        var message = new $root.StartTaskRespond();
        if (object.workers) {
            if (!Array.isArray(object.workers))
                throw TypeError(".StartTaskRespond.workers: array expected");
            message.workers = [];
            for (var i = 0; i < object.workers.length; ++i)
                if ($util.Long)
                    (message.workers[i] = $util.Long.fromValue(object.workers[i])).unsigned = false;
                else if (typeof object.workers[i] === "string")
                    message.workers[i] = parseInt(object.workers[i], 10);
                else if (typeof object.workers[i] === "number")
                    message.workers[i] = object.workers[i];
                else if (typeof object.workers[i] === "object")
                    message.workers[i] = new $util.LongBits(object.workers[i].low >>> 0, object.workers[i].high >>> 0).toNumber();
        }
        return message;
    };

    /**
     * Creates a plain object from a StartTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StartTaskRespond
     * @static
     * @param {StartTaskRespond} message StartTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StartTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.workers = [];
        if (message.workers && message.workers.length) {
            object.workers = [];
            for (var j = 0; j < message.workers.length; ++j)
                if (typeof message.workers[j] === "number")
                    object.workers[j] = options.longs === String ? String(message.workers[j]) : message.workers[j];
                else
                    object.workers[j] = options.longs === String ? $util.Long.prototype.toString.call(message.workers[j]) : options.longs === Number ? new $util.LongBits(message.workers[j].low >>> 0, message.workers[j].high >>> 0).toNumber() : message.workers[j];
        }
        return object;
    };

    /**
     * Converts this StartTaskRespond to JSON.
     * @function toJSON
     * @memberof StartTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StartTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StartTaskRespond
     * @function getTypeUrl
     * @memberof StartTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StartTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StartTaskRespond";
    };

    return StartTaskRespond;
})();

$root.SeoTaskInfo = (function() {

    /**
     * Properties of a SeoTaskInfo.
     * @exports ISeoTaskInfo
     * @interface ISeoTaskInfo
     * @property {number|null} [id] SeoTaskInfo id
     * @property {number|null} [state] SeoTaskInfo state
     * @property {string|null} [keyword] SeoTaskInfo keyword
     * @property {number|null} [targetCount] SeoTaskInfo targetCount
     * @property {number|null} [nowCount] SeoTaskInfo nowCount
     * @property {number|null} [ranking] SeoTaskInfo ranking
     * @property {string|null} [tags] SeoTaskInfo tags
     * @property {string|null} [websiteTitle] SeoTaskInfo websiteTitle
     * @property {string|null} [websiteContent] SeoTaskInfo websiteContent
     * @property {string|null} [websiteDomainName] SeoTaskInfo websiteDomainName
     * @property {string|null} [nodeType] SeoTaskInfo nodeType
     */

    /**
     * Constructs a new SeoTaskInfo.
     * @exports SeoTaskInfo
     * @classdesc Represents a SeoTaskInfo.
     * @implements ISeoTaskInfo
     * @constructor
     * @param {ISeoTaskInfo=} [properties] Properties to set
     */
    function SeoTaskInfo(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SeoTaskInfo id.
     * @member {number} id
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.id = 0;

    /**
     * SeoTaskInfo state.
     * @member {number} state
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.state = 0;

    /**
     * SeoTaskInfo keyword.
     * @member {string} keyword
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.keyword = "";

    /**
     * SeoTaskInfo targetCount.
     * @member {number} targetCount
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.targetCount = 0;

    /**
     * SeoTaskInfo nowCount.
     * @member {number} nowCount
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.nowCount = 0;

    /**
     * SeoTaskInfo ranking.
     * @member {number} ranking
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.ranking = 0;

    /**
     * SeoTaskInfo tags.
     * @member {string} tags
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.tags = "";

    /**
     * SeoTaskInfo websiteTitle.
     * @member {string} websiteTitle
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.websiteTitle = "";

    /**
     * SeoTaskInfo websiteContent.
     * @member {string} websiteContent
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.websiteContent = "";

    /**
     * SeoTaskInfo websiteDomainName.
     * @member {string} websiteDomainName
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.websiteDomainName = "";

    /**
     * SeoTaskInfo nodeType.
     * @member {string} nodeType
     * @memberof SeoTaskInfo
     * @instance
     */
    SeoTaskInfo.prototype.nodeType = "";

    /**
     * Creates a new SeoTaskInfo instance using the specified properties.
     * @function create
     * @memberof SeoTaskInfo
     * @static
     * @param {ISeoTaskInfo=} [properties] Properties to set
     * @returns {SeoTaskInfo} SeoTaskInfo instance
     */
    SeoTaskInfo.create = function create(properties) {
        return new SeoTaskInfo(properties);
    };

    /**
     * Encodes the specified SeoTaskInfo message. Does not implicitly {@link SeoTaskInfo.verify|verify} messages.
     * @function encode
     * @memberof SeoTaskInfo
     * @static
     * @param {ISeoTaskInfo} message SeoTaskInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SeoTaskInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
        if (message.keyword != null && Object.hasOwnProperty.call(message, "keyword"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.keyword);
        if (message.targetCount != null && Object.hasOwnProperty.call(message, "targetCount"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.targetCount);
        if (message.ranking != null && Object.hasOwnProperty.call(message, "ranking"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ranking);
        if (message.tags != null && Object.hasOwnProperty.call(message, "tags"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.tags);
        if (message.websiteTitle != null && Object.hasOwnProperty.call(message, "websiteTitle"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.websiteTitle);
        if (message.websiteContent != null && Object.hasOwnProperty.call(message, "websiteContent"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.websiteContent);
        if (message.websiteDomainName != null && Object.hasOwnProperty.call(message, "websiteDomainName"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.websiteDomainName);
        if (message.nodeType != null && Object.hasOwnProperty.call(message, "nodeType"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.nodeType);
        if (message.nowCount != null && Object.hasOwnProperty.call(message, "nowCount"))
            writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.nowCount);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.state);
        return writer;
    };

    /**
     * Encodes the specified SeoTaskInfo message, length delimited. Does not implicitly {@link SeoTaskInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SeoTaskInfo
     * @static
     * @param {ISeoTaskInfo} message SeoTaskInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SeoTaskInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SeoTaskInfo message from the specified reader or buffer.
     * @function decode
     * @memberof SeoTaskInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SeoTaskInfo} SeoTaskInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SeoTaskInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SeoTaskInfo();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint32();
                    break;
                }
            case 12: {
                    message.state = reader.int32();
                    break;
                }
            case 2: {
                    message.keyword = reader.string();
                    break;
                }
            case 3: {
                    message.targetCount = reader.int32();
                    break;
                }
            case 11: {
                    message.nowCount = reader.uint32();
                    break;
                }
            case 4: {
                    message.ranking = reader.int32();
                    break;
                }
            case 5: {
                    message.tags = reader.string();
                    break;
                }
            case 6: {
                    message.websiteTitle = reader.string();
                    break;
                }
            case 8: {
                    message.websiteContent = reader.string();
                    break;
                }
            case 9: {
                    message.websiteDomainName = reader.string();
                    break;
                }
            case 10: {
                    message.nodeType = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SeoTaskInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SeoTaskInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SeoTaskInfo} SeoTaskInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SeoTaskInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SeoTaskInfo message.
     * @function verify
     * @memberof SeoTaskInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SeoTaskInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.state != null && message.hasOwnProperty("state"))
            if (!$util.isInteger(message.state))
                return "state: integer expected";
        if (message.keyword != null && message.hasOwnProperty("keyword"))
            if (!$util.isString(message.keyword))
                return "keyword: string expected";
        if (message.targetCount != null && message.hasOwnProperty("targetCount"))
            if (!$util.isInteger(message.targetCount))
                return "targetCount: integer expected";
        if (message.nowCount != null && message.hasOwnProperty("nowCount"))
            if (!$util.isInteger(message.nowCount))
                return "nowCount: integer expected";
        if (message.ranking != null && message.hasOwnProperty("ranking"))
            if (!$util.isInteger(message.ranking))
                return "ranking: integer expected";
        if (message.tags != null && message.hasOwnProperty("tags"))
            if (!$util.isString(message.tags))
                return "tags: string expected";
        if (message.websiteTitle != null && message.hasOwnProperty("websiteTitle"))
            if (!$util.isString(message.websiteTitle))
                return "websiteTitle: string expected";
        if (message.websiteContent != null && message.hasOwnProperty("websiteContent"))
            if (!$util.isString(message.websiteContent))
                return "websiteContent: string expected";
        if (message.websiteDomainName != null && message.hasOwnProperty("websiteDomainName"))
            if (!$util.isString(message.websiteDomainName))
                return "websiteDomainName: string expected";
        if (message.nodeType != null && message.hasOwnProperty("nodeType"))
            if (!$util.isString(message.nodeType))
                return "nodeType: string expected";
        return null;
    };

    /**
     * Creates a SeoTaskInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SeoTaskInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SeoTaskInfo} SeoTaskInfo
     */
    SeoTaskInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.SeoTaskInfo)
            return object;
        var message = new $root.SeoTaskInfo();
        if (object.id != null)
            message.id = object.id >>> 0;
        if (object.state != null)
            message.state = object.state | 0;
        if (object.keyword != null)
            message.keyword = String(object.keyword);
        if (object.targetCount != null)
            message.targetCount = object.targetCount | 0;
        if (object.nowCount != null)
            message.nowCount = object.nowCount >>> 0;
        if (object.ranking != null)
            message.ranking = object.ranking | 0;
        if (object.tags != null)
            message.tags = String(object.tags);
        if (object.websiteTitle != null)
            message.websiteTitle = String(object.websiteTitle);
        if (object.websiteContent != null)
            message.websiteContent = String(object.websiteContent);
        if (object.websiteDomainName != null)
            message.websiteDomainName = String(object.websiteDomainName);
        if (object.nodeType != null)
            message.nodeType = String(object.nodeType);
        return message;
    };

    /**
     * Creates a plain object from a SeoTaskInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SeoTaskInfo
     * @static
     * @param {SeoTaskInfo} message SeoTaskInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SeoTaskInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.id = 0;
            object.keyword = "";
            object.targetCount = 0;
            object.ranking = 0;
            object.tags = "";
            object.websiteTitle = "";
            object.websiteContent = "";
            object.websiteDomainName = "";
            object.nodeType = "";
            object.nowCount = 0;
            object.state = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.keyword != null && message.hasOwnProperty("keyword"))
            object.keyword = message.keyword;
        if (message.targetCount != null && message.hasOwnProperty("targetCount"))
            object.targetCount = message.targetCount;
        if (message.ranking != null && message.hasOwnProperty("ranking"))
            object.ranking = message.ranking;
        if (message.tags != null && message.hasOwnProperty("tags"))
            object.tags = message.tags;
        if (message.websiteTitle != null && message.hasOwnProperty("websiteTitle"))
            object.websiteTitle = message.websiteTitle;
        if (message.websiteContent != null && message.hasOwnProperty("websiteContent"))
            object.websiteContent = message.websiteContent;
        if (message.websiteDomainName != null && message.hasOwnProperty("websiteDomainName"))
            object.websiteDomainName = message.websiteDomainName;
        if (message.nodeType != null && message.hasOwnProperty("nodeType"))
            object.nodeType = message.nodeType;
        if (message.nowCount != null && message.hasOwnProperty("nowCount"))
            object.nowCount = message.nowCount;
        if (message.state != null && message.hasOwnProperty("state"))
            object.state = message.state;
        return object;
    };

    /**
     * Converts this SeoTaskInfo to JSON.
     * @function toJSON
     * @memberof SeoTaskInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SeoTaskInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SeoTaskInfo
     * @function getTypeUrl
     * @memberof SeoTaskInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SeoTaskInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SeoTaskInfo";
    };

    return SeoTaskInfo;
})();

$root.AddSeoTaskRequest = (function() {

    /**
     * Properties of an AddSeoTaskRequest.
     * @exports IAddSeoTaskRequest
     * @interface IAddSeoTaskRequest
     * @property {ISeoTaskInfo|null} [seoTaskInfo] AddSeoTaskRequest seoTaskInfo
     */

    /**
     * Constructs a new AddSeoTaskRequest.
     * @exports AddSeoTaskRequest
     * @classdesc Represents an AddSeoTaskRequest.
     * @implements IAddSeoTaskRequest
     * @constructor
     * @param {IAddSeoTaskRequest=} [properties] Properties to set
     */
    function AddSeoTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AddSeoTaskRequest seoTaskInfo.
     * @member {ISeoTaskInfo|null|undefined} seoTaskInfo
     * @memberof AddSeoTaskRequest
     * @instance
     */
    AddSeoTaskRequest.prototype.seoTaskInfo = null;

    /**
     * Creates a new AddSeoTaskRequest instance using the specified properties.
     * @function create
     * @memberof AddSeoTaskRequest
     * @static
     * @param {IAddSeoTaskRequest=} [properties] Properties to set
     * @returns {AddSeoTaskRequest} AddSeoTaskRequest instance
     */
    AddSeoTaskRequest.create = function create(properties) {
        return new AddSeoTaskRequest(properties);
    };

    /**
     * Encodes the specified AddSeoTaskRequest message. Does not implicitly {@link AddSeoTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof AddSeoTaskRequest
     * @static
     * @param {IAddSeoTaskRequest} message AddSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddSeoTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.seoTaskInfo != null && Object.hasOwnProperty.call(message, "seoTaskInfo"))
            $root.SeoTaskInfo.encode(message.seoTaskInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AddSeoTaskRequest message, length delimited. Does not implicitly {@link AddSeoTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AddSeoTaskRequest
     * @static
     * @param {IAddSeoTaskRequest} message AddSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddSeoTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AddSeoTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AddSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AddSeoTaskRequest} AddSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddSeoTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddSeoTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.seoTaskInfo = $root.SeoTaskInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AddSeoTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AddSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AddSeoTaskRequest} AddSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddSeoTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AddSeoTaskRequest message.
     * @function verify
     * @memberof AddSeoTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AddSeoTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo")) {
            var error = $root.SeoTaskInfo.verify(message.seoTaskInfo);
            if (error)
                return "seoTaskInfo." + error;
        }
        return null;
    };

    /**
     * Creates an AddSeoTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AddSeoTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AddSeoTaskRequest} AddSeoTaskRequest
     */
    AddSeoTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AddSeoTaskRequest)
            return object;
        var message = new $root.AddSeoTaskRequest();
        if (object.seoTaskInfo != null) {
            if (typeof object.seoTaskInfo !== "object")
                throw TypeError(".AddSeoTaskRequest.seoTaskInfo: object expected");
            message.seoTaskInfo = $root.SeoTaskInfo.fromObject(object.seoTaskInfo);
        }
        return message;
    };

    /**
     * Creates a plain object from an AddSeoTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AddSeoTaskRequest
     * @static
     * @param {AddSeoTaskRequest} message AddSeoTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AddSeoTaskRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.seoTaskInfo = null;
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo"))
            object.seoTaskInfo = $root.SeoTaskInfo.toObject(message.seoTaskInfo, options);
        return object;
    };

    /**
     * Converts this AddSeoTaskRequest to JSON.
     * @function toJSON
     * @memberof AddSeoTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AddSeoTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AddSeoTaskRequest
     * @function getTypeUrl
     * @memberof AddSeoTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AddSeoTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddSeoTaskRequest";
    };

    return AddSeoTaskRequest;
})();

$root.AddSeoTaskRespond = (function() {

    /**
     * Properties of an AddSeoTaskRespond.
     * @exports IAddSeoTaskRespond
     * @interface IAddSeoTaskRespond
     * @property {boolean|null} [result] AddSeoTaskRespond result
     */

    /**
     * Constructs a new AddSeoTaskRespond.
     * @exports AddSeoTaskRespond
     * @classdesc Represents an AddSeoTaskRespond.
     * @implements IAddSeoTaskRespond
     * @constructor
     * @param {IAddSeoTaskRespond=} [properties] Properties to set
     */
    function AddSeoTaskRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AddSeoTaskRespond result.
     * @member {boolean} result
     * @memberof AddSeoTaskRespond
     * @instance
     */
    AddSeoTaskRespond.prototype.result = false;

    /**
     * Creates a new AddSeoTaskRespond instance using the specified properties.
     * @function create
     * @memberof AddSeoTaskRespond
     * @static
     * @param {IAddSeoTaskRespond=} [properties] Properties to set
     * @returns {AddSeoTaskRespond} AddSeoTaskRespond instance
     */
    AddSeoTaskRespond.create = function create(properties) {
        return new AddSeoTaskRespond(properties);
    };

    /**
     * Encodes the specified AddSeoTaskRespond message. Does not implicitly {@link AddSeoTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof AddSeoTaskRespond
     * @static
     * @param {IAddSeoTaskRespond} message AddSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddSeoTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.result);
        return writer;
    };

    /**
     * Encodes the specified AddSeoTaskRespond message, length delimited. Does not implicitly {@link AddSeoTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AddSeoTaskRespond
     * @static
     * @param {IAddSeoTaskRespond} message AddSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AddSeoTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AddSeoTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof AddSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AddSeoTaskRespond} AddSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddSeoTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddSeoTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AddSeoTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AddSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AddSeoTaskRespond} AddSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AddSeoTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AddSeoTaskRespond message.
     * @function verify
     * @memberof AddSeoTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AddSeoTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (typeof message.result !== "boolean")
                return "result: boolean expected";
        return null;
    };

    /**
     * Creates an AddSeoTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AddSeoTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AddSeoTaskRespond} AddSeoTaskRespond
     */
    AddSeoTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.AddSeoTaskRespond)
            return object;
        var message = new $root.AddSeoTaskRespond();
        if (object.result != null)
            message.result = Boolean(object.result);
        return message;
    };

    /**
     * Creates a plain object from an AddSeoTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AddSeoTaskRespond
     * @static
     * @param {AddSeoTaskRespond} message AddSeoTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AddSeoTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.result = false;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        return object;
    };

    /**
     * Converts this AddSeoTaskRespond to JSON.
     * @function toJSON
     * @memberof AddSeoTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AddSeoTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AddSeoTaskRespond
     * @function getTypeUrl
     * @memberof AddSeoTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AddSeoTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AddSeoTaskRespond";
    };

    return AddSeoTaskRespond;
})();

$root.RemoveSeoTasksRequest = (function() {

    /**
     * Properties of a RemoveSeoTasksRequest.
     * @exports IRemoveSeoTasksRequest
     * @interface IRemoveSeoTasksRequest
     * @property {Array.<number>|null} [ids] RemoveSeoTasksRequest ids
     */

    /**
     * Constructs a new RemoveSeoTasksRequest.
     * @exports RemoveSeoTasksRequest
     * @classdesc Represents a RemoveSeoTasksRequest.
     * @implements IRemoveSeoTasksRequest
     * @constructor
     * @param {IRemoveSeoTasksRequest=} [properties] Properties to set
     */
    function RemoveSeoTasksRequest(properties) {
        this.ids = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoveSeoTasksRequest ids.
     * @member {Array.<number>} ids
     * @memberof RemoveSeoTasksRequest
     * @instance
     */
    RemoveSeoTasksRequest.prototype.ids = $util.emptyArray;

    /**
     * Creates a new RemoveSeoTasksRequest instance using the specified properties.
     * @function create
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {IRemoveSeoTasksRequest=} [properties] Properties to set
     * @returns {RemoveSeoTasksRequest} RemoveSeoTasksRequest instance
     */
    RemoveSeoTasksRequest.create = function create(properties) {
        return new RemoveSeoTasksRequest(properties);
    };

    /**
     * Encodes the specified RemoveSeoTasksRequest message. Does not implicitly {@link RemoveSeoTasksRequest.verify|verify} messages.
     * @function encode
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {IRemoveSeoTasksRequest} message RemoveSeoTasksRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveSeoTasksRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ids != null && message.ids.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.ids.length; ++i)
                writer.uint32(message.ids[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified RemoveSeoTasksRequest message, length delimited. Does not implicitly {@link RemoveSeoTasksRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {IRemoveSeoTasksRequest} message RemoveSeoTasksRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveSeoTasksRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoveSeoTasksRequest message from the specified reader or buffer.
     * @function decode
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoveSeoTasksRequest} RemoveSeoTasksRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveSeoTasksRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoveSeoTasksRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ids.push(reader.uint32());
                    } else
                        message.ids.push(reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoveSeoTasksRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoveSeoTasksRequest} RemoveSeoTasksRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveSeoTasksRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoveSeoTasksRequest message.
     * @function verify
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoveSeoTasksRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ids != null && message.hasOwnProperty("ids")) {
            if (!Array.isArray(message.ids))
                return "ids: array expected";
            for (var i = 0; i < message.ids.length; ++i)
                if (!$util.isInteger(message.ids[i]))
                    return "ids: integer[] expected";
        }
        return null;
    };

    /**
     * Creates a RemoveSeoTasksRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoveSeoTasksRequest} RemoveSeoTasksRequest
     */
    RemoveSeoTasksRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoveSeoTasksRequest)
            return object;
        var message = new $root.RemoveSeoTasksRequest();
        if (object.ids) {
            if (!Array.isArray(object.ids))
                throw TypeError(".RemoveSeoTasksRequest.ids: array expected");
            message.ids = [];
            for (var i = 0; i < object.ids.length; ++i)
                message.ids[i] = object.ids[i] >>> 0;
        }
        return message;
    };

    /**
     * Creates a plain object from a RemoveSeoTasksRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {RemoveSeoTasksRequest} message RemoveSeoTasksRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoveSeoTasksRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.ids = [];
        if (message.ids && message.ids.length) {
            object.ids = [];
            for (var j = 0; j < message.ids.length; ++j)
                object.ids[j] = message.ids[j];
        }
        return object;
    };

    /**
     * Converts this RemoveSeoTasksRequest to JSON.
     * @function toJSON
     * @memberof RemoveSeoTasksRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoveSeoTasksRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoveSeoTasksRequest
     * @function getTypeUrl
     * @memberof RemoveSeoTasksRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoveSeoTasksRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoveSeoTasksRequest";
    };

    return RemoveSeoTasksRequest;
})();

$root.RemoveSeoTasksRespond = (function() {

    /**
     * Properties of a RemoveSeoTasksRespond.
     * @exports IRemoveSeoTasksRespond
     * @interface IRemoveSeoTasksRespond
     * @property {Array.<number>|null} [ids] RemoveSeoTasksRespond ids
     */

    /**
     * Constructs a new RemoveSeoTasksRespond.
     * @exports RemoveSeoTasksRespond
     * @classdesc Represents a RemoveSeoTasksRespond.
     * @implements IRemoveSeoTasksRespond
     * @constructor
     * @param {IRemoveSeoTasksRespond=} [properties] Properties to set
     */
    function RemoveSeoTasksRespond(properties) {
        this.ids = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RemoveSeoTasksRespond ids.
     * @member {Array.<number>} ids
     * @memberof RemoveSeoTasksRespond
     * @instance
     */
    RemoveSeoTasksRespond.prototype.ids = $util.emptyArray;

    /**
     * Creates a new RemoveSeoTasksRespond instance using the specified properties.
     * @function create
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {IRemoveSeoTasksRespond=} [properties] Properties to set
     * @returns {RemoveSeoTasksRespond} RemoveSeoTasksRespond instance
     */
    RemoveSeoTasksRespond.create = function create(properties) {
        return new RemoveSeoTasksRespond(properties);
    };

    /**
     * Encodes the specified RemoveSeoTasksRespond message. Does not implicitly {@link RemoveSeoTasksRespond.verify|verify} messages.
     * @function encode
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {IRemoveSeoTasksRespond} message RemoveSeoTasksRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveSeoTasksRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ids != null && message.ids.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.ids.length; ++i)
                writer.uint32(message.ids[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified RemoveSeoTasksRespond message, length delimited. Does not implicitly {@link RemoveSeoTasksRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {IRemoveSeoTasksRespond} message RemoveSeoTasksRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RemoveSeoTasksRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RemoveSeoTasksRespond message from the specified reader or buffer.
     * @function decode
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RemoveSeoTasksRespond} RemoveSeoTasksRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveSeoTasksRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.RemoveSeoTasksRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ids.push(reader.uint32());
                    } else
                        message.ids.push(reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a RemoveSeoTasksRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {RemoveSeoTasksRespond} RemoveSeoTasksRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RemoveSeoTasksRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RemoveSeoTasksRespond message.
     * @function verify
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RemoveSeoTasksRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ids != null && message.hasOwnProperty("ids")) {
            if (!Array.isArray(message.ids))
                return "ids: array expected";
            for (var i = 0; i < message.ids.length; ++i)
                if (!$util.isInteger(message.ids[i]))
                    return "ids: integer[] expected";
        }
        return null;
    };

    /**
     * Creates a RemoveSeoTasksRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {RemoveSeoTasksRespond} RemoveSeoTasksRespond
     */
    RemoveSeoTasksRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.RemoveSeoTasksRespond)
            return object;
        var message = new $root.RemoveSeoTasksRespond();
        if (object.ids) {
            if (!Array.isArray(object.ids))
                throw TypeError(".RemoveSeoTasksRespond.ids: array expected");
            message.ids = [];
            for (var i = 0; i < object.ids.length; ++i)
                message.ids[i] = object.ids[i] >>> 0;
        }
        return message;
    };

    /**
     * Creates a plain object from a RemoveSeoTasksRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {RemoveSeoTasksRespond} message RemoveSeoTasksRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RemoveSeoTasksRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.ids = [];
        if (message.ids && message.ids.length) {
            object.ids = [];
            for (var j = 0; j < message.ids.length; ++j)
                object.ids[j] = message.ids[j];
        }
        return object;
    };

    /**
     * Converts this RemoveSeoTasksRespond to JSON.
     * @function toJSON
     * @memberof RemoveSeoTasksRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RemoveSeoTasksRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RemoveSeoTasksRespond
     * @function getTypeUrl
     * @memberof RemoveSeoTasksRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RemoveSeoTasksRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RemoveSeoTasksRespond";
    };

    return RemoveSeoTasksRespond;
})();

$root.SubscribeSeoTaskRequest = (function() {

    /**
     * Properties of a SubscribeSeoTaskRequest.
     * @exports ISubscribeSeoTaskRequest
     * @interface ISubscribeSeoTaskRequest
     */

    /**
     * Constructs a new SubscribeSeoTaskRequest.
     * @exports SubscribeSeoTaskRequest
     * @classdesc Represents a SubscribeSeoTaskRequest.
     * @implements ISubscribeSeoTaskRequest
     * @constructor
     * @param {ISubscribeSeoTaskRequest=} [properties] Properties to set
     */
    function SubscribeSeoTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SubscribeSeoTaskRequest instance using the specified properties.
     * @function create
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {ISubscribeSeoTaskRequest=} [properties] Properties to set
     * @returns {SubscribeSeoTaskRequest} SubscribeSeoTaskRequest instance
     */
    SubscribeSeoTaskRequest.create = function create(properties) {
        return new SubscribeSeoTaskRequest(properties);
    };

    /**
     * Encodes the specified SubscribeSeoTaskRequest message. Does not implicitly {@link SubscribeSeoTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {ISubscribeSeoTaskRequest} message SubscribeSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeSeoTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified SubscribeSeoTaskRequest message, length delimited. Does not implicitly {@link SubscribeSeoTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {ISubscribeSeoTaskRequest} message SubscribeSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeSeoTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubscribeSeoTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubscribeSeoTaskRequest} SubscribeSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeSeoTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscribeSeoTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubscribeSeoTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubscribeSeoTaskRequest} SubscribeSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeSeoTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubscribeSeoTaskRequest message.
     * @function verify
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubscribeSeoTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a SubscribeSeoTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubscribeSeoTaskRequest} SubscribeSeoTaskRequest
     */
    SubscribeSeoTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscribeSeoTaskRequest)
            return object;
        return new $root.SubscribeSeoTaskRequest();
    };

    /**
     * Creates a plain object from a SubscribeSeoTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {SubscribeSeoTaskRequest} message SubscribeSeoTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubscribeSeoTaskRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this SubscribeSeoTaskRequest to JSON.
     * @function toJSON
     * @memberof SubscribeSeoTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubscribeSeoTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubscribeSeoTaskRequest
     * @function getTypeUrl
     * @memberof SubscribeSeoTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubscribeSeoTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscribeSeoTaskRequest";
    };

    return SubscribeSeoTaskRequest;
})();

$root.SubscribeSeoTaskRespond = (function() {

    /**
     * Properties of a SubscribeSeoTaskRespond.
     * @exports ISubscribeSeoTaskRespond
     * @interface ISubscribeSeoTaskRespond
     * @property {Array.<ISeoTaskInfo>|null} [seoTaskInfos] SubscribeSeoTaskRespond seoTaskInfos
     */

    /**
     * Constructs a new SubscribeSeoTaskRespond.
     * @exports SubscribeSeoTaskRespond
     * @classdesc Represents a SubscribeSeoTaskRespond.
     * @implements ISubscribeSeoTaskRespond
     * @constructor
     * @param {ISubscribeSeoTaskRespond=} [properties] Properties to set
     */
    function SubscribeSeoTaskRespond(properties) {
        this.seoTaskInfos = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubscribeSeoTaskRespond seoTaskInfos.
     * @member {Array.<ISeoTaskInfo>} seoTaskInfos
     * @memberof SubscribeSeoTaskRespond
     * @instance
     */
    SubscribeSeoTaskRespond.prototype.seoTaskInfos = $util.emptyArray;

    /**
     * Creates a new SubscribeSeoTaskRespond instance using the specified properties.
     * @function create
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {ISubscribeSeoTaskRespond=} [properties] Properties to set
     * @returns {SubscribeSeoTaskRespond} SubscribeSeoTaskRespond instance
     */
    SubscribeSeoTaskRespond.create = function create(properties) {
        return new SubscribeSeoTaskRespond(properties);
    };

    /**
     * Encodes the specified SubscribeSeoTaskRespond message. Does not implicitly {@link SubscribeSeoTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {ISubscribeSeoTaskRespond} message SubscribeSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeSeoTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.seoTaskInfos != null && message.seoTaskInfos.length)
            for (var i = 0; i < message.seoTaskInfos.length; ++i)
                $root.SeoTaskInfo.encode(message.seoTaskInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SubscribeSeoTaskRespond message, length delimited. Does not implicitly {@link SubscribeSeoTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {ISubscribeSeoTaskRespond} message SubscribeSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubscribeSeoTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubscribeSeoTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SubscribeSeoTaskRespond} SubscribeSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeSeoTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SubscribeSeoTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.seoTaskInfos && message.seoTaskInfos.length))
                        message.seoTaskInfos = [];
                    message.seoTaskInfos.push($root.SeoTaskInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SubscribeSeoTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SubscribeSeoTaskRespond} SubscribeSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubscribeSeoTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubscribeSeoTaskRespond message.
     * @function verify
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubscribeSeoTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.seoTaskInfos != null && message.hasOwnProperty("seoTaskInfos")) {
            if (!Array.isArray(message.seoTaskInfos))
                return "seoTaskInfos: array expected";
            for (var i = 0; i < message.seoTaskInfos.length; ++i) {
                var error = $root.SeoTaskInfo.verify(message.seoTaskInfos[i]);
                if (error)
                    return "seoTaskInfos." + error;
            }
        }
        return null;
    };

    /**
     * Creates a SubscribeSeoTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SubscribeSeoTaskRespond} SubscribeSeoTaskRespond
     */
    SubscribeSeoTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.SubscribeSeoTaskRespond)
            return object;
        var message = new $root.SubscribeSeoTaskRespond();
        if (object.seoTaskInfos) {
            if (!Array.isArray(object.seoTaskInfos))
                throw TypeError(".SubscribeSeoTaskRespond.seoTaskInfos: array expected");
            message.seoTaskInfos = [];
            for (var i = 0; i < object.seoTaskInfos.length; ++i) {
                if (typeof object.seoTaskInfos[i] !== "object")
                    throw TypeError(".SubscribeSeoTaskRespond.seoTaskInfos: object expected");
                message.seoTaskInfos[i] = $root.SeoTaskInfo.fromObject(object.seoTaskInfos[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a SubscribeSeoTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {SubscribeSeoTaskRespond} message SubscribeSeoTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubscribeSeoTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.seoTaskInfos = [];
        if (message.seoTaskInfos && message.seoTaskInfos.length) {
            object.seoTaskInfos = [];
            for (var j = 0; j < message.seoTaskInfos.length; ++j)
                object.seoTaskInfos[j] = $root.SeoTaskInfo.toObject(message.seoTaskInfos[j], options);
        }
        return object;
    };

    /**
     * Converts this SubscribeSeoTaskRespond to JSON.
     * @function toJSON
     * @memberof SubscribeSeoTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubscribeSeoTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubscribeSeoTaskRespond
     * @function getTypeUrl
     * @memberof SubscribeSeoTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubscribeSeoTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SubscribeSeoTaskRespond";
    };

    return SubscribeSeoTaskRespond;
})();

$root.UpdateSeoTaskNotify = (function() {

    /**
     * Properties of an UpdateSeoTaskNotify.
     * @exports IUpdateSeoTaskNotify
     * @interface IUpdateSeoTaskNotify
     * @property {UpdateSeoTaskNotify.EventType|null} [event] UpdateSeoTaskNotify event
     * @property {ISeoTaskInfo|null} [info] UpdateSeoTaskNotify info
     */

    /**
     * Constructs a new UpdateSeoTaskNotify.
     * @exports UpdateSeoTaskNotify
     * @classdesc Represents an UpdateSeoTaskNotify.
     * @implements IUpdateSeoTaskNotify
     * @constructor
     * @param {IUpdateSeoTaskNotify=} [properties] Properties to set
     */
    function UpdateSeoTaskNotify(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSeoTaskNotify event.
     * @member {UpdateSeoTaskNotify.EventType} event
     * @memberof UpdateSeoTaskNotify
     * @instance
     */
    UpdateSeoTaskNotify.prototype.event = 0;

    /**
     * UpdateSeoTaskNotify info.
     * @member {ISeoTaskInfo|null|undefined} info
     * @memberof UpdateSeoTaskNotify
     * @instance
     */
    UpdateSeoTaskNotify.prototype.info = null;

    /**
     * Creates a new UpdateSeoTaskNotify instance using the specified properties.
     * @function create
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {IUpdateSeoTaskNotify=} [properties] Properties to set
     * @returns {UpdateSeoTaskNotify} UpdateSeoTaskNotify instance
     */
    UpdateSeoTaskNotify.create = function create(properties) {
        return new UpdateSeoTaskNotify(properties);
    };

    /**
     * Encodes the specified UpdateSeoTaskNotify message. Does not implicitly {@link UpdateSeoTaskNotify.verify|verify} messages.
     * @function encode
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {IUpdateSeoTaskNotify} message UpdateSeoTaskNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskNotify.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.event != null && Object.hasOwnProperty.call(message, "event"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.event);
        if (message.info != null && Object.hasOwnProperty.call(message, "info"))
            $root.SeoTaskInfo.encode(message.info, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSeoTaskNotify message, length delimited. Does not implicitly {@link UpdateSeoTaskNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {IUpdateSeoTaskNotify} message UpdateSeoTaskNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskNotify.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSeoTaskNotify message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSeoTaskNotify} UpdateSeoTaskNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskNotify.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSeoTaskNotify();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.event = reader.int32();
                    break;
                }
            case 2: {
                    message.info = $root.SeoTaskInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSeoTaskNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSeoTaskNotify} UpdateSeoTaskNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskNotify.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSeoTaskNotify message.
     * @function verify
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSeoTaskNotify.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.event != null && message.hasOwnProperty("event"))
            switch (message.event) {
            default:
                return "event: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.info != null && message.hasOwnProperty("info")) {
            var error = $root.SeoTaskInfo.verify(message.info);
            if (error)
                return "info." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSeoTaskNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSeoTaskNotify} UpdateSeoTaskNotify
     */
    UpdateSeoTaskNotify.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSeoTaskNotify)
            return object;
        var message = new $root.UpdateSeoTaskNotify();
        switch (object.event) {
        default:
            if (typeof object.event === "number") {
                message.event = object.event;
                break;
            }
            break;
        case "Add":
        case 0:
            message.event = 0;
            break;
        case "Update":
        case 1:
            message.event = 1;
            break;
        case "Remove":
        case 2:
            message.event = 2;
            break;
        }
        if (object.info != null) {
            if (typeof object.info !== "object")
                throw TypeError(".UpdateSeoTaskNotify.info: object expected");
            message.info = $root.SeoTaskInfo.fromObject(object.info);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSeoTaskNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {UpdateSeoTaskNotify} message UpdateSeoTaskNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSeoTaskNotify.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.event = options.enums === String ? "Add" : 0;
            object.info = null;
        }
        if (message.event != null && message.hasOwnProperty("event"))
            object.event = options.enums === String ? $root.UpdateSeoTaskNotify.EventType[message.event] === undefined ? message.event : $root.UpdateSeoTaskNotify.EventType[message.event] : message.event;
        if (message.info != null && message.hasOwnProperty("info"))
            object.info = $root.SeoTaskInfo.toObject(message.info, options);
        return object;
    };

    /**
     * Converts this UpdateSeoTaskNotify to JSON.
     * @function toJSON
     * @memberof UpdateSeoTaskNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSeoTaskNotify.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSeoTaskNotify
     * @function getTypeUrl
     * @memberof UpdateSeoTaskNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSeoTaskNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSeoTaskNotify";
    };

    /**
     * EventType enum.
     * @name UpdateSeoTaskNotify.EventType
     * @enum {number}
     * @property {number} Add=0 Add value
     * @property {number} Update=1 Update value
     * @property {number} Remove=2 Remove value
     */
    UpdateSeoTaskNotify.EventType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Add"] = 0;
        values[valuesById[1] = "Update"] = 1;
        values[valuesById[2] = "Remove"] = 2;
        return values;
    })();

    return UpdateSeoTaskNotify;
})();

$root.SelectSeoTaskRequest = (function() {

    /**
     * Properties of a SelectSeoTaskRequest.
     * @exports ISelectSeoTaskRequest
     * @interface ISelectSeoTaskRequest
     * @property {string|null} [tags] SelectSeoTaskRequest tags
     */

    /**
     * Constructs a new SelectSeoTaskRequest.
     * @exports SelectSeoTaskRequest
     * @classdesc Represents a SelectSeoTaskRequest.
     * @implements ISelectSeoTaskRequest
     * @constructor
     * @param {ISelectSeoTaskRequest=} [properties] Properties to set
     */
    function SelectSeoTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SelectSeoTaskRequest tags.
     * @member {string} tags
     * @memberof SelectSeoTaskRequest
     * @instance
     */
    SelectSeoTaskRequest.prototype.tags = "";

    /**
     * Creates a new SelectSeoTaskRequest instance using the specified properties.
     * @function create
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {ISelectSeoTaskRequest=} [properties] Properties to set
     * @returns {SelectSeoTaskRequest} SelectSeoTaskRequest instance
     */
    SelectSeoTaskRequest.create = function create(properties) {
        return new SelectSeoTaskRequest(properties);
    };

    /**
     * Encodes the specified SelectSeoTaskRequest message. Does not implicitly {@link SelectSeoTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {ISelectSeoTaskRequest} message SelectSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectSeoTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tags != null && Object.hasOwnProperty.call(message, "tags"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.tags);
        return writer;
    };

    /**
     * Encodes the specified SelectSeoTaskRequest message, length delimited. Does not implicitly {@link SelectSeoTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {ISelectSeoTaskRequest} message SelectSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectSeoTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SelectSeoTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SelectSeoTaskRequest} SelectSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectSeoTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SelectSeoTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.tags = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SelectSeoTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SelectSeoTaskRequest} SelectSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectSeoTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SelectSeoTaskRequest message.
     * @function verify
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SelectSeoTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tags != null && message.hasOwnProperty("tags"))
            if (!$util.isString(message.tags))
                return "tags: string expected";
        return null;
    };

    /**
     * Creates a SelectSeoTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SelectSeoTaskRequest} SelectSeoTaskRequest
     */
    SelectSeoTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SelectSeoTaskRequest)
            return object;
        var message = new $root.SelectSeoTaskRequest();
        if (object.tags != null)
            message.tags = String(object.tags);
        return message;
    };

    /**
     * Creates a plain object from a SelectSeoTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {SelectSeoTaskRequest} message SelectSeoTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SelectSeoTaskRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.tags = "";
        if (message.tags != null && message.hasOwnProperty("tags"))
            object.tags = message.tags;
        return object;
    };

    /**
     * Converts this SelectSeoTaskRequest to JSON.
     * @function toJSON
     * @memberof SelectSeoTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SelectSeoTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SelectSeoTaskRequest
     * @function getTypeUrl
     * @memberof SelectSeoTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SelectSeoTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SelectSeoTaskRequest";
    };

    return SelectSeoTaskRequest;
})();

$root.SelectSeoTaskRespond = (function() {

    /**
     * Properties of a SelectSeoTaskRespond.
     * @exports ISelectSeoTaskRespond
     * @interface ISelectSeoTaskRespond
     * @property {Array.<ISeoTaskInfo>|null} [seoTaskInfos] SelectSeoTaskRespond seoTaskInfos
     */

    /**
     * Constructs a new SelectSeoTaskRespond.
     * @exports SelectSeoTaskRespond
     * @classdesc Represents a SelectSeoTaskRespond.
     * @implements ISelectSeoTaskRespond
     * @constructor
     * @param {ISelectSeoTaskRespond=} [properties] Properties to set
     */
    function SelectSeoTaskRespond(properties) {
        this.seoTaskInfos = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SelectSeoTaskRespond seoTaskInfos.
     * @member {Array.<ISeoTaskInfo>} seoTaskInfos
     * @memberof SelectSeoTaskRespond
     * @instance
     */
    SelectSeoTaskRespond.prototype.seoTaskInfos = $util.emptyArray;

    /**
     * Creates a new SelectSeoTaskRespond instance using the specified properties.
     * @function create
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {ISelectSeoTaskRespond=} [properties] Properties to set
     * @returns {SelectSeoTaskRespond} SelectSeoTaskRespond instance
     */
    SelectSeoTaskRespond.create = function create(properties) {
        return new SelectSeoTaskRespond(properties);
    };

    /**
     * Encodes the specified SelectSeoTaskRespond message. Does not implicitly {@link SelectSeoTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {ISelectSeoTaskRespond} message SelectSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectSeoTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.seoTaskInfos != null && message.seoTaskInfos.length)
            for (var i = 0; i < message.seoTaskInfos.length; ++i)
                $root.SeoTaskInfo.encode(message.seoTaskInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SelectSeoTaskRespond message, length delimited. Does not implicitly {@link SelectSeoTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {ISelectSeoTaskRespond} message SelectSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectSeoTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SelectSeoTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SelectSeoTaskRespond} SelectSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectSeoTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SelectSeoTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.seoTaskInfos && message.seoTaskInfos.length))
                        message.seoTaskInfos = [];
                    message.seoTaskInfos.push($root.SeoTaskInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SelectSeoTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SelectSeoTaskRespond} SelectSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectSeoTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SelectSeoTaskRespond message.
     * @function verify
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SelectSeoTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.seoTaskInfos != null && message.hasOwnProperty("seoTaskInfos")) {
            if (!Array.isArray(message.seoTaskInfos))
                return "seoTaskInfos: array expected";
            for (var i = 0; i < message.seoTaskInfos.length; ++i) {
                var error = $root.SeoTaskInfo.verify(message.seoTaskInfos[i]);
                if (error)
                    return "seoTaskInfos." + error;
            }
        }
        return null;
    };

    /**
     * Creates a SelectSeoTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SelectSeoTaskRespond} SelectSeoTaskRespond
     */
    SelectSeoTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.SelectSeoTaskRespond)
            return object;
        var message = new $root.SelectSeoTaskRespond();
        if (object.seoTaskInfos) {
            if (!Array.isArray(object.seoTaskInfos))
                throw TypeError(".SelectSeoTaskRespond.seoTaskInfos: array expected");
            message.seoTaskInfos = [];
            for (var i = 0; i < object.seoTaskInfos.length; ++i) {
                if (typeof object.seoTaskInfos[i] !== "object")
                    throw TypeError(".SelectSeoTaskRespond.seoTaskInfos: object expected");
                message.seoTaskInfos[i] = $root.SeoTaskInfo.fromObject(object.seoTaskInfos[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a SelectSeoTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {SelectSeoTaskRespond} message SelectSeoTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SelectSeoTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.seoTaskInfos = [];
        if (message.seoTaskInfos && message.seoTaskInfos.length) {
            object.seoTaskInfos = [];
            for (var j = 0; j < message.seoTaskInfos.length; ++j)
                object.seoTaskInfos[j] = $root.SeoTaskInfo.toObject(message.seoTaskInfos[j], options);
        }
        return object;
    };

    /**
     * Converts this SelectSeoTaskRespond to JSON.
     * @function toJSON
     * @memberof SelectSeoTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SelectSeoTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SelectSeoTaskRespond
     * @function getTypeUrl
     * @memberof SelectSeoTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SelectSeoTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SelectSeoTaskRespond";
    };

    return SelectSeoTaskRespond;
})();

$root.UpdateSeoTaskRequest = (function() {

    /**
     * Properties of an UpdateSeoTaskRequest.
     * @exports IUpdateSeoTaskRequest
     * @interface IUpdateSeoTaskRequest
     * @property {ISeoTaskInfo|null} [seoTaskInfo] UpdateSeoTaskRequest seoTaskInfo
     */

    /**
     * Constructs a new UpdateSeoTaskRequest.
     * @exports UpdateSeoTaskRequest
     * @classdesc Represents an UpdateSeoTaskRequest.
     * @implements IUpdateSeoTaskRequest
     * @constructor
     * @param {IUpdateSeoTaskRequest=} [properties] Properties to set
     */
    function UpdateSeoTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSeoTaskRequest seoTaskInfo.
     * @member {ISeoTaskInfo|null|undefined} seoTaskInfo
     * @memberof UpdateSeoTaskRequest
     * @instance
     */
    UpdateSeoTaskRequest.prototype.seoTaskInfo = null;

    /**
     * Creates a new UpdateSeoTaskRequest instance using the specified properties.
     * @function create
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {IUpdateSeoTaskRequest=} [properties] Properties to set
     * @returns {UpdateSeoTaskRequest} UpdateSeoTaskRequest instance
     */
    UpdateSeoTaskRequest.create = function create(properties) {
        return new UpdateSeoTaskRequest(properties);
    };

    /**
     * Encodes the specified UpdateSeoTaskRequest message. Does not implicitly {@link UpdateSeoTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {IUpdateSeoTaskRequest} message UpdateSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.seoTaskInfo != null && Object.hasOwnProperty.call(message, "seoTaskInfo"))
            $root.SeoTaskInfo.encode(message.seoTaskInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSeoTaskRequest message, length delimited. Does not implicitly {@link UpdateSeoTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {IUpdateSeoTaskRequest} message UpdateSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSeoTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSeoTaskRequest} UpdateSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSeoTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.seoTaskInfo = $root.SeoTaskInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSeoTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSeoTaskRequest} UpdateSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSeoTaskRequest message.
     * @function verify
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSeoTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo")) {
            var error = $root.SeoTaskInfo.verify(message.seoTaskInfo);
            if (error)
                return "seoTaskInfo." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSeoTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSeoTaskRequest} UpdateSeoTaskRequest
     */
    UpdateSeoTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSeoTaskRequest)
            return object;
        var message = new $root.UpdateSeoTaskRequest();
        if (object.seoTaskInfo != null) {
            if (typeof object.seoTaskInfo !== "object")
                throw TypeError(".UpdateSeoTaskRequest.seoTaskInfo: object expected");
            message.seoTaskInfo = $root.SeoTaskInfo.fromObject(object.seoTaskInfo);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSeoTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {UpdateSeoTaskRequest} message UpdateSeoTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSeoTaskRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.seoTaskInfo = null;
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo"))
            object.seoTaskInfo = $root.SeoTaskInfo.toObject(message.seoTaskInfo, options);
        return object;
    };

    /**
     * Converts this UpdateSeoTaskRequest to JSON.
     * @function toJSON
     * @memberof UpdateSeoTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSeoTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSeoTaskRequest
     * @function getTypeUrl
     * @memberof UpdateSeoTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSeoTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSeoTaskRequest";
    };

    return UpdateSeoTaskRequest;
})();

$root.UpdateSeoTaskRespond = (function() {

    /**
     * Properties of an UpdateSeoTaskRespond.
     * @exports IUpdateSeoTaskRespond
     * @interface IUpdateSeoTaskRespond
     * @property {boolean|null} [result] UpdateSeoTaskRespond result
     */

    /**
     * Constructs a new UpdateSeoTaskRespond.
     * @exports UpdateSeoTaskRespond
     * @classdesc Represents an UpdateSeoTaskRespond.
     * @implements IUpdateSeoTaskRespond
     * @constructor
     * @param {IUpdateSeoTaskRespond=} [properties] Properties to set
     */
    function UpdateSeoTaskRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSeoTaskRespond result.
     * @member {boolean} result
     * @memberof UpdateSeoTaskRespond
     * @instance
     */
    UpdateSeoTaskRespond.prototype.result = false;

    /**
     * Creates a new UpdateSeoTaskRespond instance using the specified properties.
     * @function create
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {IUpdateSeoTaskRespond=} [properties] Properties to set
     * @returns {UpdateSeoTaskRespond} UpdateSeoTaskRespond instance
     */
    UpdateSeoTaskRespond.create = function create(properties) {
        return new UpdateSeoTaskRespond(properties);
    };

    /**
     * Encodes the specified UpdateSeoTaskRespond message. Does not implicitly {@link UpdateSeoTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {IUpdateSeoTaskRespond} message UpdateSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.result != null && Object.hasOwnProperty.call(message, "result"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.result);
        return writer;
    };

    /**
     * Encodes the specified UpdateSeoTaskRespond message, length delimited. Does not implicitly {@link UpdateSeoTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {IUpdateSeoTaskRespond} message UpdateSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSeoTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSeoTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSeoTaskRespond} UpdateSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSeoTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.result = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSeoTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSeoTaskRespond} UpdateSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSeoTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSeoTaskRespond message.
     * @function verify
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSeoTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.result != null && message.hasOwnProperty("result"))
            if (typeof message.result !== "boolean")
                return "result: boolean expected";
        return null;
    };

    /**
     * Creates an UpdateSeoTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSeoTaskRespond} UpdateSeoTaskRespond
     */
    UpdateSeoTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSeoTaskRespond)
            return object;
        var message = new $root.UpdateSeoTaskRespond();
        if (object.result != null)
            message.result = Boolean(object.result);
        return message;
    };

    /**
     * Creates a plain object from an UpdateSeoTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {UpdateSeoTaskRespond} message UpdateSeoTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSeoTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.result = false;
        if (message.result != null && message.hasOwnProperty("result"))
            object.result = message.result;
        return object;
    };

    /**
     * Converts this UpdateSeoTaskRespond to JSON.
     * @function toJSON
     * @memberof UpdateSeoTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSeoTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSeoTaskRespond
     * @function getTypeUrl
     * @memberof UpdateSeoTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSeoTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSeoTaskRespond";
    };

    return UpdateSeoTaskRespond;
})();

$root.StartSeoTaskCommand = (function() {

    /**
     * Properties of a StartSeoTaskCommand.
     * @exports IStartSeoTaskCommand
     * @interface IStartSeoTaskCommand
     * @property {Array.<number>|null} [ids] StartSeoTaskCommand ids
     */

    /**
     * Constructs a new StartSeoTaskCommand.
     * @exports StartSeoTaskCommand
     * @classdesc Represents a StartSeoTaskCommand.
     * @implements IStartSeoTaskCommand
     * @constructor
     * @param {IStartSeoTaskCommand=} [properties] Properties to set
     */
    function StartSeoTaskCommand(properties) {
        this.ids = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StartSeoTaskCommand ids.
     * @member {Array.<number>} ids
     * @memberof StartSeoTaskCommand
     * @instance
     */
    StartSeoTaskCommand.prototype.ids = $util.emptyArray;

    /**
     * Creates a new StartSeoTaskCommand instance using the specified properties.
     * @function create
     * @memberof StartSeoTaskCommand
     * @static
     * @param {IStartSeoTaskCommand=} [properties] Properties to set
     * @returns {StartSeoTaskCommand} StartSeoTaskCommand instance
     */
    StartSeoTaskCommand.create = function create(properties) {
        return new StartSeoTaskCommand(properties);
    };

    /**
     * Encodes the specified StartSeoTaskCommand message. Does not implicitly {@link StartSeoTaskCommand.verify|verify} messages.
     * @function encode
     * @memberof StartSeoTaskCommand
     * @static
     * @param {IStartSeoTaskCommand} message StartSeoTaskCommand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartSeoTaskCommand.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ids != null && message.ids.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.ids.length; ++i)
                writer.uint32(message.ids[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified StartSeoTaskCommand message, length delimited. Does not implicitly {@link StartSeoTaskCommand.verify|verify} messages.
     * @function encodeDelimited
     * @memberof StartSeoTaskCommand
     * @static
     * @param {IStartSeoTaskCommand} message StartSeoTaskCommand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartSeoTaskCommand.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StartSeoTaskCommand message from the specified reader or buffer.
     * @function decode
     * @memberof StartSeoTaskCommand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StartSeoTaskCommand} StartSeoTaskCommand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartSeoTaskCommand.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.StartSeoTaskCommand();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ids.push(reader.uint32());
                    } else
                        message.ids.push(reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a StartSeoTaskCommand message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof StartSeoTaskCommand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {StartSeoTaskCommand} StartSeoTaskCommand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartSeoTaskCommand.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StartSeoTaskCommand message.
     * @function verify
     * @memberof StartSeoTaskCommand
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StartSeoTaskCommand.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ids != null && message.hasOwnProperty("ids")) {
            if (!Array.isArray(message.ids))
                return "ids: array expected";
            for (var i = 0; i < message.ids.length; ++i)
                if (!$util.isInteger(message.ids[i]))
                    return "ids: integer[] expected";
        }
        return null;
    };

    /**
     * Creates a StartSeoTaskCommand message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof StartSeoTaskCommand
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {StartSeoTaskCommand} StartSeoTaskCommand
     */
    StartSeoTaskCommand.fromObject = function fromObject(object) {
        if (object instanceof $root.StartSeoTaskCommand)
            return object;
        var message = new $root.StartSeoTaskCommand();
        if (object.ids) {
            if (!Array.isArray(object.ids))
                throw TypeError(".StartSeoTaskCommand.ids: array expected");
            message.ids = [];
            for (var i = 0; i < object.ids.length; ++i)
                message.ids[i] = object.ids[i] >>> 0;
        }
        return message;
    };

    /**
     * Creates a plain object from a StartSeoTaskCommand message. Also converts values to other types if specified.
     * @function toObject
     * @memberof StartSeoTaskCommand
     * @static
     * @param {StartSeoTaskCommand} message StartSeoTaskCommand
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StartSeoTaskCommand.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.ids = [];
        if (message.ids && message.ids.length) {
            object.ids = [];
            for (var j = 0; j < message.ids.length; ++j)
                object.ids[j] = message.ids[j];
        }
        return object;
    };

    /**
     * Converts this StartSeoTaskCommand to JSON.
     * @function toJSON
     * @memberof StartSeoTaskCommand
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StartSeoTaskCommand.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StartSeoTaskCommand
     * @function getTypeUrl
     * @memberof StartSeoTaskCommand
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StartSeoTaskCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StartSeoTaskCommand";
    };

    return StartSeoTaskCommand;
})();

$root.CancelSeoTaskCommand = (function() {

    /**
     * Properties of a CancelSeoTaskCommand.
     * @exports ICancelSeoTaskCommand
     * @interface ICancelSeoTaskCommand
     * @property {Array.<number>|null} [ids] CancelSeoTaskCommand ids
     */

    /**
     * Constructs a new CancelSeoTaskCommand.
     * @exports CancelSeoTaskCommand
     * @classdesc Represents a CancelSeoTaskCommand.
     * @implements ICancelSeoTaskCommand
     * @constructor
     * @param {ICancelSeoTaskCommand=} [properties] Properties to set
     */
    function CancelSeoTaskCommand(properties) {
        this.ids = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CancelSeoTaskCommand ids.
     * @member {Array.<number>} ids
     * @memberof CancelSeoTaskCommand
     * @instance
     */
    CancelSeoTaskCommand.prototype.ids = $util.emptyArray;

    /**
     * Creates a new CancelSeoTaskCommand instance using the specified properties.
     * @function create
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {ICancelSeoTaskCommand=} [properties] Properties to set
     * @returns {CancelSeoTaskCommand} CancelSeoTaskCommand instance
     */
    CancelSeoTaskCommand.create = function create(properties) {
        return new CancelSeoTaskCommand(properties);
    };

    /**
     * Encodes the specified CancelSeoTaskCommand message. Does not implicitly {@link CancelSeoTaskCommand.verify|verify} messages.
     * @function encode
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {ICancelSeoTaskCommand} message CancelSeoTaskCommand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CancelSeoTaskCommand.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ids != null && message.ids.length) {
            writer.uint32(/* id 1, wireType 2 =*/10).fork();
            for (var i = 0; i < message.ids.length; ++i)
                writer.uint32(message.ids[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified CancelSeoTaskCommand message, length delimited. Does not implicitly {@link CancelSeoTaskCommand.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {ICancelSeoTaskCommand} message CancelSeoTaskCommand message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CancelSeoTaskCommand.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CancelSeoTaskCommand message from the specified reader or buffer.
     * @function decode
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CancelSeoTaskCommand} CancelSeoTaskCommand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CancelSeoTaskCommand.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CancelSeoTaskCommand();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ids && message.ids.length))
                        message.ids = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ids.push(reader.uint32());
                    } else
                        message.ids.push(reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CancelSeoTaskCommand message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CancelSeoTaskCommand} CancelSeoTaskCommand
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CancelSeoTaskCommand.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CancelSeoTaskCommand message.
     * @function verify
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CancelSeoTaskCommand.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ids != null && message.hasOwnProperty("ids")) {
            if (!Array.isArray(message.ids))
                return "ids: array expected";
            for (var i = 0; i < message.ids.length; ++i)
                if (!$util.isInteger(message.ids[i]))
                    return "ids: integer[] expected";
        }
        return null;
    };

    /**
     * Creates a CancelSeoTaskCommand message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CancelSeoTaskCommand} CancelSeoTaskCommand
     */
    CancelSeoTaskCommand.fromObject = function fromObject(object) {
        if (object instanceof $root.CancelSeoTaskCommand)
            return object;
        var message = new $root.CancelSeoTaskCommand();
        if (object.ids) {
            if (!Array.isArray(object.ids))
                throw TypeError(".CancelSeoTaskCommand.ids: array expected");
            message.ids = [];
            for (var i = 0; i < object.ids.length; ++i)
                message.ids[i] = object.ids[i] >>> 0;
        }
        return message;
    };

    /**
     * Creates a plain object from a CancelSeoTaskCommand message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {CancelSeoTaskCommand} message CancelSeoTaskCommand
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CancelSeoTaskCommand.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.ids = [];
        if (message.ids && message.ids.length) {
            object.ids = [];
            for (var j = 0; j < message.ids.length; ++j)
                object.ids[j] = message.ids[j];
        }
        return object;
    };

    /**
     * Converts this CancelSeoTaskCommand to JSON.
     * @function toJSON
     * @memberof CancelSeoTaskCommand
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CancelSeoTaskCommand.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CancelSeoTaskCommand
     * @function getTypeUrl
     * @memberof CancelSeoTaskCommand
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CancelSeoTaskCommand.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CancelSeoTaskCommand";
    };

    return CancelSeoTaskCommand;
})();

$root.SelectIdleWorkerRequest = (function() {

    /**
     * Properties of a SelectIdleWorkerRequest.
     * @exports ISelectIdleWorkerRequest
     * @interface ISelectIdleWorkerRequest
     * @property {number|null} [maxCount] SelectIdleWorkerRequest maxCount
     */

    /**
     * Constructs a new SelectIdleWorkerRequest.
     * @exports SelectIdleWorkerRequest
     * @classdesc Represents a SelectIdleWorkerRequest.
     * @implements ISelectIdleWorkerRequest
     * @constructor
     * @param {ISelectIdleWorkerRequest=} [properties] Properties to set
     */
    function SelectIdleWorkerRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SelectIdleWorkerRequest maxCount.
     * @member {number} maxCount
     * @memberof SelectIdleWorkerRequest
     * @instance
     */
    SelectIdleWorkerRequest.prototype.maxCount = 0;

    /**
     * Creates a new SelectIdleWorkerRequest instance using the specified properties.
     * @function create
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {ISelectIdleWorkerRequest=} [properties] Properties to set
     * @returns {SelectIdleWorkerRequest} SelectIdleWorkerRequest instance
     */
    SelectIdleWorkerRequest.create = function create(properties) {
        return new SelectIdleWorkerRequest(properties);
    };

    /**
     * Encodes the specified SelectIdleWorkerRequest message. Does not implicitly {@link SelectIdleWorkerRequest.verify|verify} messages.
     * @function encode
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {ISelectIdleWorkerRequest} message SelectIdleWorkerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectIdleWorkerRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.maxCount != null && Object.hasOwnProperty.call(message, "maxCount"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxCount);
        return writer;
    };

    /**
     * Encodes the specified SelectIdleWorkerRequest message, length delimited. Does not implicitly {@link SelectIdleWorkerRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {ISelectIdleWorkerRequest} message SelectIdleWorkerRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectIdleWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SelectIdleWorkerRequest message from the specified reader or buffer.
     * @function decode
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SelectIdleWorkerRequest} SelectIdleWorkerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectIdleWorkerRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SelectIdleWorkerRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.maxCount = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SelectIdleWorkerRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SelectIdleWorkerRequest} SelectIdleWorkerRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectIdleWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SelectIdleWorkerRequest message.
     * @function verify
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SelectIdleWorkerRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            if (!$util.isInteger(message.maxCount))
                return "maxCount: integer expected";
        return null;
    };

    /**
     * Creates a SelectIdleWorkerRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SelectIdleWorkerRequest} SelectIdleWorkerRequest
     */
    SelectIdleWorkerRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.SelectIdleWorkerRequest)
            return object;
        var message = new $root.SelectIdleWorkerRequest();
        if (object.maxCount != null)
            message.maxCount = object.maxCount | 0;
        return message;
    };

    /**
     * Creates a plain object from a SelectIdleWorkerRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {SelectIdleWorkerRequest} message SelectIdleWorkerRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SelectIdleWorkerRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.maxCount = 0;
        if (message.maxCount != null && message.hasOwnProperty("maxCount"))
            object.maxCount = message.maxCount;
        return object;
    };

    /**
     * Converts this SelectIdleWorkerRequest to JSON.
     * @function toJSON
     * @memberof SelectIdleWorkerRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SelectIdleWorkerRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SelectIdleWorkerRequest
     * @function getTypeUrl
     * @memberof SelectIdleWorkerRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SelectIdleWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SelectIdleWorkerRequest";
    };

    return SelectIdleWorkerRequest;
})();

$root.SelectIdleWorkderRespond = (function() {

    /**
     * Properties of a SelectIdleWorkderRespond.
     * @exports ISelectIdleWorkderRespond
     * @interface ISelectIdleWorkderRespond
     * @property {Array.<IWorkerInfo>|null} [workerInfos] SelectIdleWorkderRespond workerInfos
     */

    /**
     * Constructs a new SelectIdleWorkderRespond.
     * @exports SelectIdleWorkderRespond
     * @classdesc Represents a SelectIdleWorkderRespond.
     * @implements ISelectIdleWorkderRespond
     * @constructor
     * @param {ISelectIdleWorkderRespond=} [properties] Properties to set
     */
    function SelectIdleWorkderRespond(properties) {
        this.workerInfos = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * SelectIdleWorkderRespond workerInfos.
     * @member {Array.<IWorkerInfo>} workerInfos
     * @memberof SelectIdleWorkderRespond
     * @instance
     */
    SelectIdleWorkderRespond.prototype.workerInfos = $util.emptyArray;

    /**
     * Creates a new SelectIdleWorkderRespond instance using the specified properties.
     * @function create
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {ISelectIdleWorkderRespond=} [properties] Properties to set
     * @returns {SelectIdleWorkderRespond} SelectIdleWorkderRespond instance
     */
    SelectIdleWorkderRespond.create = function create(properties) {
        return new SelectIdleWorkderRespond(properties);
    };

    /**
     * Encodes the specified SelectIdleWorkderRespond message. Does not implicitly {@link SelectIdleWorkderRespond.verify|verify} messages.
     * @function encode
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {ISelectIdleWorkderRespond} message SelectIdleWorkderRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectIdleWorkderRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.workerInfos != null && message.workerInfos.length)
            for (var i = 0; i < message.workerInfos.length; ++i)
                $root.WorkerInfo.encode(message.workerInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified SelectIdleWorkderRespond message, length delimited. Does not implicitly {@link SelectIdleWorkderRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {ISelectIdleWorkderRespond} message SelectIdleWorkderRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SelectIdleWorkderRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SelectIdleWorkderRespond message from the specified reader or buffer.
     * @function decode
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SelectIdleWorkderRespond} SelectIdleWorkderRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectIdleWorkderRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SelectIdleWorkderRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.workerInfos && message.workerInfos.length))
                        message.workerInfos = [];
                    message.workerInfos.push($root.WorkerInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SelectIdleWorkderRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SelectIdleWorkderRespond} SelectIdleWorkderRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SelectIdleWorkderRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SelectIdleWorkderRespond message.
     * @function verify
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SelectIdleWorkderRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.workerInfos != null && message.hasOwnProperty("workerInfos")) {
            if (!Array.isArray(message.workerInfos))
                return "workerInfos: array expected";
            for (var i = 0; i < message.workerInfos.length; ++i) {
                var error = $root.WorkerInfo.verify(message.workerInfos[i]);
                if (error)
                    return "workerInfos." + error;
            }
        }
        return null;
    };

    /**
     * Creates a SelectIdleWorkderRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SelectIdleWorkderRespond} SelectIdleWorkderRespond
     */
    SelectIdleWorkderRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.SelectIdleWorkderRespond)
            return object;
        var message = new $root.SelectIdleWorkderRespond();
        if (object.workerInfos) {
            if (!Array.isArray(object.workerInfos))
                throw TypeError(".SelectIdleWorkderRespond.workerInfos: array expected");
            message.workerInfos = [];
            for (var i = 0; i < object.workerInfos.length; ++i) {
                if (typeof object.workerInfos[i] !== "object")
                    throw TypeError(".SelectIdleWorkderRespond.workerInfos: object expected");
                message.workerInfos[i] = $root.WorkerInfo.fromObject(object.workerInfos[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a SelectIdleWorkderRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {SelectIdleWorkderRespond} message SelectIdleWorkderRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SelectIdleWorkderRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.workerInfos = [];
        if (message.workerInfos && message.workerInfos.length) {
            object.workerInfos = [];
            for (var j = 0; j < message.workerInfos.length; ++j)
                object.workerInfos[j] = $root.WorkerInfo.toObject(message.workerInfos[j], options);
        }
        return object;
    };

    /**
     * Converts this SelectIdleWorkderRespond to JSON.
     * @function toJSON
     * @memberof SelectIdleWorkderRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SelectIdleWorkderRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SelectIdleWorkderRespond
     * @function getTypeUrl
     * @memberof SelectIdleWorkderRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SelectIdleWorkderRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SelectIdleWorkderRespond";
    };

    return SelectIdleWorkderRespond;
})();

$root.GetSeoTaskRequest = (function() {

    /**
     * Properties of a GetSeoTaskRequest.
     * @exports IGetSeoTaskRequest
     * @interface IGetSeoTaskRequest
     */

    /**
     * Constructs a new GetSeoTaskRequest.
     * @exports GetSeoTaskRequest
     * @classdesc Represents a GetSeoTaskRequest.
     * @implements IGetSeoTaskRequest
     * @constructor
     * @param {IGetSeoTaskRequest=} [properties] Properties to set
     */
    function GetSeoTaskRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetSeoTaskRequest instance using the specified properties.
     * @function create
     * @memberof GetSeoTaskRequest
     * @static
     * @param {IGetSeoTaskRequest=} [properties] Properties to set
     * @returns {GetSeoTaskRequest} GetSeoTaskRequest instance
     */
    GetSeoTaskRequest.create = function create(properties) {
        return new GetSeoTaskRequest(properties);
    };

    /**
     * Encodes the specified GetSeoTaskRequest message. Does not implicitly {@link GetSeoTaskRequest.verify|verify} messages.
     * @function encode
     * @memberof GetSeoTaskRequest
     * @static
     * @param {IGetSeoTaskRequest} message GetSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSeoTaskRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetSeoTaskRequest message, length delimited. Does not implicitly {@link GetSeoTaskRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSeoTaskRequest
     * @static
     * @param {IGetSeoTaskRequest} message GetSeoTaskRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSeoTaskRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSeoTaskRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSeoTaskRequest} GetSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSeoTaskRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSeoTaskRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSeoTaskRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSeoTaskRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSeoTaskRequest} GetSeoTaskRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSeoTaskRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSeoTaskRequest message.
     * @function verify
     * @memberof GetSeoTaskRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSeoTaskRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetSeoTaskRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSeoTaskRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSeoTaskRequest} GetSeoTaskRequest
     */
    GetSeoTaskRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSeoTaskRequest)
            return object;
        return new $root.GetSeoTaskRequest();
    };

    /**
     * Creates a plain object from a GetSeoTaskRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSeoTaskRequest
     * @static
     * @param {GetSeoTaskRequest} message GetSeoTaskRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSeoTaskRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetSeoTaskRequest to JSON.
     * @function toJSON
     * @memberof GetSeoTaskRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSeoTaskRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSeoTaskRequest
     * @function getTypeUrl
     * @memberof GetSeoTaskRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSeoTaskRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSeoTaskRequest";
    };

    return GetSeoTaskRequest;
})();

$root.GetSeoTaskRespond = (function() {

    /**
     * Properties of a GetSeoTaskRespond.
     * @exports IGetSeoTaskRespond
     * @interface IGetSeoTaskRespond
     * @property {ISeoTaskInfo|null} [seoTaskInfo] GetSeoTaskRespond seoTaskInfo
     */

    /**
     * Constructs a new GetSeoTaskRespond.
     * @exports GetSeoTaskRespond
     * @classdesc Represents a GetSeoTaskRespond.
     * @implements IGetSeoTaskRespond
     * @constructor
     * @param {IGetSeoTaskRespond=} [properties] Properties to set
     */
    function GetSeoTaskRespond(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetSeoTaskRespond seoTaskInfo.
     * @member {ISeoTaskInfo|null|undefined} seoTaskInfo
     * @memberof GetSeoTaskRespond
     * @instance
     */
    GetSeoTaskRespond.prototype.seoTaskInfo = null;

    /**
     * Creates a new GetSeoTaskRespond instance using the specified properties.
     * @function create
     * @memberof GetSeoTaskRespond
     * @static
     * @param {IGetSeoTaskRespond=} [properties] Properties to set
     * @returns {GetSeoTaskRespond} GetSeoTaskRespond instance
     */
    GetSeoTaskRespond.create = function create(properties) {
        return new GetSeoTaskRespond(properties);
    };

    /**
     * Encodes the specified GetSeoTaskRespond message. Does not implicitly {@link GetSeoTaskRespond.verify|verify} messages.
     * @function encode
     * @memberof GetSeoTaskRespond
     * @static
     * @param {IGetSeoTaskRespond} message GetSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSeoTaskRespond.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.seoTaskInfo != null && Object.hasOwnProperty.call(message, "seoTaskInfo"))
            $root.SeoTaskInfo.encode(message.seoTaskInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetSeoTaskRespond message, length delimited. Does not implicitly {@link GetSeoTaskRespond.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSeoTaskRespond
     * @static
     * @param {IGetSeoTaskRespond} message GetSeoTaskRespond message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSeoTaskRespond.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSeoTaskRespond message from the specified reader or buffer.
     * @function decode
     * @memberof GetSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSeoTaskRespond} GetSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSeoTaskRespond.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSeoTaskRespond();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.seoTaskInfo = $root.SeoTaskInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSeoTaskRespond message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSeoTaskRespond
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSeoTaskRespond} GetSeoTaskRespond
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSeoTaskRespond.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSeoTaskRespond message.
     * @function verify
     * @memberof GetSeoTaskRespond
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSeoTaskRespond.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo")) {
            var error = $root.SeoTaskInfo.verify(message.seoTaskInfo);
            if (error)
                return "seoTaskInfo." + error;
        }
        return null;
    };

    /**
     * Creates a GetSeoTaskRespond message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSeoTaskRespond
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSeoTaskRespond} GetSeoTaskRespond
     */
    GetSeoTaskRespond.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSeoTaskRespond)
            return object;
        var message = new $root.GetSeoTaskRespond();
        if (object.seoTaskInfo != null) {
            if (typeof object.seoTaskInfo !== "object")
                throw TypeError(".GetSeoTaskRespond.seoTaskInfo: object expected");
            message.seoTaskInfo = $root.SeoTaskInfo.fromObject(object.seoTaskInfo);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetSeoTaskRespond message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSeoTaskRespond
     * @static
     * @param {GetSeoTaskRespond} message GetSeoTaskRespond
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSeoTaskRespond.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.seoTaskInfo = null;
        if (message.seoTaskInfo != null && message.hasOwnProperty("seoTaskInfo"))
            object.seoTaskInfo = $root.SeoTaskInfo.toObject(message.seoTaskInfo, options);
        return object;
    };

    /**
     * Converts this GetSeoTaskRespond to JSON.
     * @function toJSON
     * @memberof GetSeoTaskRespond
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSeoTaskRespond.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSeoTaskRespond
     * @function getTypeUrl
     * @memberof GetSeoTaskRespond
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSeoTaskRespond.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSeoTaskRespond";
    };

    return GetSeoTaskRespond;
})();

$root.CompletedSeoTaskNotify = (function() {

    /**
     * Properties of a CompletedSeoTaskNotify.
     * @exports ICompletedSeoTaskNotify
     * @interface ICompletedSeoTaskNotify
     * @property {number|null} [id] CompletedSeoTaskNotify id
     * @property {number|null} [ranking] CompletedSeoTaskNotify ranking
     */

    /**
     * Constructs a new CompletedSeoTaskNotify.
     * @exports CompletedSeoTaskNotify
     * @classdesc Represents a CompletedSeoTaskNotify.
     * @implements ICompletedSeoTaskNotify
     * @constructor
     * @param {ICompletedSeoTaskNotify=} [properties] Properties to set
     */
    function CompletedSeoTaskNotify(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CompletedSeoTaskNotify id.
     * @member {number} id
     * @memberof CompletedSeoTaskNotify
     * @instance
     */
    CompletedSeoTaskNotify.prototype.id = 0;

    /**
     * CompletedSeoTaskNotify ranking.
     * @member {number} ranking
     * @memberof CompletedSeoTaskNotify
     * @instance
     */
    CompletedSeoTaskNotify.prototype.ranking = 0;

    /**
     * Creates a new CompletedSeoTaskNotify instance using the specified properties.
     * @function create
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {ICompletedSeoTaskNotify=} [properties] Properties to set
     * @returns {CompletedSeoTaskNotify} CompletedSeoTaskNotify instance
     */
    CompletedSeoTaskNotify.create = function create(properties) {
        return new CompletedSeoTaskNotify(properties);
    };

    /**
     * Encodes the specified CompletedSeoTaskNotify message. Does not implicitly {@link CompletedSeoTaskNotify.verify|verify} messages.
     * @function encode
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {ICompletedSeoTaskNotify} message CompletedSeoTaskNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompletedSeoTaskNotify.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
        if (message.ranking != null && Object.hasOwnProperty.call(message, "ranking"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ranking);
        return writer;
    };

    /**
     * Encodes the specified CompletedSeoTaskNotify message, length delimited. Does not implicitly {@link CompletedSeoTaskNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {ICompletedSeoTaskNotify} message CompletedSeoTaskNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompletedSeoTaskNotify.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CompletedSeoTaskNotify message from the specified reader or buffer.
     * @function decode
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CompletedSeoTaskNotify} CompletedSeoTaskNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompletedSeoTaskNotify.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CompletedSeoTaskNotify();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.uint32();
                    break;
                }
            case 2: {
                    message.ranking = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CompletedSeoTaskNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CompletedSeoTaskNotify} CompletedSeoTaskNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompletedSeoTaskNotify.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CompletedSeoTaskNotify message.
     * @function verify
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CompletedSeoTaskNotify.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        if (message.ranking != null && message.hasOwnProperty("ranking"))
            if (!$util.isInteger(message.ranking))
                return "ranking: integer expected";
        return null;
    };

    /**
     * Creates a CompletedSeoTaskNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CompletedSeoTaskNotify} CompletedSeoTaskNotify
     */
    CompletedSeoTaskNotify.fromObject = function fromObject(object) {
        if (object instanceof $root.CompletedSeoTaskNotify)
            return object;
        var message = new $root.CompletedSeoTaskNotify();
        if (object.id != null)
            message.id = object.id >>> 0;
        if (object.ranking != null)
            message.ranking = object.ranking | 0;
        return message;
    };

    /**
     * Creates a plain object from a CompletedSeoTaskNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {CompletedSeoTaskNotify} message CompletedSeoTaskNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CompletedSeoTaskNotify.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.id = 0;
            object.ranking = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.ranking != null && message.hasOwnProperty("ranking"))
            object.ranking = message.ranking;
        return object;
    };

    /**
     * Converts this CompletedSeoTaskNotify to JSON.
     * @function toJSON
     * @memberof CompletedSeoTaskNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CompletedSeoTaskNotify.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CompletedSeoTaskNotify
     * @function getTypeUrl
     * @memberof CompletedSeoTaskNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CompletedSeoTaskNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CompletedSeoTaskNotify";
    };

    return CompletedSeoTaskNotify;
})();

$root.SeoTaskFailNotify = (function() {

    /**
     * Properties of a SeoTaskFailNotify.
     * @exports ISeoTaskFailNotify
     * @interface ISeoTaskFailNotify
     */

    /**
     * Constructs a new SeoTaskFailNotify.
     * @exports SeoTaskFailNotify
     * @classdesc Represents a SeoTaskFailNotify.
     * @implements ISeoTaskFailNotify
     * @constructor
     * @param {ISeoTaskFailNotify=} [properties] Properties to set
     */
    function SeoTaskFailNotify(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SeoTaskFailNotify instance using the specified properties.
     * @function create
     * @memberof SeoTaskFailNotify
     * @static
     * @param {ISeoTaskFailNotify=} [properties] Properties to set
     * @returns {SeoTaskFailNotify} SeoTaskFailNotify instance
     */
    SeoTaskFailNotify.create = function create(properties) {
        return new SeoTaskFailNotify(properties);
    };

    /**
     * Encodes the specified SeoTaskFailNotify message. Does not implicitly {@link SeoTaskFailNotify.verify|verify} messages.
     * @function encode
     * @memberof SeoTaskFailNotify
     * @static
     * @param {ISeoTaskFailNotify} message SeoTaskFailNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SeoTaskFailNotify.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified SeoTaskFailNotify message, length delimited. Does not implicitly {@link SeoTaskFailNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof SeoTaskFailNotify
     * @static
     * @param {ISeoTaskFailNotify} message SeoTaskFailNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SeoTaskFailNotify.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SeoTaskFailNotify message from the specified reader or buffer.
     * @function decode
     * @memberof SeoTaskFailNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {SeoTaskFailNotify} SeoTaskFailNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SeoTaskFailNotify.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SeoTaskFailNotify();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a SeoTaskFailNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof SeoTaskFailNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {SeoTaskFailNotify} SeoTaskFailNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SeoTaskFailNotify.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SeoTaskFailNotify message.
     * @function verify
     * @memberof SeoTaskFailNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SeoTaskFailNotify.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a SeoTaskFailNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof SeoTaskFailNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {SeoTaskFailNotify} SeoTaskFailNotify
     */
    SeoTaskFailNotify.fromObject = function fromObject(object) {
        if (object instanceof $root.SeoTaskFailNotify)
            return object;
        return new $root.SeoTaskFailNotify();
    };

    /**
     * Creates a plain object from a SeoTaskFailNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof SeoTaskFailNotify
     * @static
     * @param {SeoTaskFailNotify} message SeoTaskFailNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SeoTaskFailNotify.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this SeoTaskFailNotify to JSON.
     * @function toJSON
     * @memberof SeoTaskFailNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SeoTaskFailNotify.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SeoTaskFailNotify
     * @function getTypeUrl
     * @memberof SeoTaskFailNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SeoTaskFailNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SeoTaskFailNotify";
    };

    return SeoTaskFailNotify;
})();

$root.Message = (function() {

    /**
     * Properties of a Message.
     * @exports IMessage
     * @interface IMessage
     * @property {ILoginRequest|null} [loginRequest] Message loginRequest
     * @property {ILoginRespond|null} [loginRespond] Message loginRespond
     * @property {IGetCodeRequest|null} [getCodeRequest] Message getCodeRequest
     * @property {IGetCodeRespond|null} [getCodeRespond] Message getCodeRespond
     * @property {IUpdateWorkerStateRequest|null} [updateWorkerStateRequest] Message updateWorkerStateRequest
     * @property {IInterruptRequest|null} [interruptRequest] Message interruptRequest
     * @property {IExecuteRequest|null} [executeRequest] Message executeRequest
     * @property {IIsUseRemoteCodeProviderRequest|null} [isUseRemoteCodeProviderRequest] Message isUseRemoteCodeProviderRequest
     * @property {IPing|null} [ping] Message ping
     * @property {ILogRequest|null} [logRequest] Message logRequest
     * @property {IIsUseRemoteLogRequest|null} [isUseRemoteLogRequest] Message isUseRemoteLogRequest
     * @property {IAddLogObserverRequest|null} [addLogObserverRequest] Message addLogObserverRequest
     * @property {IRemoveLogObserverRequest|null} [removeLogObserverRequest] Message removeLogObserverRequest
     * @property {ISetDebuggerRequest|null} [setDebuggerRequest] Message setDebuggerRequest
     * @property {IResetDebuggerRequest|null} [resetDebuggerRequest] Message resetDebuggerRequest
     * @property {IGetWorkerInfoRequest|null} [getWorkerInfoRequest] Message getWorkerInfoRequest
     * @property {IGetWorkerInfoRespond|null} [getWorkerInfoRespond] Message getWorkerInfoRespond
     * @property {IScreenShotRequest|null} [screenShotRequest] Message screenShotRequest
     * @property {IScreenShotRespond|null} [screenShotRespond] Message screenShotRespond
     * @property {IGetResourceRequest|null} [getResourceRequest] Message getResourceRequest
     * @property {IGetResourceRespond|null} [getResourceRespond] Message getResourceRespond
     * @property {IReadSettingRequest|null} [readSettingRequest] Message readSettingRequest
     * @property {IReadSettingRespond|null} [readSettingRespond] Message readSettingRespond
     * @property {IWriteSettingRequest|null} [writeSettingRequest] Message writeSettingRequest
     * @property {IRecognizeRequest|null} [recognizeRequest] Message recognizeRequest
     * @property {IRecognizeRespond|null} [recognizeRespond] Message recognizeRespond
     * @property {IGetTaskRequest|null} [getTaskRequest] Message getTaskRequest
     * @property {IGetTaskRespond|null} [getTaskRespond] Message getTaskRespond
     * @property {IExecuteTaskRequest|null} [executeTaskRequest] Message executeTaskRequest
     * @property {IPublishMessageRequest|null} [publishMessageRequest] Message publishMessageRequest
     * @property {ISubscribeMessageRequest|null} [subscribeMessageRequest] Message subscribeMessageRequest
     * @property {IRemoveMessageRequest|null} [removeMessageRequest] Message removeMessageRequest
     * @property {ISubscribeMessageRespond|null} [subscribeMessageRespond] Message subscribeMessageRespond
     * @property {IUnsubscribeMessageRequest|null} [unsubscribeMessageRequest] Message unsubscribeMessageRequest
     * @property {IUseLatestVerifyDataRequest|null} [useLatestVerifyDataRequest] Message useLatestVerifyDataRequest
     * @property {ILogoutRequest|null} [logoutRequest] Message logoutRequest
     * @property {IObserveWorkerRequest|null} [observeWorkerRequest] Message observeWorkerRequest
     * @property {IObserveWorkerRespond|null} [observeWorkerRespond] Message observeWorkerRespond
     * @property {IRemoteCallRequest|null} [remoteCallRequest] Message remoteCallRequest
     * @property {IRemoteCallRespond|null} [remoteCallRespond] Message remoteCallRespond
     * @property {IStartTaskRequest|null} [startTaskRequest] Message startTaskRequest
     * @property {IStartTaskRespond|null} [startTaskRespond] Message startTaskRespond
     * @property {IAddSeoTaskRequest|null} [addSeoTaskRequest] Message addSeoTaskRequest
     * @property {IAddSeoTaskRespond|null} [addSeoTaskRespond] Message addSeoTaskRespond
     * @property {IRemoveSeoTasksRequest|null} [removeSeoTasksRequest] Message removeSeoTasksRequest
     * @property {IRemoveSeoTasksRespond|null} [removeSeoTasksRespond] Message removeSeoTasksRespond
     * @property {ISelectSeoTaskRequest|null} [selectSeoTaskRequest] Message selectSeoTaskRequest
     * @property {ISelectSeoTaskRespond|null} [selectSeoTaskRespond] Message selectSeoTaskRespond
     * @property {IUpdateSeoTaskRequest|null} [updateSeoTaskRequest] Message updateSeoTaskRequest
     * @property {IUpdateSeoTaskRespond|null} [updateSeoTaskRespond] Message updateSeoTaskRespond
     * @property {IStartSeoTaskCommand|null} [startSeoTaskCommand] Message startSeoTaskCommand
     * @property {ISelectIdleWorkerRequest|null} [selectIdleWorkerRequest] Message selectIdleWorkerRequest
     * @property {ISelectIdleWorkderRespond|null} [selectIdleWorkerRespond] Message selectIdleWorkerRespond
     * @property {IGetSeoTaskRequest|null} [getSeoTaskRequest] Message getSeoTaskRequest
     * @property {IGetSeoTaskRespond|null} [getSeoTaskRespond] Message getSeoTaskRespond
     * @property {ICompletedSeoTaskNotify|null} [completedSeoTaskNotify] Message completedSeoTaskNotify
     * @property {ISubscribeSeoTaskRequest|null} [subscribeSeoTaskRequest] Message subscribeSeoTaskRequest
     * @property {ISubscribeSeoTaskRespond|null} [subscribeSeoTaskRespond] Message subscribeSeoTaskRespond
     * @property {IUpdateSeoTaskNotify|null} [updateSeoTaskNotify] Message updateSeoTaskNotify
     * @property {ISeoTaskFailNotify|null} [seoTaskFailNotify] Message seoTaskFailNotify
     * @property {ICancelSeoTaskCommand|null} [cancelSeoTaskCommand] Message cancelSeoTaskCommand
     */

    /**
     * Constructs a new Message.
     * @exports Message
     * @classdesc Represents a Message.
     * @implements IMessage
     * @constructor
     * @param {IMessage=} [properties] Properties to set
     */
    function Message(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Message loginRequest.
     * @member {ILoginRequest|null|undefined} loginRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.loginRequest = null;

    /**
     * Message loginRespond.
     * @member {ILoginRespond|null|undefined} loginRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.loginRespond = null;

    /**
     * Message getCodeRequest.
     * @member {IGetCodeRequest|null|undefined} getCodeRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.getCodeRequest = null;

    /**
     * Message getCodeRespond.
     * @member {IGetCodeRespond|null|undefined} getCodeRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.getCodeRespond = null;

    /**
     * Message updateWorkerStateRequest.
     * @member {IUpdateWorkerStateRequest|null|undefined} updateWorkerStateRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.updateWorkerStateRequest = null;

    /**
     * Message interruptRequest.
     * @member {IInterruptRequest|null|undefined} interruptRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.interruptRequest = null;

    /**
     * Message executeRequest.
     * @member {IExecuteRequest|null|undefined} executeRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.executeRequest = null;

    /**
     * Message isUseRemoteCodeProviderRequest.
     * @member {IIsUseRemoteCodeProviderRequest|null|undefined} isUseRemoteCodeProviderRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.isUseRemoteCodeProviderRequest = null;

    /**
     * Message ping.
     * @member {IPing|null|undefined} ping
     * @memberof Message
     * @instance
     */
    Message.prototype.ping = null;

    /**
     * Message logRequest.
     * @member {ILogRequest|null|undefined} logRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.logRequest = null;

    /**
     * Message isUseRemoteLogRequest.
     * @member {IIsUseRemoteLogRequest|null|undefined} isUseRemoteLogRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.isUseRemoteLogRequest = null;

    /**
     * Message addLogObserverRequest.
     * @member {IAddLogObserverRequest|null|undefined} addLogObserverRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.addLogObserverRequest = null;

    /**
     * Message removeLogObserverRequest.
     * @member {IRemoveLogObserverRequest|null|undefined} removeLogObserverRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.removeLogObserverRequest = null;

    /**
     * Message setDebuggerRequest.
     * @member {ISetDebuggerRequest|null|undefined} setDebuggerRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.setDebuggerRequest = null;

    /**
     * Message resetDebuggerRequest.
     * @member {IResetDebuggerRequest|null|undefined} resetDebuggerRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.resetDebuggerRequest = null;

    /**
     * Message getWorkerInfoRequest.
     * @member {IGetWorkerInfoRequest|null|undefined} getWorkerInfoRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.getWorkerInfoRequest = null;

    /**
     * Message getWorkerInfoRespond.
     * @member {IGetWorkerInfoRespond|null|undefined} getWorkerInfoRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.getWorkerInfoRespond = null;

    /**
     * Message screenShotRequest.
     * @member {IScreenShotRequest|null|undefined} screenShotRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.screenShotRequest = null;

    /**
     * Message screenShotRespond.
     * @member {IScreenShotRespond|null|undefined} screenShotRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.screenShotRespond = null;

    /**
     * Message getResourceRequest.
     * @member {IGetResourceRequest|null|undefined} getResourceRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.getResourceRequest = null;

    /**
     * Message getResourceRespond.
     * @member {IGetResourceRespond|null|undefined} getResourceRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.getResourceRespond = null;

    /**
     * Message readSettingRequest.
     * @member {IReadSettingRequest|null|undefined} readSettingRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.readSettingRequest = null;

    /**
     * Message readSettingRespond.
     * @member {IReadSettingRespond|null|undefined} readSettingRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.readSettingRespond = null;

    /**
     * Message writeSettingRequest.
     * @member {IWriteSettingRequest|null|undefined} writeSettingRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.writeSettingRequest = null;

    /**
     * Message recognizeRequest.
     * @member {IRecognizeRequest|null|undefined} recognizeRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.recognizeRequest = null;

    /**
     * Message recognizeRespond.
     * @member {IRecognizeRespond|null|undefined} recognizeRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.recognizeRespond = null;

    /**
     * Message getTaskRequest.
     * @member {IGetTaskRequest|null|undefined} getTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.getTaskRequest = null;

    /**
     * Message getTaskRespond.
     * @member {IGetTaskRespond|null|undefined} getTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.getTaskRespond = null;

    /**
     * Message executeTaskRequest.
     * @member {IExecuteTaskRequest|null|undefined} executeTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.executeTaskRequest = null;

    /**
     * Message publishMessageRequest.
     * @member {IPublishMessageRequest|null|undefined} publishMessageRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.publishMessageRequest = null;

    /**
     * Message subscribeMessageRequest.
     * @member {ISubscribeMessageRequest|null|undefined} subscribeMessageRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.subscribeMessageRequest = null;

    /**
     * Message removeMessageRequest.
     * @member {IRemoveMessageRequest|null|undefined} removeMessageRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.removeMessageRequest = null;

    /**
     * Message subscribeMessageRespond.
     * @member {ISubscribeMessageRespond|null|undefined} subscribeMessageRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.subscribeMessageRespond = null;

    /**
     * Message unsubscribeMessageRequest.
     * @member {IUnsubscribeMessageRequest|null|undefined} unsubscribeMessageRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.unsubscribeMessageRequest = null;

    /**
     * Message useLatestVerifyDataRequest.
     * @member {IUseLatestVerifyDataRequest|null|undefined} useLatestVerifyDataRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.useLatestVerifyDataRequest = null;

    /**
     * Message logoutRequest.
     * @member {ILogoutRequest|null|undefined} logoutRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.logoutRequest = null;

    /**
     * Message observeWorkerRequest.
     * @member {IObserveWorkerRequest|null|undefined} observeWorkerRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.observeWorkerRequest = null;

    /**
     * Message observeWorkerRespond.
     * @member {IObserveWorkerRespond|null|undefined} observeWorkerRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.observeWorkerRespond = null;

    /**
     * Message remoteCallRequest.
     * @member {IRemoteCallRequest|null|undefined} remoteCallRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.remoteCallRequest = null;

    /**
     * Message remoteCallRespond.
     * @member {IRemoteCallRespond|null|undefined} remoteCallRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.remoteCallRespond = null;

    /**
     * Message startTaskRequest.
     * @member {IStartTaskRequest|null|undefined} startTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.startTaskRequest = null;

    /**
     * Message startTaskRespond.
     * @member {IStartTaskRespond|null|undefined} startTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.startTaskRespond = null;

    /**
     * Message addSeoTaskRequest.
     * @member {IAddSeoTaskRequest|null|undefined} addSeoTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.addSeoTaskRequest = null;

    /**
     * Message addSeoTaskRespond.
     * @member {IAddSeoTaskRespond|null|undefined} addSeoTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.addSeoTaskRespond = null;

    /**
     * Message removeSeoTasksRequest.
     * @member {IRemoveSeoTasksRequest|null|undefined} removeSeoTasksRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.removeSeoTasksRequest = null;

    /**
     * Message removeSeoTasksRespond.
     * @member {IRemoveSeoTasksRespond|null|undefined} removeSeoTasksRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.removeSeoTasksRespond = null;

    /**
     * Message selectSeoTaskRequest.
     * @member {ISelectSeoTaskRequest|null|undefined} selectSeoTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.selectSeoTaskRequest = null;

    /**
     * Message selectSeoTaskRespond.
     * @member {ISelectSeoTaskRespond|null|undefined} selectSeoTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.selectSeoTaskRespond = null;

    /**
     * Message updateSeoTaskRequest.
     * @member {IUpdateSeoTaskRequest|null|undefined} updateSeoTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.updateSeoTaskRequest = null;

    /**
     * Message updateSeoTaskRespond.
     * @member {IUpdateSeoTaskRespond|null|undefined} updateSeoTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.updateSeoTaskRespond = null;

    /**
     * Message startSeoTaskCommand.
     * @member {IStartSeoTaskCommand|null|undefined} startSeoTaskCommand
     * @memberof Message
     * @instance
     */
    Message.prototype.startSeoTaskCommand = null;

    /**
     * Message selectIdleWorkerRequest.
     * @member {ISelectIdleWorkerRequest|null|undefined} selectIdleWorkerRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.selectIdleWorkerRequest = null;

    /**
     * Message selectIdleWorkerRespond.
     * @member {ISelectIdleWorkderRespond|null|undefined} selectIdleWorkerRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.selectIdleWorkerRespond = null;

    /**
     * Message getSeoTaskRequest.
     * @member {IGetSeoTaskRequest|null|undefined} getSeoTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.getSeoTaskRequest = null;

    /**
     * Message getSeoTaskRespond.
     * @member {IGetSeoTaskRespond|null|undefined} getSeoTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.getSeoTaskRespond = null;

    /**
     * Message completedSeoTaskNotify.
     * @member {ICompletedSeoTaskNotify|null|undefined} completedSeoTaskNotify
     * @memberof Message
     * @instance
     */
    Message.prototype.completedSeoTaskNotify = null;

    /**
     * Message subscribeSeoTaskRequest.
     * @member {ISubscribeSeoTaskRequest|null|undefined} subscribeSeoTaskRequest
     * @memberof Message
     * @instance
     */
    Message.prototype.subscribeSeoTaskRequest = null;

    /**
     * Message subscribeSeoTaskRespond.
     * @member {ISubscribeSeoTaskRespond|null|undefined} subscribeSeoTaskRespond
     * @memberof Message
     * @instance
     */
    Message.prototype.subscribeSeoTaskRespond = null;

    /**
     * Message updateSeoTaskNotify.
     * @member {IUpdateSeoTaskNotify|null|undefined} updateSeoTaskNotify
     * @memberof Message
     * @instance
     */
    Message.prototype.updateSeoTaskNotify = null;

    /**
     * Message seoTaskFailNotify.
     * @member {ISeoTaskFailNotify|null|undefined} seoTaskFailNotify
     * @memberof Message
     * @instance
     */
    Message.prototype.seoTaskFailNotify = null;

    /**
     * Message cancelSeoTaskCommand.
     * @member {ICancelSeoTaskCommand|null|undefined} cancelSeoTaskCommand
     * @memberof Message
     * @instance
     */
    Message.prototype.cancelSeoTaskCommand = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * Message Data.
     * @member {"loginRequest"|"loginRespond"|"getCodeRequest"|"getCodeRespond"|"updateWorkerStateRequest"|"interruptRequest"|"executeRequest"|"isUseRemoteCodeProviderRequest"|"ping"|"logRequest"|"isUseRemoteLogRequest"|"addLogObserverRequest"|"removeLogObserverRequest"|"setDebuggerRequest"|"resetDebuggerRequest"|"getWorkerInfoRequest"|"getWorkerInfoRespond"|"screenShotRequest"|"screenShotRespond"|"getResourceRequest"|"getResourceRespond"|"readSettingRequest"|"readSettingRespond"|"writeSettingRequest"|"recognizeRequest"|"recognizeRespond"|"getTaskRequest"|"getTaskRespond"|"executeTaskRequest"|"publishMessageRequest"|"subscribeMessageRequest"|"removeMessageRequest"|"subscribeMessageRespond"|"unsubscribeMessageRequest"|"useLatestVerifyDataRequest"|"logoutRequest"|"observeWorkerRequest"|"observeWorkerRespond"|"remoteCallRequest"|"remoteCallRespond"|"startTaskRequest"|"startTaskRespond"|"addSeoTaskRequest"|"addSeoTaskRespond"|"removeSeoTasksRequest"|"removeSeoTasksRespond"|"selectSeoTaskRequest"|"selectSeoTaskRespond"|"updateSeoTaskRequest"|"updateSeoTaskRespond"|"startSeoTaskCommand"|"selectIdleWorkerRequest"|"selectIdleWorkerRespond"|"getSeoTaskRequest"|"getSeoTaskRespond"|"completedSeoTaskNotify"|"subscribeSeoTaskRequest"|"subscribeSeoTaskRespond"|"updateSeoTaskNotify"|"seoTaskFailNotify"|"cancelSeoTaskCommand"|undefined} Data
     * @memberof Message
     * @instance
     */
    Object.defineProperty(Message.prototype, "Data", {
        get: $util.oneOfGetter($oneOfFields = ["loginRequest", "loginRespond", "getCodeRequest", "getCodeRespond", "updateWorkerStateRequest", "interruptRequest", "executeRequest", "isUseRemoteCodeProviderRequest", "ping", "logRequest", "isUseRemoteLogRequest", "addLogObserverRequest", "removeLogObserverRequest", "setDebuggerRequest", "resetDebuggerRequest", "getWorkerInfoRequest", "getWorkerInfoRespond", "screenShotRequest", "screenShotRespond", "getResourceRequest", "getResourceRespond", "readSettingRequest", "readSettingRespond", "writeSettingRequest", "recognizeRequest", "recognizeRespond", "getTaskRequest", "getTaskRespond", "executeTaskRequest", "publishMessageRequest", "subscribeMessageRequest", "removeMessageRequest", "subscribeMessageRespond", "unsubscribeMessageRequest", "useLatestVerifyDataRequest", "logoutRequest", "observeWorkerRequest", "observeWorkerRespond", "remoteCallRequest", "remoteCallRespond", "startTaskRequest", "startTaskRespond", "addSeoTaskRequest", "addSeoTaskRespond", "removeSeoTasksRequest", "removeSeoTasksRespond", "selectSeoTaskRequest", "selectSeoTaskRespond", "updateSeoTaskRequest", "updateSeoTaskRespond", "startSeoTaskCommand", "selectIdleWorkerRequest", "selectIdleWorkerRespond", "getSeoTaskRequest", "getSeoTaskRespond", "completedSeoTaskNotify", "subscribeSeoTaskRequest", "subscribeSeoTaskRespond", "updateSeoTaskNotify", "seoTaskFailNotify", "cancelSeoTaskCommand"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new Message instance using the specified properties.
     * @function create
     * @memberof Message
     * @static
     * @param {IMessage=} [properties] Properties to set
     * @returns {Message} Message instance
     */
    Message.create = function create(properties) {
        return new Message(properties);
    };

    /**
     * Encodes the specified Message message. Does not implicitly {@link Message.verify|verify} messages.
     * @function encode
     * @memberof Message
     * @static
     * @param {IMessage} message Message message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Message.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.loginRequest != null && Object.hasOwnProperty.call(message, "loginRequest"))
            $root.LoginRequest.encode(message.loginRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.loginRespond != null && Object.hasOwnProperty.call(message, "loginRespond"))
            $root.LoginRespond.encode(message.loginRespond, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.getCodeRequest != null && Object.hasOwnProperty.call(message, "getCodeRequest"))
            $root.GetCodeRequest.encode(message.getCodeRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.getCodeRespond != null && Object.hasOwnProperty.call(message, "getCodeRespond"))
            $root.GetCodeRespond.encode(message.getCodeRespond, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.updateWorkerStateRequest != null && Object.hasOwnProperty.call(message, "updateWorkerStateRequest"))
            $root.UpdateWorkerStateRequest.encode(message.updateWorkerStateRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.interruptRequest != null && Object.hasOwnProperty.call(message, "interruptRequest"))
            $root.InterruptRequest.encode(message.interruptRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.executeRequest != null && Object.hasOwnProperty.call(message, "executeRequest"))
            $root.ExecuteRequest.encode(message.executeRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.isUseRemoteCodeProviderRequest != null && Object.hasOwnProperty.call(message, "isUseRemoteCodeProviderRequest"))
            $root.IsUseRemoteCodeProviderRequest.encode(message.isUseRemoteCodeProviderRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
            $root.Ping.encode(message.ping, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.logRequest != null && Object.hasOwnProperty.call(message, "logRequest"))
            $root.LogRequest.encode(message.logRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.isUseRemoteLogRequest != null && Object.hasOwnProperty.call(message, "isUseRemoteLogRequest"))
            $root.IsUseRemoteLogRequest.encode(message.isUseRemoteLogRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.addLogObserverRequest != null && Object.hasOwnProperty.call(message, "addLogObserverRequest"))
            $root.AddLogObserverRequest.encode(message.addLogObserverRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.removeLogObserverRequest != null && Object.hasOwnProperty.call(message, "removeLogObserverRequest"))
            $root.RemoveLogObserverRequest.encode(message.removeLogObserverRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.setDebuggerRequest != null && Object.hasOwnProperty.call(message, "setDebuggerRequest"))
            $root.SetDebuggerRequest.encode(message.setDebuggerRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        if (message.resetDebuggerRequest != null && Object.hasOwnProperty.call(message, "resetDebuggerRequest"))
            $root.ResetDebuggerRequest.encode(message.resetDebuggerRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.getWorkerInfoRequest != null && Object.hasOwnProperty.call(message, "getWorkerInfoRequest"))
            $root.GetWorkerInfoRequest.encode(message.getWorkerInfoRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.getWorkerInfoRespond != null && Object.hasOwnProperty.call(message, "getWorkerInfoRespond"))
            $root.GetWorkerInfoRespond.encode(message.getWorkerInfoRespond, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
        if (message.screenShotRequest != null && Object.hasOwnProperty.call(message, "screenShotRequest"))
            $root.ScreenShotRequest.encode(message.screenShotRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.screenShotRespond != null && Object.hasOwnProperty.call(message, "screenShotRespond"))
            $root.ScreenShotRespond.encode(message.screenShotRespond, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.getResourceRequest != null && Object.hasOwnProperty.call(message, "getResourceRequest"))
            $root.GetResourceRequest.encode(message.getResourceRequest, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.getResourceRespond != null && Object.hasOwnProperty.call(message, "getResourceRespond"))
            $root.GetResourceRespond.encode(message.getResourceRespond, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
        if (message.readSettingRequest != null && Object.hasOwnProperty.call(message, "readSettingRequest"))
            $root.ReadSettingRequest.encode(message.readSettingRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.readSettingRespond != null && Object.hasOwnProperty.call(message, "readSettingRespond"))
            $root.ReadSettingRespond.encode(message.readSettingRespond, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        if (message.writeSettingRequest != null && Object.hasOwnProperty.call(message, "writeSettingRequest"))
            $root.WriteSettingRequest.encode(message.writeSettingRequest, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
        if (message.recognizeRequest != null && Object.hasOwnProperty.call(message, "recognizeRequest"))
            $root.RecognizeRequest.encode(message.recognizeRequest, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
        if (message.recognizeRespond != null && Object.hasOwnProperty.call(message, "recognizeRespond"))
            $root.RecognizeRespond.encode(message.recognizeRespond, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
        if (message.getTaskRequest != null && Object.hasOwnProperty.call(message, "getTaskRequest"))
            $root.GetTaskRequest.encode(message.getTaskRequest, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
        if (message.getTaskRespond != null && Object.hasOwnProperty.call(message, "getTaskRespond"))
            $root.GetTaskRespond.encode(message.getTaskRespond, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.executeTaskRequest != null && Object.hasOwnProperty.call(message, "executeTaskRequest"))
            $root.ExecuteTaskRequest.encode(message.executeTaskRequest, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.publishMessageRequest != null && Object.hasOwnProperty.call(message, "publishMessageRequest"))
            $root.PublishMessageRequest.encode(message.publishMessageRequest, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
        if (message.subscribeMessageRequest != null && Object.hasOwnProperty.call(message, "subscribeMessageRequest"))
            $root.SubscribeMessageRequest.encode(message.subscribeMessageRequest, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
        if (message.removeMessageRequest != null && Object.hasOwnProperty.call(message, "removeMessageRequest"))
            $root.RemoveMessageRequest.encode(message.removeMessageRequest, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
        if (message.subscribeMessageRespond != null && Object.hasOwnProperty.call(message, "subscribeMessageRespond"))
            $root.SubscribeMessageRespond.encode(message.subscribeMessageRespond, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
        if (message.unsubscribeMessageRequest != null && Object.hasOwnProperty.call(message, "unsubscribeMessageRequest"))
            $root.UnsubscribeMessageRequest.encode(message.unsubscribeMessageRequest, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.useLatestVerifyDataRequest != null && Object.hasOwnProperty.call(message, "useLatestVerifyDataRequest"))
            $root.UseLatestVerifyDataRequest.encode(message.useLatestVerifyDataRequest, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
        if (message.logoutRequest != null && Object.hasOwnProperty.call(message, "logoutRequest"))
            $root.LogoutRequest.encode(message.logoutRequest, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
        if (message.remoteCallRequest != null && Object.hasOwnProperty.call(message, "remoteCallRequest"))
            $root.RemoteCallRequest.encode(message.remoteCallRequest, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
        if (message.remoteCallRespond != null && Object.hasOwnProperty.call(message, "remoteCallRespond"))
            $root.RemoteCallRespond.encode(message.remoteCallRespond, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        if (message.startTaskRequest != null && Object.hasOwnProperty.call(message, "startTaskRequest"))
            $root.StartTaskRequest.encode(message.startTaskRequest, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
        if (message.startTaskRespond != null && Object.hasOwnProperty.call(message, "startTaskRespond"))
            $root.StartTaskRespond.encode(message.startTaskRespond, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
        if (message.addSeoTaskRequest != null && Object.hasOwnProperty.call(message, "addSeoTaskRequest"))
            $root.AddSeoTaskRequest.encode(message.addSeoTaskRequest, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
        if (message.addSeoTaskRespond != null && Object.hasOwnProperty.call(message, "addSeoTaskRespond"))
            $root.AddSeoTaskRespond.encode(message.addSeoTaskRespond, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
        if (message.removeSeoTasksRequest != null && Object.hasOwnProperty.call(message, "removeSeoTasksRequest"))
            $root.RemoveSeoTasksRequest.encode(message.removeSeoTasksRequest, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
        if (message.removeSeoTasksRespond != null && Object.hasOwnProperty.call(message, "removeSeoTasksRespond"))
            $root.RemoveSeoTasksRespond.encode(message.removeSeoTasksRespond, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
        if (message.selectSeoTaskRequest != null && Object.hasOwnProperty.call(message, "selectSeoTaskRequest"))
            $root.SelectSeoTaskRequest.encode(message.selectSeoTaskRequest, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
        if (message.selectSeoTaskRespond != null && Object.hasOwnProperty.call(message, "selectSeoTaskRespond"))
            $root.SelectSeoTaskRespond.encode(message.selectSeoTaskRespond, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
        if (message.updateSeoTaskRequest != null && Object.hasOwnProperty.call(message, "updateSeoTaskRequest"))
            $root.UpdateSeoTaskRequest.encode(message.updateSeoTaskRequest, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
        if (message.updateSeoTaskRespond != null && Object.hasOwnProperty.call(message, "updateSeoTaskRespond"))
            $root.UpdateSeoTaskRespond.encode(message.updateSeoTaskRespond, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        if (message.startSeoTaskCommand != null && Object.hasOwnProperty.call(message, "startSeoTaskCommand"))
            $root.StartSeoTaskCommand.encode(message.startSeoTaskCommand, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
        if (message.selectIdleWorkerRequest != null && Object.hasOwnProperty.call(message, "selectIdleWorkerRequest"))
            $root.SelectIdleWorkerRequest.encode(message.selectIdleWorkerRequest, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
        if (message.selectIdleWorkerRespond != null && Object.hasOwnProperty.call(message, "selectIdleWorkerRespond"))
            $root.SelectIdleWorkderRespond.encode(message.selectIdleWorkerRespond, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
        if (message.observeWorkerRequest != null && Object.hasOwnProperty.call(message, "observeWorkerRequest"))
            $root.ObserveWorkerRequest.encode(message.observeWorkerRequest, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
        if (message.observeWorkerRespond != null && Object.hasOwnProperty.call(message, "observeWorkerRespond"))
            $root.ObserveWorkerRespond.encode(message.observeWorkerRespond, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
        if (message.getSeoTaskRequest != null && Object.hasOwnProperty.call(message, "getSeoTaskRequest"))
            $root.GetSeoTaskRequest.encode(message.getSeoTaskRequest, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
        if (message.getSeoTaskRespond != null && Object.hasOwnProperty.call(message, "getSeoTaskRespond"))
            $root.GetSeoTaskRespond.encode(message.getSeoTaskRespond, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
        if (message.completedSeoTaskNotify != null && Object.hasOwnProperty.call(message, "completedSeoTaskNotify"))
            $root.CompletedSeoTaskNotify.encode(message.completedSeoTaskNotify, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
        if (message.subscribeSeoTaskRequest != null && Object.hasOwnProperty.call(message, "subscribeSeoTaskRequest"))
            $root.SubscribeSeoTaskRequest.encode(message.subscribeSeoTaskRequest, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
        if (message.subscribeSeoTaskRespond != null && Object.hasOwnProperty.call(message, "subscribeSeoTaskRespond"))
            $root.SubscribeSeoTaskRespond.encode(message.subscribeSeoTaskRespond, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
        if (message.updateSeoTaskNotify != null && Object.hasOwnProperty.call(message, "updateSeoTaskNotify"))
            $root.UpdateSeoTaskNotify.encode(message.updateSeoTaskNotify, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
        if (message.seoTaskFailNotify != null && Object.hasOwnProperty.call(message, "seoTaskFailNotify"))
            $root.SeoTaskFailNotify.encode(message.seoTaskFailNotify, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
        if (message.cancelSeoTaskCommand != null && Object.hasOwnProperty.call(message, "cancelSeoTaskCommand"))
            $root.CancelSeoTaskCommand.encode(message.cancelSeoTaskCommand, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Message message, length delimited. Does not implicitly {@link Message.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Message
     * @static
     * @param {IMessage} message Message message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Message.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Message message from the specified reader or buffer.
     * @function decode
     * @memberof Message
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Message} Message
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Message.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Message();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.loginRequest = $root.LoginRequest.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.loginRespond = $root.LoginRespond.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.getCodeRequest = $root.GetCodeRequest.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.getCodeRespond = $root.GetCodeRespond.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.updateWorkerStateRequest = $root.UpdateWorkerStateRequest.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.interruptRequest = $root.InterruptRequest.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.executeRequest = $root.ExecuteRequest.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.isUseRemoteCodeProviderRequest = $root.IsUseRemoteCodeProviderRequest.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.ping = $root.Ping.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.logRequest = $root.LogRequest.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.isUseRemoteLogRequest = $root.IsUseRemoteLogRequest.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.addLogObserverRequest = $root.AddLogObserverRequest.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.removeLogObserverRequest = $root.RemoveLogObserverRequest.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.setDebuggerRequest = $root.SetDebuggerRequest.decode(reader, reader.uint32());
                    break;
                }
            case 17: {
                    message.resetDebuggerRequest = $root.ResetDebuggerRequest.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.getWorkerInfoRequest = $root.GetWorkerInfoRequest.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    message.getWorkerInfoRespond = $root.GetWorkerInfoRespond.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.screenShotRequest = $root.ScreenShotRequest.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.screenShotRespond = $root.ScreenShotRespond.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.getResourceRequest = $root.GetResourceRequest.decode(reader, reader.uint32());
                    break;
                }
            case 23: {
                    message.getResourceRespond = $root.GetResourceRespond.decode(reader, reader.uint32());
                    break;
                }
            case 24: {
                    message.readSettingRequest = $root.ReadSettingRequest.decode(reader, reader.uint32());
                    break;
                }
            case 25: {
                    message.readSettingRespond = $root.ReadSettingRespond.decode(reader, reader.uint32());
                    break;
                }
            case 26: {
                    message.writeSettingRequest = $root.WriteSettingRequest.decode(reader, reader.uint32());
                    break;
                }
            case 27: {
                    message.recognizeRequest = $root.RecognizeRequest.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    message.recognizeRespond = $root.RecognizeRespond.decode(reader, reader.uint32());
                    break;
                }
            case 29: {
                    message.getTaskRequest = $root.GetTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.getTaskRespond = $root.GetTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 31: {
                    message.executeTaskRequest = $root.ExecuteTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.publishMessageRequest = $root.PublishMessageRequest.decode(reader, reader.uint32());
                    break;
                }
            case 33: {
                    message.subscribeMessageRequest = $root.SubscribeMessageRequest.decode(reader, reader.uint32());
                    break;
                }
            case 34: {
                    message.removeMessageRequest = $root.RemoveMessageRequest.decode(reader, reader.uint32());
                    break;
                }
            case 35: {
                    message.subscribeMessageRespond = $root.SubscribeMessageRespond.decode(reader, reader.uint32());
                    break;
                }
            case 36: {
                    message.unsubscribeMessageRequest = $root.UnsubscribeMessageRequest.decode(reader, reader.uint32());
                    break;
                }
            case 37: {
                    message.useLatestVerifyDataRequest = $root.UseLatestVerifyDataRequest.decode(reader, reader.uint32());
                    break;
                }
            case 38: {
                    message.logoutRequest = $root.LogoutRequest.decode(reader, reader.uint32());
                    break;
                }
            case 54: {
                    message.observeWorkerRequest = $root.ObserveWorkerRequest.decode(reader, reader.uint32());
                    break;
                }
            case 55: {
                    message.observeWorkerRespond = $root.ObserveWorkerRespond.decode(reader, reader.uint32());
                    break;
                }
            case 39: {
                    message.remoteCallRequest = $root.RemoteCallRequest.decode(reader, reader.uint32());
                    break;
                }
            case 40: {
                    message.remoteCallRespond = $root.RemoteCallRespond.decode(reader, reader.uint32());
                    break;
                }
            case 41: {
                    message.startTaskRequest = $root.StartTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 42: {
                    message.startTaskRespond = $root.StartTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 43: {
                    message.addSeoTaskRequest = $root.AddSeoTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 44: {
                    message.addSeoTaskRespond = $root.AddSeoTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 45: {
                    message.removeSeoTasksRequest = $root.RemoveSeoTasksRequest.decode(reader, reader.uint32());
                    break;
                }
            case 46: {
                    message.removeSeoTasksRespond = $root.RemoveSeoTasksRespond.decode(reader, reader.uint32());
                    break;
                }
            case 47: {
                    message.selectSeoTaskRequest = $root.SelectSeoTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 48: {
                    message.selectSeoTaskRespond = $root.SelectSeoTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 49: {
                    message.updateSeoTaskRequest = $root.UpdateSeoTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 50: {
                    message.updateSeoTaskRespond = $root.UpdateSeoTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 51: {
                    message.startSeoTaskCommand = $root.StartSeoTaskCommand.decode(reader, reader.uint32());
                    break;
                }
            case 52: {
                    message.selectIdleWorkerRequest = $root.SelectIdleWorkerRequest.decode(reader, reader.uint32());
                    break;
                }
            case 53: {
                    message.selectIdleWorkerRespond = $root.SelectIdleWorkderRespond.decode(reader, reader.uint32());
                    break;
                }
            case 56: {
                    message.getSeoTaskRequest = $root.GetSeoTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 57: {
                    message.getSeoTaskRespond = $root.GetSeoTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 59: {
                    message.completedSeoTaskNotify = $root.CompletedSeoTaskNotify.decode(reader, reader.uint32());
                    break;
                }
            case 62: {
                    message.subscribeSeoTaskRequest = $root.SubscribeSeoTaskRequest.decode(reader, reader.uint32());
                    break;
                }
            case 63: {
                    message.subscribeSeoTaskRespond = $root.SubscribeSeoTaskRespond.decode(reader, reader.uint32());
                    break;
                }
            case 64: {
                    message.updateSeoTaskNotify = $root.UpdateSeoTaskNotify.decode(reader, reader.uint32());
                    break;
                }
            case 65: {
                    message.seoTaskFailNotify = $root.SeoTaskFailNotify.decode(reader, reader.uint32());
                    break;
                }
            case 66: {
                    message.cancelSeoTaskCommand = $root.CancelSeoTaskCommand.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Message message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Message
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Message} Message
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Message.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Message message.
     * @function verify
     * @memberof Message
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Message.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
            properties.Data = 1;
            {
                var error = $root.LoginRequest.verify(message.loginRequest);
                if (error)
                    return "loginRequest." + error;
            }
        }
        if (message.loginRespond != null && message.hasOwnProperty("loginRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.LoginRespond.verify(message.loginRespond);
                if (error)
                    return "loginRespond." + error;
            }
        }
        if (message.getCodeRequest != null && message.hasOwnProperty("getCodeRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetCodeRequest.verify(message.getCodeRequest);
                if (error)
                    return "getCodeRequest." + error;
            }
        }
        if (message.getCodeRespond != null && message.hasOwnProperty("getCodeRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetCodeRespond.verify(message.getCodeRespond);
                if (error)
                    return "getCodeRespond." + error;
            }
        }
        if (message.updateWorkerStateRequest != null && message.hasOwnProperty("updateWorkerStateRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UpdateWorkerStateRequest.verify(message.updateWorkerStateRequest);
                if (error)
                    return "updateWorkerStateRequest." + error;
            }
        }
        if (message.interruptRequest != null && message.hasOwnProperty("interruptRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.InterruptRequest.verify(message.interruptRequest);
                if (error)
                    return "interruptRequest." + error;
            }
        }
        if (message.executeRequest != null && message.hasOwnProperty("executeRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ExecuteRequest.verify(message.executeRequest);
                if (error)
                    return "executeRequest." + error;
            }
        }
        if (message.isUseRemoteCodeProviderRequest != null && message.hasOwnProperty("isUseRemoteCodeProviderRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.IsUseRemoteCodeProviderRequest.verify(message.isUseRemoteCodeProviderRequest);
                if (error)
                    return "isUseRemoteCodeProviderRequest." + error;
            }
        }
        if (message.ping != null && message.hasOwnProperty("ping")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.Ping.verify(message.ping);
                if (error)
                    return "ping." + error;
            }
        }
        if (message.logRequest != null && message.hasOwnProperty("logRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.LogRequest.verify(message.logRequest);
                if (error)
                    return "logRequest." + error;
            }
        }
        if (message.isUseRemoteLogRequest != null && message.hasOwnProperty("isUseRemoteLogRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.IsUseRemoteLogRequest.verify(message.isUseRemoteLogRequest);
                if (error)
                    return "isUseRemoteLogRequest." + error;
            }
        }
        if (message.addLogObserverRequest != null && message.hasOwnProperty("addLogObserverRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.AddLogObserverRequest.verify(message.addLogObserverRequest);
                if (error)
                    return "addLogObserverRequest." + error;
            }
        }
        if (message.removeLogObserverRequest != null && message.hasOwnProperty("removeLogObserverRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoveLogObserverRequest.verify(message.removeLogObserverRequest);
                if (error)
                    return "removeLogObserverRequest." + error;
            }
        }
        if (message.setDebuggerRequest != null && message.hasOwnProperty("setDebuggerRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SetDebuggerRequest.verify(message.setDebuggerRequest);
                if (error)
                    return "setDebuggerRequest." + error;
            }
        }
        if (message.resetDebuggerRequest != null && message.hasOwnProperty("resetDebuggerRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ResetDebuggerRequest.verify(message.resetDebuggerRequest);
                if (error)
                    return "resetDebuggerRequest." + error;
            }
        }
        if (message.getWorkerInfoRequest != null && message.hasOwnProperty("getWorkerInfoRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetWorkerInfoRequest.verify(message.getWorkerInfoRequest);
                if (error)
                    return "getWorkerInfoRequest." + error;
            }
        }
        if (message.getWorkerInfoRespond != null && message.hasOwnProperty("getWorkerInfoRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetWorkerInfoRespond.verify(message.getWorkerInfoRespond);
                if (error)
                    return "getWorkerInfoRespond." + error;
            }
        }
        if (message.screenShotRequest != null && message.hasOwnProperty("screenShotRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ScreenShotRequest.verify(message.screenShotRequest);
                if (error)
                    return "screenShotRequest." + error;
            }
        }
        if (message.screenShotRespond != null && message.hasOwnProperty("screenShotRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ScreenShotRespond.verify(message.screenShotRespond);
                if (error)
                    return "screenShotRespond." + error;
            }
        }
        if (message.getResourceRequest != null && message.hasOwnProperty("getResourceRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetResourceRequest.verify(message.getResourceRequest);
                if (error)
                    return "getResourceRequest." + error;
            }
        }
        if (message.getResourceRespond != null && message.hasOwnProperty("getResourceRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetResourceRespond.verify(message.getResourceRespond);
                if (error)
                    return "getResourceRespond." + error;
            }
        }
        if (message.readSettingRequest != null && message.hasOwnProperty("readSettingRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ReadSettingRequest.verify(message.readSettingRequest);
                if (error)
                    return "readSettingRequest." + error;
            }
        }
        if (message.readSettingRespond != null && message.hasOwnProperty("readSettingRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ReadSettingRespond.verify(message.readSettingRespond);
                if (error)
                    return "readSettingRespond." + error;
            }
        }
        if (message.writeSettingRequest != null && message.hasOwnProperty("writeSettingRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.WriteSettingRequest.verify(message.writeSettingRequest);
                if (error)
                    return "writeSettingRequest." + error;
            }
        }
        if (message.recognizeRequest != null && message.hasOwnProperty("recognizeRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RecognizeRequest.verify(message.recognizeRequest);
                if (error)
                    return "recognizeRequest." + error;
            }
        }
        if (message.recognizeRespond != null && message.hasOwnProperty("recognizeRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RecognizeRespond.verify(message.recognizeRespond);
                if (error)
                    return "recognizeRespond." + error;
            }
        }
        if (message.getTaskRequest != null && message.hasOwnProperty("getTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetTaskRequest.verify(message.getTaskRequest);
                if (error)
                    return "getTaskRequest." + error;
            }
        }
        if (message.getTaskRespond != null && message.hasOwnProperty("getTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetTaskRespond.verify(message.getTaskRespond);
                if (error)
                    return "getTaskRespond." + error;
            }
        }
        if (message.executeTaskRequest != null && message.hasOwnProperty("executeTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ExecuteTaskRequest.verify(message.executeTaskRequest);
                if (error)
                    return "executeTaskRequest." + error;
            }
        }
        if (message.publishMessageRequest != null && message.hasOwnProperty("publishMessageRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.PublishMessageRequest.verify(message.publishMessageRequest);
                if (error)
                    return "publishMessageRequest." + error;
            }
        }
        if (message.subscribeMessageRequest != null && message.hasOwnProperty("subscribeMessageRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SubscribeMessageRequest.verify(message.subscribeMessageRequest);
                if (error)
                    return "subscribeMessageRequest." + error;
            }
        }
        if (message.removeMessageRequest != null && message.hasOwnProperty("removeMessageRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoveMessageRequest.verify(message.removeMessageRequest);
                if (error)
                    return "removeMessageRequest." + error;
            }
        }
        if (message.subscribeMessageRespond != null && message.hasOwnProperty("subscribeMessageRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SubscribeMessageRespond.verify(message.subscribeMessageRespond);
                if (error)
                    return "subscribeMessageRespond." + error;
            }
        }
        if (message.unsubscribeMessageRequest != null && message.hasOwnProperty("unsubscribeMessageRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UnsubscribeMessageRequest.verify(message.unsubscribeMessageRequest);
                if (error)
                    return "unsubscribeMessageRequest." + error;
            }
        }
        if (message.useLatestVerifyDataRequest != null && message.hasOwnProperty("useLatestVerifyDataRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UseLatestVerifyDataRequest.verify(message.useLatestVerifyDataRequest);
                if (error)
                    return "useLatestVerifyDataRequest." + error;
            }
        }
        if (message.logoutRequest != null && message.hasOwnProperty("logoutRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.LogoutRequest.verify(message.logoutRequest);
                if (error)
                    return "logoutRequest." + error;
            }
        }
        if (message.observeWorkerRequest != null && message.hasOwnProperty("observeWorkerRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ObserveWorkerRequest.verify(message.observeWorkerRequest);
                if (error)
                    return "observeWorkerRequest." + error;
            }
        }
        if (message.observeWorkerRespond != null && message.hasOwnProperty("observeWorkerRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.ObserveWorkerRespond.verify(message.observeWorkerRespond);
                if (error)
                    return "observeWorkerRespond." + error;
            }
        }
        if (message.remoteCallRequest != null && message.hasOwnProperty("remoteCallRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoteCallRequest.verify(message.remoteCallRequest);
                if (error)
                    return "remoteCallRequest." + error;
            }
        }
        if (message.remoteCallRespond != null && message.hasOwnProperty("remoteCallRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoteCallRespond.verify(message.remoteCallRespond);
                if (error)
                    return "remoteCallRespond." + error;
            }
        }
        if (message.startTaskRequest != null && message.hasOwnProperty("startTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.StartTaskRequest.verify(message.startTaskRequest);
                if (error)
                    return "startTaskRequest." + error;
            }
        }
        if (message.startTaskRespond != null && message.hasOwnProperty("startTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.StartTaskRespond.verify(message.startTaskRespond);
                if (error)
                    return "startTaskRespond." + error;
            }
        }
        if (message.addSeoTaskRequest != null && message.hasOwnProperty("addSeoTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.AddSeoTaskRequest.verify(message.addSeoTaskRequest);
                if (error)
                    return "addSeoTaskRequest." + error;
            }
        }
        if (message.addSeoTaskRespond != null && message.hasOwnProperty("addSeoTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.AddSeoTaskRespond.verify(message.addSeoTaskRespond);
                if (error)
                    return "addSeoTaskRespond." + error;
            }
        }
        if (message.removeSeoTasksRequest != null && message.hasOwnProperty("removeSeoTasksRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoveSeoTasksRequest.verify(message.removeSeoTasksRequest);
                if (error)
                    return "removeSeoTasksRequest." + error;
            }
        }
        if (message.removeSeoTasksRespond != null && message.hasOwnProperty("removeSeoTasksRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.RemoveSeoTasksRespond.verify(message.removeSeoTasksRespond);
                if (error)
                    return "removeSeoTasksRespond." + error;
            }
        }
        if (message.selectSeoTaskRequest != null && message.hasOwnProperty("selectSeoTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SelectSeoTaskRequest.verify(message.selectSeoTaskRequest);
                if (error)
                    return "selectSeoTaskRequest." + error;
            }
        }
        if (message.selectSeoTaskRespond != null && message.hasOwnProperty("selectSeoTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SelectSeoTaskRespond.verify(message.selectSeoTaskRespond);
                if (error)
                    return "selectSeoTaskRespond." + error;
            }
        }
        if (message.updateSeoTaskRequest != null && message.hasOwnProperty("updateSeoTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UpdateSeoTaskRequest.verify(message.updateSeoTaskRequest);
                if (error)
                    return "updateSeoTaskRequest." + error;
            }
        }
        if (message.updateSeoTaskRespond != null && message.hasOwnProperty("updateSeoTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UpdateSeoTaskRespond.verify(message.updateSeoTaskRespond);
                if (error)
                    return "updateSeoTaskRespond." + error;
            }
        }
        if (message.startSeoTaskCommand != null && message.hasOwnProperty("startSeoTaskCommand")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.StartSeoTaskCommand.verify(message.startSeoTaskCommand);
                if (error)
                    return "startSeoTaskCommand." + error;
            }
        }
        if (message.selectIdleWorkerRequest != null && message.hasOwnProperty("selectIdleWorkerRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SelectIdleWorkerRequest.verify(message.selectIdleWorkerRequest);
                if (error)
                    return "selectIdleWorkerRequest." + error;
            }
        }
        if (message.selectIdleWorkerRespond != null && message.hasOwnProperty("selectIdleWorkerRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SelectIdleWorkderRespond.verify(message.selectIdleWorkerRespond);
                if (error)
                    return "selectIdleWorkerRespond." + error;
            }
        }
        if (message.getSeoTaskRequest != null && message.hasOwnProperty("getSeoTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetSeoTaskRequest.verify(message.getSeoTaskRequest);
                if (error)
                    return "getSeoTaskRequest." + error;
            }
        }
        if (message.getSeoTaskRespond != null && message.hasOwnProperty("getSeoTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.GetSeoTaskRespond.verify(message.getSeoTaskRespond);
                if (error)
                    return "getSeoTaskRespond." + error;
            }
        }
        if (message.completedSeoTaskNotify != null && message.hasOwnProperty("completedSeoTaskNotify")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.CompletedSeoTaskNotify.verify(message.completedSeoTaskNotify);
                if (error)
                    return "completedSeoTaskNotify." + error;
            }
        }
        if (message.subscribeSeoTaskRequest != null && message.hasOwnProperty("subscribeSeoTaskRequest")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SubscribeSeoTaskRequest.verify(message.subscribeSeoTaskRequest);
                if (error)
                    return "subscribeSeoTaskRequest." + error;
            }
        }
        if (message.subscribeSeoTaskRespond != null && message.hasOwnProperty("subscribeSeoTaskRespond")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SubscribeSeoTaskRespond.verify(message.subscribeSeoTaskRespond);
                if (error)
                    return "subscribeSeoTaskRespond." + error;
            }
        }
        if (message.updateSeoTaskNotify != null && message.hasOwnProperty("updateSeoTaskNotify")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.UpdateSeoTaskNotify.verify(message.updateSeoTaskNotify);
                if (error)
                    return "updateSeoTaskNotify." + error;
            }
        }
        if (message.seoTaskFailNotify != null && message.hasOwnProperty("seoTaskFailNotify")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.SeoTaskFailNotify.verify(message.seoTaskFailNotify);
                if (error)
                    return "seoTaskFailNotify." + error;
            }
        }
        if (message.cancelSeoTaskCommand != null && message.hasOwnProperty("cancelSeoTaskCommand")) {
            if (properties.Data === 1)
                return "Data: multiple values";
            properties.Data = 1;
            {
                var error = $root.CancelSeoTaskCommand.verify(message.cancelSeoTaskCommand);
                if (error)
                    return "cancelSeoTaskCommand." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Message message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Message
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Message} Message
     */
    Message.fromObject = function fromObject(object) {
        if (object instanceof $root.Message)
            return object;
        var message = new $root.Message();
        if (object.loginRequest != null) {
            if (typeof object.loginRequest !== "object")
                throw TypeError(".Message.loginRequest: object expected");
            message.loginRequest = $root.LoginRequest.fromObject(object.loginRequest);
        }
        if (object.loginRespond != null) {
            if (typeof object.loginRespond !== "object")
                throw TypeError(".Message.loginRespond: object expected");
            message.loginRespond = $root.LoginRespond.fromObject(object.loginRespond);
        }
        if (object.getCodeRequest != null) {
            if (typeof object.getCodeRequest !== "object")
                throw TypeError(".Message.getCodeRequest: object expected");
            message.getCodeRequest = $root.GetCodeRequest.fromObject(object.getCodeRequest);
        }
        if (object.getCodeRespond != null) {
            if (typeof object.getCodeRespond !== "object")
                throw TypeError(".Message.getCodeRespond: object expected");
            message.getCodeRespond = $root.GetCodeRespond.fromObject(object.getCodeRespond);
        }
        if (object.updateWorkerStateRequest != null) {
            if (typeof object.updateWorkerStateRequest !== "object")
                throw TypeError(".Message.updateWorkerStateRequest: object expected");
            message.updateWorkerStateRequest = $root.UpdateWorkerStateRequest.fromObject(object.updateWorkerStateRequest);
        }
        if (object.interruptRequest != null) {
            if (typeof object.interruptRequest !== "object")
                throw TypeError(".Message.interruptRequest: object expected");
            message.interruptRequest = $root.InterruptRequest.fromObject(object.interruptRequest);
        }
        if (object.executeRequest != null) {
            if (typeof object.executeRequest !== "object")
                throw TypeError(".Message.executeRequest: object expected");
            message.executeRequest = $root.ExecuteRequest.fromObject(object.executeRequest);
        }
        if (object.isUseRemoteCodeProviderRequest != null) {
            if (typeof object.isUseRemoteCodeProviderRequest !== "object")
                throw TypeError(".Message.isUseRemoteCodeProviderRequest: object expected");
            message.isUseRemoteCodeProviderRequest = $root.IsUseRemoteCodeProviderRequest.fromObject(object.isUseRemoteCodeProviderRequest);
        }
        if (object.ping != null) {
            if (typeof object.ping !== "object")
                throw TypeError(".Message.ping: object expected");
            message.ping = $root.Ping.fromObject(object.ping);
        }
        if (object.logRequest != null) {
            if (typeof object.logRequest !== "object")
                throw TypeError(".Message.logRequest: object expected");
            message.logRequest = $root.LogRequest.fromObject(object.logRequest);
        }
        if (object.isUseRemoteLogRequest != null) {
            if (typeof object.isUseRemoteLogRequest !== "object")
                throw TypeError(".Message.isUseRemoteLogRequest: object expected");
            message.isUseRemoteLogRequest = $root.IsUseRemoteLogRequest.fromObject(object.isUseRemoteLogRequest);
        }
        if (object.addLogObserverRequest != null) {
            if (typeof object.addLogObserverRequest !== "object")
                throw TypeError(".Message.addLogObserverRequest: object expected");
            message.addLogObserverRequest = $root.AddLogObserverRequest.fromObject(object.addLogObserverRequest);
        }
        if (object.removeLogObserverRequest != null) {
            if (typeof object.removeLogObserverRequest !== "object")
                throw TypeError(".Message.removeLogObserverRequest: object expected");
            message.removeLogObserverRequest = $root.RemoveLogObserverRequest.fromObject(object.removeLogObserverRequest);
        }
        if (object.setDebuggerRequest != null) {
            if (typeof object.setDebuggerRequest !== "object")
                throw TypeError(".Message.setDebuggerRequest: object expected");
            message.setDebuggerRequest = $root.SetDebuggerRequest.fromObject(object.setDebuggerRequest);
        }
        if (object.resetDebuggerRequest != null) {
            if (typeof object.resetDebuggerRequest !== "object")
                throw TypeError(".Message.resetDebuggerRequest: object expected");
            message.resetDebuggerRequest = $root.ResetDebuggerRequest.fromObject(object.resetDebuggerRequest);
        }
        if (object.getWorkerInfoRequest != null) {
            if (typeof object.getWorkerInfoRequest !== "object")
                throw TypeError(".Message.getWorkerInfoRequest: object expected");
            message.getWorkerInfoRequest = $root.GetWorkerInfoRequest.fromObject(object.getWorkerInfoRequest);
        }
        if (object.getWorkerInfoRespond != null) {
            if (typeof object.getWorkerInfoRespond !== "object")
                throw TypeError(".Message.getWorkerInfoRespond: object expected");
            message.getWorkerInfoRespond = $root.GetWorkerInfoRespond.fromObject(object.getWorkerInfoRespond);
        }
        if (object.screenShotRequest != null) {
            if (typeof object.screenShotRequest !== "object")
                throw TypeError(".Message.screenShotRequest: object expected");
            message.screenShotRequest = $root.ScreenShotRequest.fromObject(object.screenShotRequest);
        }
        if (object.screenShotRespond != null) {
            if (typeof object.screenShotRespond !== "object")
                throw TypeError(".Message.screenShotRespond: object expected");
            message.screenShotRespond = $root.ScreenShotRespond.fromObject(object.screenShotRespond);
        }
        if (object.getResourceRequest != null) {
            if (typeof object.getResourceRequest !== "object")
                throw TypeError(".Message.getResourceRequest: object expected");
            message.getResourceRequest = $root.GetResourceRequest.fromObject(object.getResourceRequest);
        }
        if (object.getResourceRespond != null) {
            if (typeof object.getResourceRespond !== "object")
                throw TypeError(".Message.getResourceRespond: object expected");
            message.getResourceRespond = $root.GetResourceRespond.fromObject(object.getResourceRespond);
        }
        if (object.readSettingRequest != null) {
            if (typeof object.readSettingRequest !== "object")
                throw TypeError(".Message.readSettingRequest: object expected");
            message.readSettingRequest = $root.ReadSettingRequest.fromObject(object.readSettingRequest);
        }
        if (object.readSettingRespond != null) {
            if (typeof object.readSettingRespond !== "object")
                throw TypeError(".Message.readSettingRespond: object expected");
            message.readSettingRespond = $root.ReadSettingRespond.fromObject(object.readSettingRespond);
        }
        if (object.writeSettingRequest != null) {
            if (typeof object.writeSettingRequest !== "object")
                throw TypeError(".Message.writeSettingRequest: object expected");
            message.writeSettingRequest = $root.WriteSettingRequest.fromObject(object.writeSettingRequest);
        }
        if (object.recognizeRequest != null) {
            if (typeof object.recognizeRequest !== "object")
                throw TypeError(".Message.recognizeRequest: object expected");
            message.recognizeRequest = $root.RecognizeRequest.fromObject(object.recognizeRequest);
        }
        if (object.recognizeRespond != null) {
            if (typeof object.recognizeRespond !== "object")
                throw TypeError(".Message.recognizeRespond: object expected");
            message.recognizeRespond = $root.RecognizeRespond.fromObject(object.recognizeRespond);
        }
        if (object.getTaskRequest != null) {
            if (typeof object.getTaskRequest !== "object")
                throw TypeError(".Message.getTaskRequest: object expected");
            message.getTaskRequest = $root.GetTaskRequest.fromObject(object.getTaskRequest);
        }
        if (object.getTaskRespond != null) {
            if (typeof object.getTaskRespond !== "object")
                throw TypeError(".Message.getTaskRespond: object expected");
            message.getTaskRespond = $root.GetTaskRespond.fromObject(object.getTaskRespond);
        }
        if (object.executeTaskRequest != null) {
            if (typeof object.executeTaskRequest !== "object")
                throw TypeError(".Message.executeTaskRequest: object expected");
            message.executeTaskRequest = $root.ExecuteTaskRequest.fromObject(object.executeTaskRequest);
        }
        if (object.publishMessageRequest != null) {
            if (typeof object.publishMessageRequest !== "object")
                throw TypeError(".Message.publishMessageRequest: object expected");
            message.publishMessageRequest = $root.PublishMessageRequest.fromObject(object.publishMessageRequest);
        }
        if (object.subscribeMessageRequest != null) {
            if (typeof object.subscribeMessageRequest !== "object")
                throw TypeError(".Message.subscribeMessageRequest: object expected");
            message.subscribeMessageRequest = $root.SubscribeMessageRequest.fromObject(object.subscribeMessageRequest);
        }
        if (object.removeMessageRequest != null) {
            if (typeof object.removeMessageRequest !== "object")
                throw TypeError(".Message.removeMessageRequest: object expected");
            message.removeMessageRequest = $root.RemoveMessageRequest.fromObject(object.removeMessageRequest);
        }
        if (object.subscribeMessageRespond != null) {
            if (typeof object.subscribeMessageRespond !== "object")
                throw TypeError(".Message.subscribeMessageRespond: object expected");
            message.subscribeMessageRespond = $root.SubscribeMessageRespond.fromObject(object.subscribeMessageRespond);
        }
        if (object.unsubscribeMessageRequest != null) {
            if (typeof object.unsubscribeMessageRequest !== "object")
                throw TypeError(".Message.unsubscribeMessageRequest: object expected");
            message.unsubscribeMessageRequest = $root.UnsubscribeMessageRequest.fromObject(object.unsubscribeMessageRequest);
        }
        if (object.useLatestVerifyDataRequest != null) {
            if (typeof object.useLatestVerifyDataRequest !== "object")
                throw TypeError(".Message.useLatestVerifyDataRequest: object expected");
            message.useLatestVerifyDataRequest = $root.UseLatestVerifyDataRequest.fromObject(object.useLatestVerifyDataRequest);
        }
        if (object.logoutRequest != null) {
            if (typeof object.logoutRequest !== "object")
                throw TypeError(".Message.logoutRequest: object expected");
            message.logoutRequest = $root.LogoutRequest.fromObject(object.logoutRequest);
        }
        if (object.observeWorkerRequest != null) {
            if (typeof object.observeWorkerRequest !== "object")
                throw TypeError(".Message.observeWorkerRequest: object expected");
            message.observeWorkerRequest = $root.ObserveWorkerRequest.fromObject(object.observeWorkerRequest);
        }
        if (object.observeWorkerRespond != null) {
            if (typeof object.observeWorkerRespond !== "object")
                throw TypeError(".Message.observeWorkerRespond: object expected");
            message.observeWorkerRespond = $root.ObserveWorkerRespond.fromObject(object.observeWorkerRespond);
        }
        if (object.remoteCallRequest != null) {
            if (typeof object.remoteCallRequest !== "object")
                throw TypeError(".Message.remoteCallRequest: object expected");
            message.remoteCallRequest = $root.RemoteCallRequest.fromObject(object.remoteCallRequest);
        }
        if (object.remoteCallRespond != null) {
            if (typeof object.remoteCallRespond !== "object")
                throw TypeError(".Message.remoteCallRespond: object expected");
            message.remoteCallRespond = $root.RemoteCallRespond.fromObject(object.remoteCallRespond);
        }
        if (object.startTaskRequest != null) {
            if (typeof object.startTaskRequest !== "object")
                throw TypeError(".Message.startTaskRequest: object expected");
            message.startTaskRequest = $root.StartTaskRequest.fromObject(object.startTaskRequest);
        }
        if (object.startTaskRespond != null) {
            if (typeof object.startTaskRespond !== "object")
                throw TypeError(".Message.startTaskRespond: object expected");
            message.startTaskRespond = $root.StartTaskRespond.fromObject(object.startTaskRespond);
        }
        if (object.addSeoTaskRequest != null) {
            if (typeof object.addSeoTaskRequest !== "object")
                throw TypeError(".Message.addSeoTaskRequest: object expected");
            message.addSeoTaskRequest = $root.AddSeoTaskRequest.fromObject(object.addSeoTaskRequest);
        }
        if (object.addSeoTaskRespond != null) {
            if (typeof object.addSeoTaskRespond !== "object")
                throw TypeError(".Message.addSeoTaskRespond: object expected");
            message.addSeoTaskRespond = $root.AddSeoTaskRespond.fromObject(object.addSeoTaskRespond);
        }
        if (object.removeSeoTasksRequest != null) {
            if (typeof object.removeSeoTasksRequest !== "object")
                throw TypeError(".Message.removeSeoTasksRequest: object expected");
            message.removeSeoTasksRequest = $root.RemoveSeoTasksRequest.fromObject(object.removeSeoTasksRequest);
        }
        if (object.removeSeoTasksRespond != null) {
            if (typeof object.removeSeoTasksRespond !== "object")
                throw TypeError(".Message.removeSeoTasksRespond: object expected");
            message.removeSeoTasksRespond = $root.RemoveSeoTasksRespond.fromObject(object.removeSeoTasksRespond);
        }
        if (object.selectSeoTaskRequest != null) {
            if (typeof object.selectSeoTaskRequest !== "object")
                throw TypeError(".Message.selectSeoTaskRequest: object expected");
            message.selectSeoTaskRequest = $root.SelectSeoTaskRequest.fromObject(object.selectSeoTaskRequest);
        }
        if (object.selectSeoTaskRespond != null) {
            if (typeof object.selectSeoTaskRespond !== "object")
                throw TypeError(".Message.selectSeoTaskRespond: object expected");
            message.selectSeoTaskRespond = $root.SelectSeoTaskRespond.fromObject(object.selectSeoTaskRespond);
        }
        if (object.updateSeoTaskRequest != null) {
            if (typeof object.updateSeoTaskRequest !== "object")
                throw TypeError(".Message.updateSeoTaskRequest: object expected");
            message.updateSeoTaskRequest = $root.UpdateSeoTaskRequest.fromObject(object.updateSeoTaskRequest);
        }
        if (object.updateSeoTaskRespond != null) {
            if (typeof object.updateSeoTaskRespond !== "object")
                throw TypeError(".Message.updateSeoTaskRespond: object expected");
            message.updateSeoTaskRespond = $root.UpdateSeoTaskRespond.fromObject(object.updateSeoTaskRespond);
        }
        if (object.startSeoTaskCommand != null) {
            if (typeof object.startSeoTaskCommand !== "object")
                throw TypeError(".Message.startSeoTaskCommand: object expected");
            message.startSeoTaskCommand = $root.StartSeoTaskCommand.fromObject(object.startSeoTaskCommand);
        }
        if (object.selectIdleWorkerRequest != null) {
            if (typeof object.selectIdleWorkerRequest !== "object")
                throw TypeError(".Message.selectIdleWorkerRequest: object expected");
            message.selectIdleWorkerRequest = $root.SelectIdleWorkerRequest.fromObject(object.selectIdleWorkerRequest);
        }
        if (object.selectIdleWorkerRespond != null) {
            if (typeof object.selectIdleWorkerRespond !== "object")
                throw TypeError(".Message.selectIdleWorkerRespond: object expected");
            message.selectIdleWorkerRespond = $root.SelectIdleWorkderRespond.fromObject(object.selectIdleWorkerRespond);
        }
        if (object.getSeoTaskRequest != null) {
            if (typeof object.getSeoTaskRequest !== "object")
                throw TypeError(".Message.getSeoTaskRequest: object expected");
            message.getSeoTaskRequest = $root.GetSeoTaskRequest.fromObject(object.getSeoTaskRequest);
        }
        if (object.getSeoTaskRespond != null) {
            if (typeof object.getSeoTaskRespond !== "object")
                throw TypeError(".Message.getSeoTaskRespond: object expected");
            message.getSeoTaskRespond = $root.GetSeoTaskRespond.fromObject(object.getSeoTaskRespond);
        }
        if (object.completedSeoTaskNotify != null) {
            if (typeof object.completedSeoTaskNotify !== "object")
                throw TypeError(".Message.completedSeoTaskNotify: object expected");
            message.completedSeoTaskNotify = $root.CompletedSeoTaskNotify.fromObject(object.completedSeoTaskNotify);
        }
        if (object.subscribeSeoTaskRequest != null) {
            if (typeof object.subscribeSeoTaskRequest !== "object")
                throw TypeError(".Message.subscribeSeoTaskRequest: object expected");
            message.subscribeSeoTaskRequest = $root.SubscribeSeoTaskRequest.fromObject(object.subscribeSeoTaskRequest);
        }
        if (object.subscribeSeoTaskRespond != null) {
            if (typeof object.subscribeSeoTaskRespond !== "object")
                throw TypeError(".Message.subscribeSeoTaskRespond: object expected");
            message.subscribeSeoTaskRespond = $root.SubscribeSeoTaskRespond.fromObject(object.subscribeSeoTaskRespond);
        }
        if (object.updateSeoTaskNotify != null) {
            if (typeof object.updateSeoTaskNotify !== "object")
                throw TypeError(".Message.updateSeoTaskNotify: object expected");
            message.updateSeoTaskNotify = $root.UpdateSeoTaskNotify.fromObject(object.updateSeoTaskNotify);
        }
        if (object.seoTaskFailNotify != null) {
            if (typeof object.seoTaskFailNotify !== "object")
                throw TypeError(".Message.seoTaskFailNotify: object expected");
            message.seoTaskFailNotify = $root.SeoTaskFailNotify.fromObject(object.seoTaskFailNotify);
        }
        if (object.cancelSeoTaskCommand != null) {
            if (typeof object.cancelSeoTaskCommand !== "object")
                throw TypeError(".Message.cancelSeoTaskCommand: object expected");
            message.cancelSeoTaskCommand = $root.CancelSeoTaskCommand.fromObject(object.cancelSeoTaskCommand);
        }
        return message;
    };

    /**
     * Creates a plain object from a Message message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Message
     * @static
     * @param {Message} message Message
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Message.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
            object.loginRequest = $root.LoginRequest.toObject(message.loginRequest, options);
            if (options.oneofs)
                object.Data = "loginRequest";
        }
        if (message.loginRespond != null && message.hasOwnProperty("loginRespond")) {
            object.loginRespond = $root.LoginRespond.toObject(message.loginRespond, options);
            if (options.oneofs)
                object.Data = "loginRespond";
        }
        if (message.getCodeRequest != null && message.hasOwnProperty("getCodeRequest")) {
            object.getCodeRequest = $root.GetCodeRequest.toObject(message.getCodeRequest, options);
            if (options.oneofs)
                object.Data = "getCodeRequest";
        }
        if (message.getCodeRespond != null && message.hasOwnProperty("getCodeRespond")) {
            object.getCodeRespond = $root.GetCodeRespond.toObject(message.getCodeRespond, options);
            if (options.oneofs)
                object.Data = "getCodeRespond";
        }
        if (message.updateWorkerStateRequest != null && message.hasOwnProperty("updateWorkerStateRequest")) {
            object.updateWorkerStateRequest = $root.UpdateWorkerStateRequest.toObject(message.updateWorkerStateRequest, options);
            if (options.oneofs)
                object.Data = "updateWorkerStateRequest";
        }
        if (message.interruptRequest != null && message.hasOwnProperty("interruptRequest")) {
            object.interruptRequest = $root.InterruptRequest.toObject(message.interruptRequest, options);
            if (options.oneofs)
                object.Data = "interruptRequest";
        }
        if (message.executeRequest != null && message.hasOwnProperty("executeRequest")) {
            object.executeRequest = $root.ExecuteRequest.toObject(message.executeRequest, options);
            if (options.oneofs)
                object.Data = "executeRequest";
        }
        if (message.isUseRemoteCodeProviderRequest != null && message.hasOwnProperty("isUseRemoteCodeProviderRequest")) {
            object.isUseRemoteCodeProviderRequest = $root.IsUseRemoteCodeProviderRequest.toObject(message.isUseRemoteCodeProviderRequest, options);
            if (options.oneofs)
                object.Data = "isUseRemoteCodeProviderRequest";
        }
        if (message.ping != null && message.hasOwnProperty("ping")) {
            object.ping = $root.Ping.toObject(message.ping, options);
            if (options.oneofs)
                object.Data = "ping";
        }
        if (message.logRequest != null && message.hasOwnProperty("logRequest")) {
            object.logRequest = $root.LogRequest.toObject(message.logRequest, options);
            if (options.oneofs)
                object.Data = "logRequest";
        }
        if (message.isUseRemoteLogRequest != null && message.hasOwnProperty("isUseRemoteLogRequest")) {
            object.isUseRemoteLogRequest = $root.IsUseRemoteLogRequest.toObject(message.isUseRemoteLogRequest, options);
            if (options.oneofs)
                object.Data = "isUseRemoteLogRequest";
        }
        if (message.addLogObserverRequest != null && message.hasOwnProperty("addLogObserverRequest")) {
            object.addLogObserverRequest = $root.AddLogObserverRequest.toObject(message.addLogObserverRequest, options);
            if (options.oneofs)
                object.Data = "addLogObserverRequest";
        }
        if (message.removeLogObserverRequest != null && message.hasOwnProperty("removeLogObserverRequest")) {
            object.removeLogObserverRequest = $root.RemoveLogObserverRequest.toObject(message.removeLogObserverRequest, options);
            if (options.oneofs)
                object.Data = "removeLogObserverRequest";
        }
        if (message.setDebuggerRequest != null && message.hasOwnProperty("setDebuggerRequest")) {
            object.setDebuggerRequest = $root.SetDebuggerRequest.toObject(message.setDebuggerRequest, options);
            if (options.oneofs)
                object.Data = "setDebuggerRequest";
        }
        if (message.resetDebuggerRequest != null && message.hasOwnProperty("resetDebuggerRequest")) {
            object.resetDebuggerRequest = $root.ResetDebuggerRequest.toObject(message.resetDebuggerRequest, options);
            if (options.oneofs)
                object.Data = "resetDebuggerRequest";
        }
        if (message.getWorkerInfoRequest != null && message.hasOwnProperty("getWorkerInfoRequest")) {
            object.getWorkerInfoRequest = $root.GetWorkerInfoRequest.toObject(message.getWorkerInfoRequest, options);
            if (options.oneofs)
                object.Data = "getWorkerInfoRequest";
        }
        if (message.getWorkerInfoRespond != null && message.hasOwnProperty("getWorkerInfoRespond")) {
            object.getWorkerInfoRespond = $root.GetWorkerInfoRespond.toObject(message.getWorkerInfoRespond, options);
            if (options.oneofs)
                object.Data = "getWorkerInfoRespond";
        }
        if (message.screenShotRequest != null && message.hasOwnProperty("screenShotRequest")) {
            object.screenShotRequest = $root.ScreenShotRequest.toObject(message.screenShotRequest, options);
            if (options.oneofs)
                object.Data = "screenShotRequest";
        }
        if (message.screenShotRespond != null && message.hasOwnProperty("screenShotRespond")) {
            object.screenShotRespond = $root.ScreenShotRespond.toObject(message.screenShotRespond, options);
            if (options.oneofs)
                object.Data = "screenShotRespond";
        }
        if (message.getResourceRequest != null && message.hasOwnProperty("getResourceRequest")) {
            object.getResourceRequest = $root.GetResourceRequest.toObject(message.getResourceRequest, options);
            if (options.oneofs)
                object.Data = "getResourceRequest";
        }
        if (message.getResourceRespond != null && message.hasOwnProperty("getResourceRespond")) {
            object.getResourceRespond = $root.GetResourceRespond.toObject(message.getResourceRespond, options);
            if (options.oneofs)
                object.Data = "getResourceRespond";
        }
        if (message.readSettingRequest != null && message.hasOwnProperty("readSettingRequest")) {
            object.readSettingRequest = $root.ReadSettingRequest.toObject(message.readSettingRequest, options);
            if (options.oneofs)
                object.Data = "readSettingRequest";
        }
        if (message.readSettingRespond != null && message.hasOwnProperty("readSettingRespond")) {
            object.readSettingRespond = $root.ReadSettingRespond.toObject(message.readSettingRespond, options);
            if (options.oneofs)
                object.Data = "readSettingRespond";
        }
        if (message.writeSettingRequest != null && message.hasOwnProperty("writeSettingRequest")) {
            object.writeSettingRequest = $root.WriteSettingRequest.toObject(message.writeSettingRequest, options);
            if (options.oneofs)
                object.Data = "writeSettingRequest";
        }
        if (message.recognizeRequest != null && message.hasOwnProperty("recognizeRequest")) {
            object.recognizeRequest = $root.RecognizeRequest.toObject(message.recognizeRequest, options);
            if (options.oneofs)
                object.Data = "recognizeRequest";
        }
        if (message.recognizeRespond != null && message.hasOwnProperty("recognizeRespond")) {
            object.recognizeRespond = $root.RecognizeRespond.toObject(message.recognizeRespond, options);
            if (options.oneofs)
                object.Data = "recognizeRespond";
        }
        if (message.getTaskRequest != null && message.hasOwnProperty("getTaskRequest")) {
            object.getTaskRequest = $root.GetTaskRequest.toObject(message.getTaskRequest, options);
            if (options.oneofs)
                object.Data = "getTaskRequest";
        }
        if (message.getTaskRespond != null && message.hasOwnProperty("getTaskRespond")) {
            object.getTaskRespond = $root.GetTaskRespond.toObject(message.getTaskRespond, options);
            if (options.oneofs)
                object.Data = "getTaskRespond";
        }
        if (message.executeTaskRequest != null && message.hasOwnProperty("executeTaskRequest")) {
            object.executeTaskRequest = $root.ExecuteTaskRequest.toObject(message.executeTaskRequest, options);
            if (options.oneofs)
                object.Data = "executeTaskRequest";
        }
        if (message.publishMessageRequest != null && message.hasOwnProperty("publishMessageRequest")) {
            object.publishMessageRequest = $root.PublishMessageRequest.toObject(message.publishMessageRequest, options);
            if (options.oneofs)
                object.Data = "publishMessageRequest";
        }
        if (message.subscribeMessageRequest != null && message.hasOwnProperty("subscribeMessageRequest")) {
            object.subscribeMessageRequest = $root.SubscribeMessageRequest.toObject(message.subscribeMessageRequest, options);
            if (options.oneofs)
                object.Data = "subscribeMessageRequest";
        }
        if (message.removeMessageRequest != null && message.hasOwnProperty("removeMessageRequest")) {
            object.removeMessageRequest = $root.RemoveMessageRequest.toObject(message.removeMessageRequest, options);
            if (options.oneofs)
                object.Data = "removeMessageRequest";
        }
        if (message.subscribeMessageRespond != null && message.hasOwnProperty("subscribeMessageRespond")) {
            object.subscribeMessageRespond = $root.SubscribeMessageRespond.toObject(message.subscribeMessageRespond, options);
            if (options.oneofs)
                object.Data = "subscribeMessageRespond";
        }
        if (message.unsubscribeMessageRequest != null && message.hasOwnProperty("unsubscribeMessageRequest")) {
            object.unsubscribeMessageRequest = $root.UnsubscribeMessageRequest.toObject(message.unsubscribeMessageRequest, options);
            if (options.oneofs)
                object.Data = "unsubscribeMessageRequest";
        }
        if (message.useLatestVerifyDataRequest != null && message.hasOwnProperty("useLatestVerifyDataRequest")) {
            object.useLatestVerifyDataRequest = $root.UseLatestVerifyDataRequest.toObject(message.useLatestVerifyDataRequest, options);
            if (options.oneofs)
                object.Data = "useLatestVerifyDataRequest";
        }
        if (message.logoutRequest != null && message.hasOwnProperty("logoutRequest")) {
            object.logoutRequest = $root.LogoutRequest.toObject(message.logoutRequest, options);
            if (options.oneofs)
                object.Data = "logoutRequest";
        }
        if (message.remoteCallRequest != null && message.hasOwnProperty("remoteCallRequest")) {
            object.remoteCallRequest = $root.RemoteCallRequest.toObject(message.remoteCallRequest, options);
            if (options.oneofs)
                object.Data = "remoteCallRequest";
        }
        if (message.remoteCallRespond != null && message.hasOwnProperty("remoteCallRespond")) {
            object.remoteCallRespond = $root.RemoteCallRespond.toObject(message.remoteCallRespond, options);
            if (options.oneofs)
                object.Data = "remoteCallRespond";
        }
        if (message.startTaskRequest != null && message.hasOwnProperty("startTaskRequest")) {
            object.startTaskRequest = $root.StartTaskRequest.toObject(message.startTaskRequest, options);
            if (options.oneofs)
                object.Data = "startTaskRequest";
        }
        if (message.startTaskRespond != null && message.hasOwnProperty("startTaskRespond")) {
            object.startTaskRespond = $root.StartTaskRespond.toObject(message.startTaskRespond, options);
            if (options.oneofs)
                object.Data = "startTaskRespond";
        }
        if (message.addSeoTaskRequest != null && message.hasOwnProperty("addSeoTaskRequest")) {
            object.addSeoTaskRequest = $root.AddSeoTaskRequest.toObject(message.addSeoTaskRequest, options);
            if (options.oneofs)
                object.Data = "addSeoTaskRequest";
        }
        if (message.addSeoTaskRespond != null && message.hasOwnProperty("addSeoTaskRespond")) {
            object.addSeoTaskRespond = $root.AddSeoTaskRespond.toObject(message.addSeoTaskRespond, options);
            if (options.oneofs)
                object.Data = "addSeoTaskRespond";
        }
        if (message.removeSeoTasksRequest != null && message.hasOwnProperty("removeSeoTasksRequest")) {
            object.removeSeoTasksRequest = $root.RemoveSeoTasksRequest.toObject(message.removeSeoTasksRequest, options);
            if (options.oneofs)
                object.Data = "removeSeoTasksRequest";
        }
        if (message.removeSeoTasksRespond != null && message.hasOwnProperty("removeSeoTasksRespond")) {
            object.removeSeoTasksRespond = $root.RemoveSeoTasksRespond.toObject(message.removeSeoTasksRespond, options);
            if (options.oneofs)
                object.Data = "removeSeoTasksRespond";
        }
        if (message.selectSeoTaskRequest != null && message.hasOwnProperty("selectSeoTaskRequest")) {
            object.selectSeoTaskRequest = $root.SelectSeoTaskRequest.toObject(message.selectSeoTaskRequest, options);
            if (options.oneofs)
                object.Data = "selectSeoTaskRequest";
        }
        if (message.selectSeoTaskRespond != null && message.hasOwnProperty("selectSeoTaskRespond")) {
            object.selectSeoTaskRespond = $root.SelectSeoTaskRespond.toObject(message.selectSeoTaskRespond, options);
            if (options.oneofs)
                object.Data = "selectSeoTaskRespond";
        }
        if (message.updateSeoTaskRequest != null && message.hasOwnProperty("updateSeoTaskRequest")) {
            object.updateSeoTaskRequest = $root.UpdateSeoTaskRequest.toObject(message.updateSeoTaskRequest, options);
            if (options.oneofs)
                object.Data = "updateSeoTaskRequest";
        }
        if (message.updateSeoTaskRespond != null && message.hasOwnProperty("updateSeoTaskRespond")) {
            object.updateSeoTaskRespond = $root.UpdateSeoTaskRespond.toObject(message.updateSeoTaskRespond, options);
            if (options.oneofs)
                object.Data = "updateSeoTaskRespond";
        }
        if (message.startSeoTaskCommand != null && message.hasOwnProperty("startSeoTaskCommand")) {
            object.startSeoTaskCommand = $root.StartSeoTaskCommand.toObject(message.startSeoTaskCommand, options);
            if (options.oneofs)
                object.Data = "startSeoTaskCommand";
        }
        if (message.selectIdleWorkerRequest != null && message.hasOwnProperty("selectIdleWorkerRequest")) {
            object.selectIdleWorkerRequest = $root.SelectIdleWorkerRequest.toObject(message.selectIdleWorkerRequest, options);
            if (options.oneofs)
                object.Data = "selectIdleWorkerRequest";
        }
        if (message.selectIdleWorkerRespond != null && message.hasOwnProperty("selectIdleWorkerRespond")) {
            object.selectIdleWorkerRespond = $root.SelectIdleWorkderRespond.toObject(message.selectIdleWorkerRespond, options);
            if (options.oneofs)
                object.Data = "selectIdleWorkerRespond";
        }
        if (message.observeWorkerRequest != null && message.hasOwnProperty("observeWorkerRequest")) {
            object.observeWorkerRequest = $root.ObserveWorkerRequest.toObject(message.observeWorkerRequest, options);
            if (options.oneofs)
                object.Data = "observeWorkerRequest";
        }
        if (message.observeWorkerRespond != null && message.hasOwnProperty("observeWorkerRespond")) {
            object.observeWorkerRespond = $root.ObserveWorkerRespond.toObject(message.observeWorkerRespond, options);
            if (options.oneofs)
                object.Data = "observeWorkerRespond";
        }
        if (message.getSeoTaskRequest != null && message.hasOwnProperty("getSeoTaskRequest")) {
            object.getSeoTaskRequest = $root.GetSeoTaskRequest.toObject(message.getSeoTaskRequest, options);
            if (options.oneofs)
                object.Data = "getSeoTaskRequest";
        }
        if (message.getSeoTaskRespond != null && message.hasOwnProperty("getSeoTaskRespond")) {
            object.getSeoTaskRespond = $root.GetSeoTaskRespond.toObject(message.getSeoTaskRespond, options);
            if (options.oneofs)
                object.Data = "getSeoTaskRespond";
        }
        if (message.completedSeoTaskNotify != null && message.hasOwnProperty("completedSeoTaskNotify")) {
            object.completedSeoTaskNotify = $root.CompletedSeoTaskNotify.toObject(message.completedSeoTaskNotify, options);
            if (options.oneofs)
                object.Data = "completedSeoTaskNotify";
        }
        if (message.subscribeSeoTaskRequest != null && message.hasOwnProperty("subscribeSeoTaskRequest")) {
            object.subscribeSeoTaskRequest = $root.SubscribeSeoTaskRequest.toObject(message.subscribeSeoTaskRequest, options);
            if (options.oneofs)
                object.Data = "subscribeSeoTaskRequest";
        }
        if (message.subscribeSeoTaskRespond != null && message.hasOwnProperty("subscribeSeoTaskRespond")) {
            object.subscribeSeoTaskRespond = $root.SubscribeSeoTaskRespond.toObject(message.subscribeSeoTaskRespond, options);
            if (options.oneofs)
                object.Data = "subscribeSeoTaskRespond";
        }
        if (message.updateSeoTaskNotify != null && message.hasOwnProperty("updateSeoTaskNotify")) {
            object.updateSeoTaskNotify = $root.UpdateSeoTaskNotify.toObject(message.updateSeoTaskNotify, options);
            if (options.oneofs)
                object.Data = "updateSeoTaskNotify";
        }
        if (message.seoTaskFailNotify != null && message.hasOwnProperty("seoTaskFailNotify")) {
            object.seoTaskFailNotify = $root.SeoTaskFailNotify.toObject(message.seoTaskFailNotify, options);
            if (options.oneofs)
                object.Data = "seoTaskFailNotify";
        }
        if (message.cancelSeoTaskCommand != null && message.hasOwnProperty("cancelSeoTaskCommand")) {
            object.cancelSeoTaskCommand = $root.CancelSeoTaskCommand.toObject(message.cancelSeoTaskCommand, options);
            if (options.oneofs)
                object.Data = "cancelSeoTaskCommand";
        }
        return object;
    };

    /**
     * Converts this Message to JSON.
     * @function toJSON
     * @memberof Message
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Message.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Message
     * @function getTypeUrl
     * @memberof Message
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Message";
    };

    return Message;
})();

module.exports = $root;
